import { Histogram, RGB } from '@/types/cms'
import { splitInt } from '@/utils/string'

const histogramToRGB = (hist: Histogram): RGB => {
  const histRed = splitInt(hist.r, ' ')
  const histGreen = splitInt(hist.g, ' ')
  const histBlue = splitInt(hist.b, ' ')
  const histCol = splitInt(hist.c, ' ')
  const totalPixels = hist.totalpixels

  let pixelCount = 0
  let startIndex = 0
  for (let i = 0; i < histCol.length; i++) {
    pixelCount += histCol[i]
    if (pixelCount > totalPixels * 0.4) {
      break
    }
    startIndex = i
  }

  let redSum = 0
  let greenSum = 0
  let blueSum = 0
  let count = 0
  for (let i = startIndex; i < 256; i++) {
    redSum += histRed[i] * histCol[i]
    greenSum += histGreen[i] * histCol[i]
    blueSum += histBlue[i] * histCol[i]
    count += histCol[i]
  }

  const avgRed = redSum / count
  const avgGreen = greenSum / count
  const avgBlue = blueSum / count

  return { red: avgRed, green: avgGreen, blue: avgBlue }
}

export { histogramToRGB }
