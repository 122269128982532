<template>
  <div id="firebaseui-auth-container"></div>
</template>

<script lang="ts">
interface IFirebaseUIProps {
  callback: () => Promise<void>
}
</script>

<script setup lang="ts">
import { initClient } from '@/api/client'
import { onMounted, onUnmounted } from 'vue'
import FirebaseAuth from '@/auth/firebase'
import { useAuthStore } from '@/store/authStore'
import * as firebaseui from 'firebaseui'
import google from '@/assets/icons/google.svg?url'
import '@/assets/firebaseui.css'
import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth'
import { logError } from '@/utils/errorUtils'
import { tracking } from '@/tracking/EventController'

const props = defineProps<IFirebaseUIProps>()
const authStore = useAuthStore()
const auth = new FirebaseAuth(authStore)
const ui = new firebaseui.auth.AuthUI(auth.getAuth())
const allowSignup = import.meta.env.VITE_ALLOW_SIGNUP ?? 'false'

const firebaseConfigUI: firebaseui.auth.Config = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {
      const userInfo = authResult.user?.toJSON()
      initClient(userInfo?.stsTokenManager.accessToken)
      if (authResult.additionalUserInfo.isNewUser) {
        tracking.registrationEvent()
      }
      props.callback()
      return true
    },
    signInFailure: (error) => {
      logError(error)
    }
  },
  signInFlow: 'popup',
  popupMode: true,
  signInOptions: [
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      disableSignUp: { status: allowSignup === 'false' },
      fullLabel: 'SIGN IN WITH EMAIL'
    },
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      disableSignUp: { status: allowSignup === 'false' },
      fullLabel: 'SIGN IN WITH GOOGLE',
      iconUrl: google,
      scopes: ['profile', 'email'],
      customParameters: {
        prompt: 'select_account'
      }
    }
  ]
}

onMounted(async () => {
  ui.start('#firebaseui-auth-container', firebaseConfigUI)
})
onUnmounted(() => {
  ui.delete()
})
</script>
