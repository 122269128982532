import {
  NavigationGuardNext,
  RouteLocationNormalized,
  createRouter,
  createWebHistory
} from 'vue-router'
import AdminPage from '@/pages/AdminPage.vue'
import ChatPage from '@/pages/ChatPage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import { useModalControllerStore } from '@/store/modalStore'
import { Modals, type VtoData } from '@/types/modal'
import { useAuthStore } from '@/store/authStore'
import { useErrorStore } from '@/store/errorStore'
import { logError } from '@/utils/errorUtils'
import { informationPageContents } from '@/utils/onetrust'
import { userHasNotConsented } from '@/utils/user-utils'

const popstateListener = (e: PopStateEvent) => {
  const modalStore = useModalControllerStore()
  if (modalStore.running !== Modals.NONE) {
    e.preventDefault()
    modalStore.back()
  } else {
    window.removeEventListener('popstate', popstateListener)
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/chat',
      name: 'chat',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.close()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/scan',
      name: 'scan',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setSelfie()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/skinResults',
      name: 'skinResults',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setSkinDiag()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/skinInfo',
      name: 'skinInfo',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setSkinInfo()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/auth',
      name: 'auth',
      component: LoginPage,
      meta: {
        public: true
      },
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        next()
      }
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: (to, from, next) => {
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setLogin()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/deleteAccount',
      name: 'deleteAccount',
      beforeEnter: (to, from, next) => {
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setDeleteAccount()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/profile',
      name: 'profile',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setProfile()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/termsConds',
      name: 'termsConds',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          const authStore = useAuthStore()
          if (!userHasNotConsented(authStore)) {
            next('/chat')
          } else {
            modalStore.reload(window.localStorage.getItem('modalHistory'))
            modalStore.setTermsConds()
            next()
          }
        })
      },
      component: ChatPage
    },
    {
      path: '/information',
      name: 'information',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          if (
            to.query.page &&
            !Array.isArray(to.query.page) &&
            to.query.page in informationPageContents()
          ) {
            modalStore.setInformationPage(to.query.page)
          }
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/PDP',
      name: 'PDP',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          let upc = ''
          if (to.query.upc) {
            upc = to.query.upc as string
          }
          modalStore.setProduct({ upc, msgIndex: -1 })
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/vto',
      name: 'vto',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          let upcs: string[] = ['']
          if (to.query.upcs) {
            upcs = (to.query.upcs as string).split(',')
          }
          const data = {
            upcs: upcs,
            selected: upcs[0]
          } as VtoData
          modalStore.setVTO(data)
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/admin',
      name: 'admin',
      beforeEnter: (to, from, next) => {
        guard(to, from, next, () => {
          next()
        })
      },
      component: AdminPage
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'chat' }
    }
  ]
})

const guard = (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
  callback: () => void
) => {
  const authStore = useAuthStore()
  const errorStore = useErrorStore()
  errorStore.showErrorPage = false
  authStore
    .authRoute(Boolean(to.meta.public))
    .then((validUser) => {
      if (validUser) {
        if (userHasNotConsented(authStore, true)) {
          next()
        } else {
          callback()
        }
      } else if (import.meta.env.VITE_ALLOW_ANONYMOUS === 'false') {
        window.location.href = '/auth'
        return
      } else {
        errorStore.errorMessage = 'Failed to login'
        errorStore.showErrorPage = true
      }
    })
    .catch((e) => {
      logError(e)
      errorStore.errorMessage = 'Failed to login'
      errorStore.showErrorPage = true
    })
}

router.beforeEach(() => {
  window.dataLayer = window.dataLayer || []
})
