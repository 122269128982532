<template>
  <div
    ref="backButtonRef"
    class="cursor-pointer rounded-full p-2 z-30 bg-custom-tw-nav-button-background shadow-sm"
    :class="customBackButtonStyle"
  >
    <SvgIcon
      name="back-arrow"
      class="h-6 w-6"
      :class="[color]"
    />
  </div>
</template>
<script lang="ts">
interface IBackButtonProps {
  color?: 'light' | 'dark'
  background?: string
}
</script>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

const backButtonRef = ref<HTMLDivElement | null>(null)

const props = withDefaults(defineProps<IBackButtonProps>(), {
  color: 'dark',
  background: undefined
})

const customBackButtonStyle = computed(() => {
  const style: string[] = []
  if (props.background === undefined) return style
  style.push('custom-nav-button-background')
  return style
})

const setBackgroundColor = () => {
  if (!backButtonRef.value || !props.background) return
  backButtonRef.value.style.setProperty('--color', props.background)
}

onMounted(() => {
  setBackgroundColor()
})
</script>

<style lang="scss" scoped>
.dark {
  fill: #000;
}

.light {
  fill: #fff;
}

@property --color {
  syntax: '<color>';
  inherits: true;
  initial-value: white;
}

.custom-nav-button-background {
  background-color: var(--color);
}
</style>
