import { defineStore } from 'pinia'
import EventEmitter from 'eventemitter3'
import type { Auth } from '@/types/auth'
import {
  type User,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence
} from 'firebase/auth'
import FirebaseAuth from '@/auth/firebase'
import { logError } from '@/utils/errorUtils'
import { AccountAPI } from '@/api'
import { initClient } from '@/api/client'
import { router } from '@/router/routes'

const event = new EventEmitter()

export const useAuthStore = defineStore('auth', {
  state: () =>
    ({
      user: null,
      oldUser: null,
      isAnonymous: false,
      consented: false,
      error: null,
      loading: true
    }) as Auth,
  persist: {
    storage: sessionStorage
  },
  getters: {
    // synchronous check for user object
    isValidUser: (state) => !!state.user,
    isNewUser: (state) => state.user?.metadata.creationTime === state.user?.metadata.lastSignInTime,
    isConsented: (state) => state.consented
  },
  actions: {
    // async check of user's auth status, only returns after the loading is finished
    async isUserAuthenticated() {
      if (this.loading) {
        const loadings = [
          new Promise((resolve) => event.once('loaded', resolve)),
          // the following line makes sure that the check is never blocked for more then 5 seconds
          // if anything goes wrong with the event emitting (which shouldn't)
          new Promise((resolve) => setTimeout(resolve, 5000))
        ]
        await Promise.any(loadings)
      }
      return !!this.user
    },
    async checkToken(): Promise<boolean> {
      const userInfo = this.user?.toJSON()
      if (userInfo?.stsTokenManager.expirationTime <= Date.now()) {
        try {
          const token = await this.user?.getIdToken(true)
          if (!token) {
            logError('Failed to get token')
            await router.push({ name: 'chat' })
            router.go(0)
            return false
          } else {
            initClient(token)
          }
        } catch (error) {
          logError(error)
          await router.push({ name: 'chat' })
          router.go(0)
          return false
        }
      }
      return true
    },
    // setter function for the user object
    // NOTE: this setter should be used instead of modifying the user object directly
    updateUser(user: User | null) {
      this.user = user
      if (user) this.error = null
      this.loading = false
      event.emit('loaded')
    },
    setCustomError(message: string | undefined) {
      this.error = message ?? 'Unknown error'
    },
    clearError() {
      this.error = ''
    },
    setAnonymous(isAnonymous: boolean) {
      this.isAnonymous = isAnonymous
    },
    setConsented(consent: boolean) {
      this.consented = consent
    },
    async authRoute(publicRoute: boolean): Promise<boolean> {
      const auth = new FirebaseAuth(this)
      const validUser = await this.isUserAuthenticated()
      if (!validUser && !publicRoute) {
        if (!(await this.logInAnonymous())) return false
      }
      if (import.meta.env.VITE_ALLOW_ANONYMOUS === 'false' && this.user?.isAnonymous) auth.signOut()
      if (this.user?.isAnonymous ?? false) {
        await setPersistence(auth.getAuth(), inMemoryPersistence)
      } else {
        await setPersistence(auth.getAuth(), browserLocalPersistence)
      }
      this.setAnonymous(this.user?.isAnonymous ?? false)
      return true
    },
    async logInAnonymous(): Promise<boolean> {
      const auth = new FirebaseAuth(this)
      if (import.meta.env.VITE_ALLOW_ANONYMOUS === 'false') return false
      await auth.anonymousSignIn()
      const authenticated = await auth.authenticated()
      if (!authenticated) {
        logError('anonymous login failed')
        return false
      }
      return true
    },
    async mergeAccounts() {
      if (!this.user || !this.oldUser) return
      try {
        await AccountAPI.mergeAccounts({ isNew: this.isNewUser, mergeId: this.oldUser.uid })
      } catch (e) {
        logError(e, 'Failed to merge accounts')
      }
      this.oldUser = null
    }
  }
})
