<template>
  <h1 class="text-white">
    {{ errorStore.errorMessage }}
  </h1>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/store/errorStore'
const errorStore = useErrorStore()
</script>
