export function clamp(n: number, min: number, max: number) {
  return Math.max(Math.min(n, max), min)
}

import { MersenneTwister19937, Random } from 'random-js'

const MOD = 2 ** 32
const PRNG = new Random(MersenneTwister19937.autoSeed())

export const cycle = (num1: number, num2: number) => ((num1 % num2) + num2) % num2

/**
 * Check if the input numbers are close within the given range.
 *
 * @param num1 - The first input number.
 * @param num2 - The second input number.
 * @param tol - Tolerance of the difference.
 * @returns whether the two numbers are close enough
 */
export const isClose = (num1: number, num2: number, tol: number = 0): boolean =>
  Math.abs(num1 - num2) <= tol

export const random = () => {
  try {
    const array = new Uint32Array(1)
    crypto.getRandomValues(array)
    return array[0] / MOD
  } catch {
    // a random floating point number between 0 and 1 (inclusive)
    return PRNG.real(0, 1, true)
  }
}

export const randomBetween = (num1: number, num2: number) => {
  const max = Math.max(num1, num2)
  const min = Math.min(num1, num2)
  return crypto ? Math.floor(random() * (max - min + 1)) + min : PRNG.real(min, max, true)
}
