export type Dialog = {
  title: string
  message: string
  confirmText: string
}

export const defaultDialog = {
  title: 'Confirmation',
  message: 'Do you wish to continue?',
  confirmText: 'Confirm'
}
