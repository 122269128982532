/**
 * Axios config file for initializing various Axios instance.
 */
import axios, { type AxiosInstance } from 'axios'
import { baseUrl } from '@/utils/web'
import { useAuthStore, useModalControllerStore } from '@/store'
import { router } from '@/router/routes'
import FirebaseAuth from '@/auth/firebase'
import { logError } from '@/utils/errorUtils'
/**
 * Axios clients.
 */
const clients: Record<string, AxiosInstance> = {
  // default config for bff
  bff: axios.create({
    baseURL: baseUrl + '/api'
    // timeout: 1000,
  })
}

clients.bff.interceptors.response.use(
  (res) => res,
  async (error) => {
    logError(error)
    const modalStore = useModalControllerStore()
    const authStore = useAuthStore()
    const auth = new FirebaseAuth(authStore)
    if (error.code === 'ERR_BAD_REQUEST' && error.response?.data === 'User Token Error') {
      await auth.signOut()
      modalStore.close()
      await router.push({ name: 'chat' })
      router.go(0)
    }
    return error
  }
)

/**
 * Add auth token to Axios client.
 */
const initClient = (userToken: string) => {
  if (userToken) clients.bff.defaults.headers.common['Authorization'] = 'Bearer ' + userToken
}

const getAuth = () => {
  return clients.bff.defaults.headers.common['Authorization']
}

export { clients, initClient, getAuth }
