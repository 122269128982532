<template>
  <img
    :src="file"
    :alt="alt"
  />
</template>

<script lang="ts">
interface IAnimationProps {
  name: string
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<IAnimationProps>()

const file = computed(() => `./animations/${props.name}.apng`)

const alt = computed(() => `${props.name} animation`)
</script>
