import { defineStore } from 'pinia'

export const useErrorStore = defineStore('customError', {
  state: () => ({
    errorMessage: 'There was a problem loading the page',
    showErrorPage: false
  }),
  persist: {
    storage: sessionStorage
  }
})
