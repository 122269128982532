import { userHasNotConsented } from '@/utils/user-utils'
import { clients } from './client'
import { useAuthStore } from '@/store'

export default {
  postBatch: async (batchFile: File) => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return ''
    const data = new FormData()
    data.append('batch', batchFile)
    if (!(await authStore.checkToken())) return ''
    const resp = await clients.bff.post('/batch', data)
    return resp.data as { message: string }
  }
}
