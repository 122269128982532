import { memoize } from 'lodash'

export const informationPageContents = memoize(() => {
  const contents = atob(import.meta.env.VITE_INFORMATION_PAGE_CONTENTS)
  return JSON.parse(contents)
})

export const createOneTrustCDNScript = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src =
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js'
  script.id = 'otprivacy-notice-script'
  script.innerHTML =
    'settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1ldS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"'
  return script
}

const oneTrustLoadScriptContent = (jsonUrl: string) => `
OneTrust.NoticeApi.Initialized.then(function() {
        OneTrust.NoticeApi.LoadNotices(["${jsonUrl}"])});
`

export const createOneTrustLoadScript = (jsonUrl: string) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = oneTrustLoadScriptContent(jsonUrl)
  script.defer = true
  return script
}
