<template>
  <div
    v-show="enabled"
    class="bg-white relative w-full max-w-[768px] p-2 z-40"
  >
    <div class="relative w-full flex flex-row gap-1">
      <button
        class="w-full h-full flex p-3 rounded-[200px] border border-black border-opacity-20 justify-center items-center capitalize font-bold text-sm"
        @click="modalStore.setVtoFromProduct()"
      >
        Try it on
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
// import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useModalControllerStore, useProductStore } from '@/store'
import _ from 'lodash'

const modalStore = useModalControllerStore()
const productStore = useProductStore()

const enabled = ref(false)

const emit = defineEmits(['toggle'])

const onChange = async (newState: boolean) => {
  enabled.value = false
  if (newState && modalStore.productCard) {
    const upc = modalStore.productCard.upc
    const products = await productStore.getProducts([upc])
    if (products.length > 0) {
      const product = products[0]
      enabled.value = !_.isEmpty(product.shadeArray)
    }
  }
  emit('toggle', enabled.value)
}

onMounted(() => {
  onChange(modalStore.productModalBarEnabled)
})

watch(
  () => modalStore.productModalBarEnabled,
  (val) => {
    onChange(val)
  }
)
</script>
