const PRODUCT_NAME = 'Beauty Genius'

const INTRO_TEXT = `
${PRODUCT_NAME} is an experiment. As you try ${PRODUCT_NAME}, please remember:

- **${PRODUCT_NAME} will not always get it right.** ${PRODUCT_NAME} may give inaccurate or inappropriate responses.
- **L'Oréal collects your ${PRODUCT_NAME} conversations, related product usage information and your feedback.** Do not include information that can be used to identify you or others in your ${PRODUCT_NAME} conversations and in your feedback.
- **${PRODUCT_NAME} will get better with your feedback.** Please flag anything that may be offensive or unsafe.
` as const
const ANIMATION_END_EVENT = 'vbc_animationEndEvent'
const ANIMATION_START_EVENT = 'vbc_animationStartEvent'
const GENERAL_PURPOSE_EVENT = 'vbc_generaPurposeEvent'

const SESSION_IDENTIFIER = 'new Session'

//iPhone 5
const XS_SCREEN_WIDTH_SUPPORTED = 320
const XS_SCREEN_HEIGHT_SUPPORTED = 568

//iPhone 8
const M_SCREEN_WIDTH_SUPPORTED = 375
const M_SCREEN_HEIGHT_SUPPORTED = 667

//iPhone X
const L_SCREEN_WIDTH_SUPPORTED = 414
const L_SCREEN_HEIGHT_SUPPORTED = 896

//iPhone 12 pro max
const XL_SCREEN_WIDTH_SUPPORTED = 430
const XL_SCREEN_HEIGHT_SUPPORTED = 932

// Google API for logging out
const GAPI_LOGOUT =
  'https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue='

export {
  PRODUCT_NAME,
  INTRO_TEXT,
  ANIMATION_END_EVENT,
  ANIMATION_START_EVENT,
  GENERAL_PURPOSE_EVENT,
  GAPI_LOGOUT,
  SESSION_IDENTIFIER,
  XS_SCREEN_WIDTH_SUPPORTED,
  XS_SCREEN_HEIGHT_SUPPORTED,
  M_SCREEN_WIDTH_SUPPORTED,
  M_SCREEN_HEIGHT_SUPPORTED,
  L_SCREEN_WIDTH_SUPPORTED,
  L_SCREEN_HEIGHT_SUPPORTED,
  XL_SCREEN_WIDTH_SUPPORTED,
  XL_SCREEN_HEIGHT_SUPPORTED
}
