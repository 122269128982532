<template>
  <div class="overflow-x-auto w-full flex flex-col px-8 pt-6 pb-5 bg-custom-tw-background-sign-in">
    <div
      v-if="overall !== 'general'"
      class="flex flex-shrink-0 justify-center items-center w-[96px] h-[96px] rounded-full bg-[#eeeeee] self-center mb-4"
    >
      <SvgIcon
        :key="overall"
        class="w-[32px] h-[32px]"
        :name="overall === 'positive' ? 'thumb-up' : 'thumb-down'"
        color="black"
      />
    </div>
    <p
      class="text-[#161616] font-[Campton] text-[28px] leading-[36px] text-center font-semibold mb-3"
    >
      Share Feedback
    </p>
    <div
      v-if="!!mission"
      class="flex flex-col gap-2"
    >
      <FeedbackGeneral
        v-if="overall === 'general'"
        @send="onSend"
      />
      <FeedbackMessage
        v-else-if="(overall === 'negative') | (overall === 'positive')"
        :type="overall"
        @send="onSend"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import type { FeedbackData } from '@/types/feedback'
import FeedbackMessage from './FeedbackMessage.vue'
import FeedbackGeneral from './FeedbackGeneral.vue'
import { useChatStore } from '@/store'
import { ref } from 'vue'
const chatStore = useChatStore()
const props = defineProps<{
  overall: 'positive' | 'negative' | 'general'
  message: string
  missions: Record<string, string>
}>()
const emit = defineEmits(['skip', 'send', 'comment'])

const mission = ref<string | null>('None')

const onSend = (data?: Partial<FeedbackData>) => {
  if (mission.value === null) return
  emit('send', {
    overall: props.overall,
    suggestedAnswer: '',
    frontVersion: VITE_APP_VERSION,
    sessionID: chatStore.currentSessionId,
    feedbackData: data
  } as FeedbackData)
}
</script>
