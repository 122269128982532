<template>
  <div class="flex flex-col items-start">
    <Button
      type="submit"
      @click="ExportAPI.getExport()"
      :disabled="loading"
    >
      Export feedback
    </Button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { ExportAPI } from '@/api'
import Button from '@/components/atoms/Button.vue'

const loading = ref(false)
</script>
