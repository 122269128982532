<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col gap-2">
      <p
        v-if="!downloadReady"
        class="text-lg my-4"
      >
        Click the button below to open the selfie modal:
      </p>
      <Button
        v-if="!downloadReady"
        class="w-1/12"
        @click="openSelfieModal"
      >
        Open selfie modal
      </Button>
    </div>
    <div class="flex flex-row gap-4">
      <Button
        v-if="downloadReady"
        class="w-1/12"
        @click="downloadFaceArchitect"
      >
        Face Analysis
      </Button>
      <Button
        v-if="downloadReady"
        class="w-1/12"
        @click="downloadSkinDiag"
      >
        Skin Diag
      </Button>
    </div>
    <div class="flex flex-col gap-2">
      <p class="text-lg my-4">
        Click the button below to delete this user's diag results from the server:
      </p>
      <Button
        class="w-1/12"
        @click="deleteDiagResults"
      >
        Delete diag data
      </Button>
      <p class="w-1/4 text-base my-4">{{ deleteButtonStatus }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useVisionStore } from '@/store/visionStore'
import Button from '@/components/atoms/Button.vue'
import { saveAs } from 'file-saver'
import { computed, ref } from 'vue'
import { VisionAPI } from '@/api'
import { useRouter } from 'vue-router'
import { logError } from '@/utils/errorUtils'

const router = useRouter()

const visionStore = useVisionStore()

const deleteButtonStatus = ref('')

const downloadReady = computed(() => {
  return visionStore.diagComplete
})

const openSelfieModal = () => {
  router.push({ name: 'scan' })
}

const strToBlob = (content: string) => {
  return new Blob([content], { type: 'application/json' })
}

const downloadFaceArchitect = () => {
  try {
    saveAs(strToBlob(JSON.stringify(visionStore.diagResult!.faceShape)), 'face_architect.json')
  } catch (e) {
    logError(e)
  }
}

const downloadSkinDiag = () => {
  try {
    saveAs(strToBlob(JSON.stringify(visionStore.diagResult!.skinDiag)), 'skin_diag.json')
  } catch (e) {
    logError(e)
  }
}

const deleteDiagResults = async () => {
  try {
    const result = await VisionAPI.deleteDiagResults()
    deleteButtonStatus.value = `${result.faceShape}, ${result.skinDiag}`
  } catch (e) {
    deleteButtonStatus.value =
      'There was an error deleting the diag results. Please check the console for more information.'
    logError(e)
  }
}
</script>
