<template>
  <div class="flex flex-row items-baseline text-black text-xs gap-2 font-normal">
    <div class="flex flex-row relative">
      <!-- filled stars -->
      <div
        class="flex flex-row overflow-hidden absolute left-0 gap-1"
        :style="`width: ${percent * starsWidth}px;`"
      >
        <template
          v-for="i in 5"
          :key="i"
        >
          <SvgIcon
            name="star"
            class="stroke-custom-tw-ratings-star-stroke shrink-0"
          />
        </template>
      </div>
      <!-- unfilled stars -->
      <div class="flex flex-row width-[70px] gap-1">
        <template
          v-for="i in 5"
          :key="i"
        >
          <SvgIcon
            name="star"
            class="[&_path]:fill-custom-tw-ratings-star-fill stroke-custom-tw-ratings-star-stroke"
          />
        </template>
      </div>
    </div>
    <div
      v-if="!pdp"
      class="number relative top-[-2px]"
    >
      {{ props.rating }}/5
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { computed, ref } from 'vue'

const props = defineProps<{
  rating: number
  ratingCount: number
  pdp?: boolean
}>()

const starsWidth = ref(86)
const percent = computed(() => props.rating / 5)
</script>
