<template>
  <div
    class="w-full flex flex-row items-top cursor-pointer"
    @click="toggle = !toggle"
  >
    <div class="w-[90%]">
      <slot name="header" />
    </div>
    <SvgIcon
      v-if="toggle"
      class="w-[10%] cursor-pointer"
      name="pdp-collapsable-close"
    />
    <SvgIcon
      v-else
      class="w-[10%]"
      name="pdp-collapsable-open"
    />
  </div>
  <slot name="divider" />
  <slot
    name="content"
    v-if="toggle"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import SvgIcon from '../atoms/SvgIcon.vue'

const toggle = ref(false)
</script>
