<template>
  <div class="w-full h-full relative bg-white">
    <div class="h-full flex flex-col justify-between items-center overflow-y-auto">
      <div class="flex flex-col px-12">
        <div class="mt-20 flex flex-row justify-center items-center flex-shrink-0">
          <img
            class="h-[96px] object-cover"
            :src="avatarImageRef"
            alt="pre-selfie modal avatar"
          />
        </div>
        <div class="flex flex-col items-center mt-10">
          <div class="text-[#161616] font-[Campton] font-[600] text-[28px] leading-[26px]">
            {{ titleText }}
          </div>
          <div class="mt-2 text-[#484848] font-[Inter] text-[14px] leading-[20px] text-center">
            {{ paragraphText }}
          </div>
        </div>
        <div class="flex flex-col justify-center gap-8 mt-8">
          <div class="flex flex-row gap-4 items-center">
            <div
              class="w-[30px] h-[30px] bg-[#F5F1EA] flex flex-row justify-center items-center rounded-full"
            >
              <SvgIcon name="pre-scan-camera" />
            </div>
            <div class="w-full font-[Inter] text-[14px] font-[500] leading-[18px]">
              Allow camera access when prompted
            </div>
          </div>
          <div class="flex flex-row gap-4 items-center">
            <div
              class="w-[30px] h-[30px] bg-[#F5F1EA] flex flex-row justify-center items-center rounded-full"
            >
              <SvgIcon name="pre-scan-glasses" />
            </div>
            <div class="w-full font-[Inter] text-[14px] font-[500] leading-[18px]">
              Remove glasses, hats, makeup, and tuck your hair back for the best results
            </div>
          </div>
          <div class="flex flex-row gap-4 items-center">
            <div
              class="w-[30px] h-[30px] bg-[#F5F1EA] flex flex-row justify-center items-center rounded-full"
            >
              <SvgIcon name="pre-scan-selfie" />
            </div>
            <div class="w-full font-[Inter] text-[14px] font-[500] leading-[18px]">
              Tap the "Take Selfie" button when ready
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col px-6 mb-2">
        <DesignedButton
          class="mt-[6px] mb-2"
          data-testid="PreSelfieModal-letsgo-button"
          @click="modalStore.setSelfie()"
        >
          LET'S GO
        </DesignedButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
interface IPreSelfieModalProps {
  type: string
}
</script>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useModalControllerStore } from '@/store'
import DesignedButton from '@/components/designedComponents/DesignedButton.vue'

const modalStore = useModalControllerStore()

const avatarImageRef = ref('/images/pre-makeup-selfie-avatar.png')

const props = withDefaults(defineProps<IPreSelfieModalProps>(), {
  type: 'SKINCARE'
})

const titleText = ref('Face Analysis')
const paragraphText = ref(
  'A quick selfie is all it takes to analyze your facial structure. Say goodbye to generic recommendations - hello, skincare that targets what your skin really needs.'
)

onMounted(() => {
  if (props.type === 'SKINCARE') {
    avatarImageRef.value = '/images/pre-skincare-selfie-avatar.png'
    titleText.value = 'Skin Analysis'
    paragraphText.value =
      'A quick selfie is all it takes to analyze your skin. Say goodbye to generic recommendations - hello, skincare that targets what your skin really needs.'
  }
})
</script>
