<template>
  <LoginPageContents
    class="flex flex-col justify-between w-full h-full relative bg-custom-tw-background-sign-in"
  >
    <FirebaseUI :callback="loginSuccessful" />
  </LoginPageContents>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/authStore'
import FirebaseUI from '@/components/molecules/FirebaseUI.vue'
import '@/assets/firebaseui.css'
import LoginPageContents from '@/components/molecules/LoginPageContents.vue'
import { tracking } from '@/tracking/EventController'
import FirebaseAuth from '@/auth/firebase'

const authStore = useAuthStore()
const auth = new FirebaseAuth(authStore)
const router = useRouter()

const redirect = async () => {
  await router.push({ name: 'chat' })
  router.go(0)
}

const loginSuccessful = async () => {
  tracking.loginEvent()
  if (authStore.oldUser?.isAnonymous ?? false) await authStore.mergeAccounts()
  authStore.oldUser = null
  await redirect()
}

const handleMounted = async () => {
  if (
    (await auth.authenticated()) &&
    (!authStore.isAnonymous || import.meta.env.VITE_ALLOW_ANONYMOUS === 'false')
  ) {
    await redirect()
  }
  authStore.oldUser = null
  if (authStore.user?.isAnonymous ?? false) authStore.oldUser = authStore.user
}

onMounted(() => {
  handleMounted()
})
</script>

<style lang="scss">
.firebaseui-container {
  background-color: #fafafa;
  margin: 0 0;
  max-width: 100%;
}

.firebaseui-card-content {
  padding: 0 0;
}

.firebaseui-idp-list {
  list-style: none;
  margin: 0 0;
  padding: 0;
}

.firebaseui-idp-list > .firebaseui-list-item {
  margin-bottom: 16px 0;
  text-align: center;
}

.firebaseui-button {
  display: flex;
  height: 48px;
  margin-left: 0px;
  min-width: 100%;
}

.firebaseui-idp-button {
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 6px;
  width: 100%;
  max-width: 100%;
  height: 48px;
}

.mdl-button {
  border-radius: 0.375rem;
  height: 48px;
  margin: 0;
  min-width: 64px;
  width: 100%;
  max-width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Campton, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  vertical-align: middle;
  box-shadow: none;
  border: #bebebe;
}

.mdl-button:hover {
  background-color: #636363;
}

.mdl-button:focus:not(:active) {
  background-color: #2e2e2e;
}

.mdl-button:active {
  background-color: #2e2e2e;
}

.mdl-button:focus-visible {
  background-color: #010101;
  border: 4px solid #055ccb;
}

.mdl-button.mdl-button--colored {
  color: #ffffff;
}

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: #010101;
}

.mdl-button--raised {
  background: #010101;
  box-shadow: none;
}

.mdl-button--raised:active {
  box-shadow: none;
  background-color: #2e2e2e;
}

.mdl-button--raised:focus:not(:active) {
  box-shadow: none;
  background-color: #2e2e2e;
}

.mdl-button--raised:focus-visible {
  background-color: #010101;
  border: 4px solid #055ccb;
}

.mdl-button--raised.mdl-button--colored {
  background: #010101;
  color: #fff;
}

.mdl-button--raised.mdl-button--colored:hover {
  background-color: #636363;
}

.mdl-button--raised.mdl-button--colored:active {
  background-color: #2e2e2e;
}

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: #2e2e2e;
}

.mdl-button--raised.mdl-button--colored:focus-visible:not(:active) {
  background-color: #010101;
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.firebaseui-idp-google {
  background-color: #010101 !important;
}

.firebaseui-idp-google > .firebaseui-idp-text {
  color: #ffffff;
}

.firebaseui-idp-password {
  background-color: #010101 !important;
}

.firebaseui-idp-icon {
  height: 24px;
  width: 24px;
}

.firebaseui-idp-text {
  text-transform: capitalize;
}

.firebaseui-card-header {
  display: none;
}

.firebaseui-card-actions {
  padding: 0 0;
}

.firebaseui-textfield {
  height: 48px;
  padding: 0 18px;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  border: 2px solid #bebebe;
  border-radius: 4px;
}

.mdl-textfield {
  background-color: #ffffff;
  font-size: 14px;
  display: flex;
  width: 100%;
  max-width: 100%;
}

.mdl-textfield:hover {
  background-color: #eeeeee;
}

.firebaseui-textfield:active {
  border-color: #055ccb;
}

.mdl-textfield.is-focused {
  border-color: #055ccb;
}

.mdl-textfield__label {
  color: #161616;
  font-size: 14px;
  left: 16px;
  display: flex;
  top: 12px;
}

.mdl-textfield__input {
  border-bottom: none;
  font-size: 14px;
}

input[type='email']:focus {
  border: none;
  box-shadow: none;
}

input[type='text']:focus {
  border: none;
  box-shadow: none;
}

input[type='password']:focus {
  border: none;
  box-shadow: none;
}

.firebaseui-label {
  color: #808080;
  direction: ltr;
  font-size: 16px;
  text-align: left;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  color: #5c000a;
  box-shadow: none;
}

.mdl-textfield.is-invalid {
  border-color: #5c000a;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #5c000a;
  font-size: 12px;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 0%;
}

.mdl-textfield.is-dirty .mdl-textfield__expandable-holder,
.mdl-textfield.is-focused .mdl-textfield__expandable-holder {
  max-width: 0px;
}

.firebaseui-input {
  color: #161616;
  border: 0;
}

.firebaseui-error {
  color: #5c000a;
  line-height: 18px;
  text-align: left;
}

.firebaseui-text-input-error {
  margin: 8px 0 16px;
  border-color: #5c000a;
  color: #5c000a;
}

.mdl-textfield.firebaseui-textfield-invalid {
  border-color: #5c000a;
}

.mdl-textfield--floating-label.mdl-textfield.firebaseui-textfield-invalid .mdl-textfield__label {
  color: #5c000a;
}

.mdl-textfield--floating-label.mdl-textfield.is-invalid .mdl-textfield__label {
  color: #5c000a;
}

.mdl-textfield.firebaseui-textfield-invalid .mdl-textfield__input {
  color: #5c000a;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #808080;
}

.mdl-shadow--2dp {
  box-shadow: none;
}

.mdl-textfield__label:after {
  transition-duration: 0s;
  transition-timing-function: none;
}

.firebaseui-input-floating-button {
  position: absolute;
  right: 10px;
  top: 13px;
}

.firebaseui-form-actions {
  display: flex;
  flex-direction: column-reverse;
}
</style>
