import { userHasNotConsented } from '@/utils/user-utils'
import { useAdminStore } from './adminStore'
import { useAuthStore } from './authStore'
import { useChatStore } from './chatStore'
import { useFlagStore } from './flagStore'
import { useMissionStore } from './missionStore'
import { useModalControllerStore } from './modalStore'
import { usePopularQuestionStore } from './popularQuestionStore'
import { useProductStore } from './productStore'
import { useSelfieStore } from './selfieStore'
import { useUIStore } from './uiStore'
import { useVisionStore } from './visionStore'
import { FlagAPI } from '@/api'

const useStores = () => ({
  adminStore: useAdminStore(),
  authStore: useAuthStore(),
  chatStore: useChatStore(),
  flagStore: useFlagStore(),
  missionStore: useMissionStore(),
  popularQuestionStore: usePopularQuestionStore(),
  visionStore: useVisionStore()
})

/**
 * Initialize Pinia stores i.e. fetch necessary data from bff.
 */
const initStores = async () => {
  const stores = useStores()

  // getting user flags
  stores.authStore.setConsented((await FlagAPI.getFlag('consent')) ?? false)
  // check user flags
  if (stores.authStore.user?.isAnonymous ?? false) await FlagAPI.setFlag('isAnonymous', true)
  await stores.flagStore.fetchUserFlags()
  const startUpData = [
    stores.missionStore.fetchMissions(),
    stores.popularQuestionStore.fetchQuestions(),
    stores.visionStore.fetchDiagResults()
  ]

  if (stores.adminStore.chatHistoryEnabled) {
    startUpData.push(stores.chatStore.getHistory())
  }

  await Promise.allSettled(startUpData)
  await stores.chatStore.startNewSession()
  userHasNotConsented(stores.authStore, true)
  stores.chatStore.setReady()
}

export {
  useAdminStore,
  useAuthStore,
  useChatStore,
  useFlagStore,
  useMissionStore,
  useModalControllerStore,
  usePopularQuestionStore,
  useProductStore,
  useSelfieStore,
  useUIStore,
  useVisionStore,
  initStores
}
