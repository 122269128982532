import { clients } from './client'
import type { mergeAccount } from '@/types/account'
import { useAuthStore } from '@/store'
import { logError } from '@/utils/errorUtils'

const mergeAccounts = async (value: mergeAccount) => {
  if (!(await useAuthStore().checkToken())) return
  await clients.bff.post(`/account/merge`, value)
}

const generateUUID = async () => {
  try {
    if (window.crypto?.randomUUID) return crypto.randomUUID()
    if (!(await useAuthStore().checkToken())) throw new Error('Failed to authenticate user token')
    const uuid = await clients.bff.get(`/crypto/uuid`)
    return uuid.data as string
  } catch (error) {
    logError(error)
    throw new Error('Failed to generate UUID')
  }
}

export default {
  mergeAccounts,
  generateUUID
}
