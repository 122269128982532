<template>
  <div class="flex flex-row w-full items-top justify-between pl-3 pr-6 py-4">
    <div>
      <span class="text-base pr-1">Overall rating:</span>
      <span class="text-base font-semibold">{{ rating }}</span>
    </div>
    <div class="flex flex-col pt-0.5">
      <Rating
        class="justify-end"
        :rating="rating"
        :rating-count="ratingCount"
        :pdp="true"
      />
      <span class="text-xs text-right pt-2">{{ ratingCount }} Reviews</span>
    </div>
  </div>

  <ReviewBar
    v-if="reviews[0]?.RatingDistributions"
    :total-reviews="ratingCount"
    :rating-distributions="reviews[0].RatingDistributions"
  />

  <div
    v-if="!isEmpty(reviews)"
    class="flex flex-col gap-2 w-full pt-6"
  >
    <template
      v-for="review in reviews"
      :key="review.UserNickname"
    >
      <div
        v-if="review.ReviewText"
        class="flex flex-col gap-1 rounded-lg border border-gray-300 p-4"
      >
        <div class="w-full flex flex-row pb-4 pt-1">
          <div class="w-[60%]">
            <GreyTextBox :text="'Most helpful ' + review.type + ' review'" />
          </div>
          <Rating
            class="w-[40%] pl-5 justify-end"
            :rating="toNumber(review.Rating)"
            :rating-count="1"
            :pdp="true"
          />
        </div>
        <span class="text-[12px] leading-[18px] font-semibold text-[#484848]">
          {{ review.UserNickname }}
        </span>
        <h4 class="font-semibold text-[16px] leading-[28px] text-[#161616]">{{ review.Title }}</h4>
        <p class="text-sm">
          {{ review.ReviewText }}
        </p>
        <div class="w-full flex flex-row items-center pt-2">
          <SvgIcon
            class="w-[24px] h-[16px] pr-1"
            name="thumb-up"
            color="black"
          />
          <span class="text-sm font-medium text-right pr-1">
            {{ review.TotalPositiveFeedbackCount }}
          </span>
          <span class="text-sm font-medium">people found this helpful</span>
        </div>
      </div>
    </template>
  </div>
  <div
    v-else-if="productStore.fetchingReviews"
    class="relative flex flex-col w-full p-1 h-full"
  >
    <Loading />
  </div>
</template>

<script lang="ts">
import type { ProductReview } from '@/types/products'

interface IReviewsProps {
  reviews: ProductReview[]
  rating: number
  ratingCount: number
}
</script>

<script setup lang="ts">
import { isEmpty, toNumber } from 'lodash'
import Rating from '@/components/molecules/Ratings.vue'
import { useProductStore } from '@/store/productStore'
import Loading from '@/components/atoms/Loading.vue'
import ReviewBar from '@/components/atoms/ReviewBar.vue'
import GreyTextBox from '@/components/atoms/GreyTextBox.vue'
import SvgIcon from '../atoms/SvgIcon.vue'

const productStore = useProductStore()

defineProps<IReviewsProps>()
</script>
