<template>
  <div
    :class="[
      open ? '' : 'pointer-events-none',
      'z-30 h-full w-full absolute left-0 top-0 overflow-hidden flex flex-col items-center'
    ]"
  >
    <Modal
      :open="isCurrent(Modals.PROFILE)"
      type="drawer"
      :close-button="false"
      :back-button="true"
      @close="onBack"
      button-color="light"
    >
      <ProfileModal @close="onBack" />
    </Modal>

    <Modal
      :open="isCurrent(Modals.EDIT_NAME)"
      type="drawer"
      :close-button="false"
      :back-button="true"
      @close="onBack"
      button-color="dark"
    >
      <EditNameModal @close="onBack" />
    </Modal>

    <Modal
      :open="isCurrent(Modals.VTO)"
      :close-button="false"
      :back-button="true"
      @close="onBack"
    >
      <VTOModal
        :upcs="vto?.upcs"
        :selected="vto?.selected"
        :msg-index="vto?.msgIndex"
        v-if="isPreviousOrCurrent(Modals.VTO)"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.PRODUCT)"
      :close-button="false"
      type="drawer"
      button-color="dark"
      button-background-color="#ffffffB3"
      :back-button="true"
      @close="onBack"
    >
      <ProductCardModal
        :upc="productCard?.upc"
        :msg-index="productCard?.msgIndex"
        v-if="isPreviousOrCurrent(Modals.PRODUCT)"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.PRE_SELFIE)"
      :close-button="false"
      :back-button="true"
      @close="onBack"
      button-color="dark"
    >
      <PreSelfieModal
        :type="modalStore.SelfieType"
        v-if="isPreviousOrCurrent(Modals.PRE_SELFIE)"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.SKIN_INFO)"
      :close-button="false"
      :back-button="true"
      @close="onBack"
      button-color="dark"
    >
      <SkinInfoModal
        :type="modalStore.SelfieType"
        v-if="isPreviousOrCurrent(Modals.SKIN_INFO)"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.SELFIE)"
      :close-button="true"
      :back-button="false"
      @close="onClose"
      button-color="dark"
    >
      <SelfieModal
        v-if="isPreviousOrCurrent(Modals.SELFIE)"
        @close="onClose"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.FEEDBACK)"
      :close-button="true"
      :back-button="false"
      button-color="dark"
      @close="onClose"
    >
      <FeedbackModal
        v-if="isPreviousOrCurrent(Modals.FEEDBACK)"
        @close="onClose"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.ROUTINE)"
      :close-button="false"
      :back-button="true"
      type="drawer"
      button-color="dark"
      @close="onBack"
    >
      <RoutineModal
        v-if="isPreviousOrCurrent(Modals.ROUTINE)"
        :routine="routineData?.routine"
        :msg-index="routineData?.msgIndex"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.SKINDIAG)"
      :close-button="false"
      :back-button="true"
      type="drawer"
      button-color="dark"
      @close="onBack"
    >
      <SkinDiagModal />
    </Modal>

    <Modal
      :open="isCurrent(Modals.TERMS_CONDS)"
      :close-button="false"
      :back-button="false"
      type="drawer"
    >
      <TermsConds />
    </Modal>

    <Modal
      :open="isCurrent(Modals.LOGIN)"
      :close-button="false"
      :back-button="true"
      button-color="dark"
      button-background-color="#ffffffB3"
      @close="onBack"
    >
      <LoginModal />
    </Modal>

    <Modal
      :open="isCurrent(Modals.DELETE_ACCOUNT)"
      :close-button="false"
      :back-button="true"
      button-color="dark"
      button-background-color="#ffffffB3"
      @close="onBack"
    >
      <DeleteAccountModal />
    </Modal>

    <Modal
      :open="isCurrent(Modals.INFORMATION_PAGE)"
      :close-button="false"
      :back-button="true"
      button-color="dark"
      type="drawer"
      button-background-color="#ffffffB3"
      @close="onBack"
    >
      <InformationPage
        :id="modalStore.informationPage.id"
        :content="modalStore.informationPage.content"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.INFORMATION_PAGES)"
      type="drawer"
      :close-button="false"
      :back-button="true"
      @close="onBack"
      button-color="dark"
    >
      <InformationPages />
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import Modal from '@/components/layout/Modal.vue'
import VTOModal from '@/pages/VTOModal.vue'
import SelfieModal from '@/pages/SelfieModal.vue'
import ProductCardModal from '@/pages/ProductCardModal.vue'
import FeedbackModal from '@/pages/FeedbackModal.vue'
import SkinDiagModal from '@/pages/SkinDiagModal.vue'
import RoutineModal from '@/pages/RoutineModal.vue'
import { useModalControllerStore } from '@/store/modalStore'
import { Modals } from '@/types/modal'
import ProfileModal from './ProfileModal.vue'
import PreSelfieModal from '@/pages/PreSelfieModal.vue'
import TermsConds from './TermsConds.vue'
import InformationPage from './InformationPage.vue'
import LoginModal from './LoginModal.vue'
import EditNameModal from '@/pages/EditNameModal.vue'
import DeleteAccountModal from '@/pages/DeleteAccountModal.vue'
import SkinInfoModal from './SkinInfoModal.vue'
import InformationPages from '@/pages/InformationPages.vue'

const modalStore = useModalControllerStore()

const running = computed(() => modalStore.running)
const vto = computed(() => modalStore.vto)
const productCard = computed(() => modalStore.productCard)
const routineData = computed(() => modalStore.routine)
const open = computed(() => modalStore.running !== Modals.NONE)

const onClose = () => {
  modalStore.close()
}

const onBack = () => {
  modalStore.back()
}

const previousModal = () => modalStore.previous()
const isPrevious = (modal: Modals) => modal === previousModal()
const isCurrent = (modal: Modals) => modal === running.value
const isPreviousOrCurrent = (modal: Modals) => isPrevious(modal) || isCurrent(modal)
</script>
