<template>
  <div
    class="flex flex-col justify-between w-full h-full relative bg-custom-tw-background-sign-in"
    data-testid="terms-conds"
  >
    <!-- top section -->
    <div class="relative px-4">
      <div
        id="termsCondsTopBar"
        class="pt-3 flex flex-row justify-start"
        :class="customTermsCondsTopBarStyle"
      >
        <BackButton
          v-show="modalStore.termsCondsIndex > 0"
          color="dark"
          background="#FFFFFFB3"
          class="w-[32px] h-[32px] shadow-[0_1px_8px_0px_rgba(25,23,23,0.04)] backdrop-blur-[10px]"
          @click="onPage2BackButtonClick"
        />
        <!-- progress bar -->
        <div class="w-full h-[32px] ml-6 mr-14 flex flex-col justify-center">
          <div class="bg-[#EEEEEE] w-full h-1 rounded-[4px]">
            <div
              ref="completionBarRef"
              class="custom-completion-bar bg-black h-full rounded-[4px] transition-all"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="h-full w-full flex flex-col justify-start overflow-y-auto">
      <!-- img -->
      <div class="mt-8 flex flex-row justify-center items-center flex-shrink-0">
        <img
          class="h-[156px] object-cover"
          src="/images/R.png"
          alt="VBC logo"
        />
      </div>
      <div
        v-if="modalStore.termsCondsIndex === 0"
        class="h-full w-full flex flex-col justify-start px-4"
      >
        <div class="mt-8 flex flex-col items-start justify-start">
          <div class="text-[#161616] font-[Campton] font-semibold text-[28px] leading-[36px]">
            Welcome to BeautyGenius Beta
          </div>
          <div class="mt-2 text-[#484848] font-normal text-sm leading-5">
            Thanks for joining us as we test out Beauty Genius, L'Oréal Paris' AI-powered beauty
            assistant. Just a heads up:
          </div>
        </div>
        <div class="mt-8 flex flex-col justify-start">
          <ul class="list-disc ml-[20px] space-y-4">
            <li>
              <div class="text-[#161616] font-semibold text-[16px] leading-7">
                Your feedback is important
              </div>
              <div class="text-[#484848] font-normal text-sm">
                We use fancy AI, but it's learning! Responses can be inaccurate, so your feedback
                within the app is super helpful.
              </div>
            </li>
            <li>
              <div class="text-[#161616] font-semibold text-[16px] leading-7">
                Think Beauty, not Doctor
              </div>
              <div class="text-[#484848] font-normal text-sm">
                Beauty Genius focuses on beauty and is not a medical device.
              </div>
            </li>
            <li>
              <div class="text-[#161616] font-semibold text-[16px] leading-7">
                Conversations are recorded
              </div>
              <div class="text-[#484848] font-normal text-sm">
                To help us develop and improve Beauty Genius, your conversations with the app will
                be recorded and shared with L'Oréal S.A. (L'Oréal's parent company) and their
                trusted partners who help us build the technology (data processors).
              </div>
            </li>
            <li>
              <div class="text-[#161616] font-semibold text-[16px] leading-7">
                Information to Avoid Sharing
              </div>
              <div class="text-[#484848] font-normal text-sm">
                Avoid sharing any sensitive information such as credit card numbers or specific
                health conditions or diagnoses.
              </div>
            </li>
            <li>
              <div class="text-[#161616] font-semibold text-[16px] leading-7">
                We're an AI Assistant
              </div>
              <div class="text-[#484848] font-normal text-sm">
                Remember, you're talking with an AI, not a real person.
              </div>
            </li>
          </ul>
        </div>
        <div class="text-[#484848] text-[12px] font-normal leading-[18px] pt-8 flex-shrink-0 pb-10">
          We're excited to have you on board! Let's unlock the secrets to your best skin together.
        </div>
      </div>
      <!-- page 2 -->
      <div
        v-else-if="modalStore.termsCondsIndex === 1"
        class="h-full w-full flex flex-col justify-start px-4"
      >
        <div class="mt-8 flex flex-col justify-start">
          <div class="text-[#161616] font-[Campton] font-semibold text-[28px] leading-[36px]">
            Before we get started
          </div>
          <div class="mt-2 text-[#484848] font-normal text-sm leading-5">
            To access Beauty Genius, we also require your agreement to Our
            <a
              class="text-[#002A65] font-semibold underline underline-offset-[3px] hover:no-underline hover:cursor-pointer"
              @click="modalStore.setInformationPage('termsOfUse')"
            >
              Terms of Use
            </a>
            . These terms (among other things) provide us with the right to use any content and
            information you submit to Beauty Genius to improve our products and services and
            includes an arbitration provision to resolve disputes.
            <br />
            <br />
            Beauty Genius uses the information you provide (including a selfie, if you choose) to
            personalize your experience. For more information, please see our
            <a
              class="text-[#002A65] font-semibold underline underline-offset-[3px] hover:no-underline hover:cursor-pointer"
              @click="modalStore.setInformationPage('privacyNotice')"
            >
              Privacy Notice
            </a>
            .
            <br />
            <br />
            Beauty Genius offers a optional feature that lets you analyze your skin and virtually
            try-on products using a selfie. You're not required to use this feature at all.
            <br />
            <br />
            To access Beauty Genius, we require confirmation that you understand how we handle
            selfie data, even if you choose not to use the feature right now.
          </div>
        </div>
        <div class="mt-8 flex flex-col justify-start">
          <div class="text-[#161616] font-[Campton] font-semibold text-[20px] leading-[28px]">
            Please confirm the following:
          </div>
          <div>
            <ul class="py-8 space-y-4">
              <li class="flex flex-row items-start">
                <DesignedCheckbox
                  data-testid="terms-conds-checkbox1"
                  v-model="checkbox1"
                />
                <div class="flex flex-col">
                  <div class="text-[#161616] font-semibold font-[16px]leading-7">
                    Information notice
                  </div>
                  <div class="mt-2 text-[#484848] font-normal text-sm leading-5">
                    I consent to having my face scanned and image processed as described in the
                    <a
                      class="text-[#002A65] font-semibold underline underline-offset-[3px] hover:no-underline hover:cursor-pointer"
                      @click="modalStore.setInformationPage('informationNotice')"
                    >
                      Beauty Genius Information Notice
                    </a>
                    and agree to all its terms, including as regards data retention, data deletion,
                    and data use, processing, storage, and transfer.
                  </div>
                </div>
              </li>
              <li class="flex flex-row items-start">
                <DesignedCheckbox
                  data-testid="terms-conds-checkbox2"
                  v-model="checkbox2"
                />
                <div class="flex flex-col">
                  <div class="text-[#161616] font-semibold font-[16px]leading-7">Terms of use</div>
                  <div class="mt-2 text-[#484848] font-normal text-sm leading-5">
                    I confirm I have reviewed the
                    <a
                      class="text-[#002A65] font-semibold underline underline-offset-[3px] hover:no-underline hover:cursor-pointer"
                      @click="modalStore.setInformationPage('termsOfUse')"
                    >
                      Terms of Use
                    </a>
                    and agree to all terms set forth therein.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div
      v-if="modalStore.termsCondsIndex === 0"
      class="px-4 flex flex-col items-center"
    >
      <DesignedButton
        data-testid="terms-conds-next-pg1"
        @click="onPage1NextButtonClick"
        class="mt-[6px] mb-2"
      >
        NEXT
      </DesignedButton>
    </div>
    <div
      v-else-if="modalStore.termsCondsIndex === 1"
      class="px-4 flex flex-col items-center"
    >
      <DesignedButton
        data-testid="terms-conds-accept-pg2"
        class="mt-[6px] mb-2"
        @click="updateConsented"
        :disabled="!bothChecked"
      >
        GET STARTED
      </DesignedButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import BackButton from '@/components/atoms/BackButton.vue'
import DesignedCheckbox from '@/components/designedComponents/DesignedCheckbox.vue'
import DesignedButton from '@/components/designedComponents/DesignedButton.vue'
import { useModalControllerStore, useAuthStore } from '@/store'
import { FlagAPI } from '@/api'
import { tracking } from '@/tracking/EventController'

const modalStore = useModalControllerStore()
const authStore = useAuthStore()

const checkbox1 = ref(false)
const checkbox2 = ref(false)

const completionBarRef = ref<HTMLDivElement | null>(null)

const customTermsCondsTopBarStyle = computed(() => {
  const style: string[] = []
  if (modalStore.termsCondsIndex === 0) {
    style.push('custom-padding-left')
  }
  return style
})

const setCompletionBar = (value: string) => {
  if (completionBarRef.value) {
    completionBarRef.value.style.setProperty('--completion-bar', value)
  }
}

const bothChecked = computed(() => checkbox1.value && checkbox2.value)

const onPage1NextButtonClick = () => {
  modalStore.termsCondsIndex = 1
  checkbox1.value = false
  checkbox2.value = false
  setCompletionBar('100%')
}
const onPage2BackButtonClick = () => {
  modalStore.termsCondsIndex = 0
  setCompletionBar('50%')
}

const updateConsented = async () => {
  await FlagAPI.setFlag('consent', true)
  authStore.setConsented(true)
  tracking.privacyConsentEvent()
  modalStore.close()
}

onMounted(() => {
  if (modalStore.termsCondsIndex === 1) {
    setCompletionBar('100%')
  }
})
</script>

<style scoped lang="scss">
.custom-padding-left {
  padding-left: 32px;
}

@property --completion-bar {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: false;
}

.list-disc li::marker {
  color: #000000;
  font-size: 1.25rem;
}

.custom-completion-bar {
  width: var(--completion-bar);
}
</style>
