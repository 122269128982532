import type { Flag } from '@/types/flag'
import { clients } from './client'
import { useAuthStore } from '@/store'

const getAllFlags = async () => {
  if (!(await useAuthStore().checkToken())) return
  const resp = await clients.bff.get(`/flag`)
  return resp.data as Flag[]
}

const getFlag = async (flag: string) => {
  if (!(await useAuthStore().checkToken())) return
  const resp = await clients.bff.get(`/flag/${flag}`)
  return resp.data as boolean | null
}

const setFlag = async (flag: string, value: boolean) => {
  if (!(await useAuthStore().checkToken())) return
  const resp = await clients.bff.post(`/flag/${flag}`, { value })
  return resp.data as Flag
}

export default {
  getAllFlags,
  getFlag,
  setFlag
}
