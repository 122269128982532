<template>
  <div class="button-container">
    <template
      v-for="shade in getShades()"
      :key="shade.idGenAI"
    >
      <div class="flex justify-center w-14 lg:w-10">
        <button
          class="swatch-button"
          :shade="shade"
          @click="() => $emit('productSelected', shade.idGenAI)"
        >
          <SvgIcon
            name="swatch"
            class="absolute cursor-pointer top-[30] border border-current rounded-full"
            :style="getRGB(shade) + getCalculatedStyle(shade.idGenAI || '')"
          />
        </button>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { isMobile } from '@/utils/web'
import type { ProductData } from '@/types/products'
import { MFE } from '@/utils/mfe/liveModule'
import { histogramToRGB } from '@/utils/color'
import { logError } from '@/utils/errorUtils'

//props
const props = defineProps<{ shades: ProductData[]; upc: string; makeupType: string }>()

defineEmits(['productSelected'])

const getShades = () => {
  return props.shades.filter((shade: ProductData) => !!shade.shadeArray)
}

const getCalculatedStyle = (upc: string) => {
  let size = !isMobile() ? 25 : 35
  size += props.upc === upc ? 10 : 0 //if selected upc
  return `height: ${size}px; width: ${size}px; `
}

const getRGB = (shade: ProductData) => {
  let shadeArray = shade.shadeArray[0]
  try {
    if (MFE.isHair(props.makeupType)) {
      const rgb = histogramToRGB(shadeArray.histogram)
      return `fill:rgb(${rgb.red},${rgb.green},${rgb.blue});`
    }
    return `fill:rgb(${shadeArray['colorR']},${shadeArray['colorG']},${shadeArray['colorB']});`
  } catch (e) {
    logError(e)
    return `fill:rgb(0,0,0);`
  }
}
</script>
<style scoped lang="scss">
.swatch-button {
  background: none;
  border: none;
  padding-right: 5px;
  padding-left: 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  transition:
    width 0.8s,
    height 0.8s;
}

.button-container {
  height: 50px;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
