import { saveAs } from 'file-saver'
import { clients } from './client'
import { useAuthStore } from '@/store'

export default {
  getExportEndpoint: () => `${clients.bff.defaults.baseURL}/export`,
  getExport: async () => {
    if (!(await useAuthStore().checkToken())) return
    const resp = await clients.bff.get('/export', {
      responseType: 'blob'
    })

    let fileName = 'download'
    const contentDisposition = resp.headers['content-disposition']

    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = fileNameRegex.exec(contentDisposition)
      if (matches?.[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    saveAs(resp.data, fileName)
  }
}
