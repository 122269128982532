<template>
  <div class="relative w-full h-full flex justify-center page-offwhite">
    <div class="w-[90%] h-full flex flex-col overflow-y-auto overflow-x-hidden">
      <div class="sticky top-0 w-full h-[5rem] flex-shrink-0 page-offwhite z-10" />
      <div class="text-[28px] mb-4 font-semibold font-[Campton] leading-snug page-offwhite p-4">
        Your
        <span class="px-2 py-1 bg-[#F5F1EA] rounded-full">
          <span
            class="bg-gradient-to-r from-[#9D6D2F] to-[#F2CD95] inline-block text-transparent bg-clip-text"
          >
            {{ routineComplexity }}
          </span>
        </span>
        skincare routine, tailored for your skin type and your skin concerns.
      </div>
      <SectionToggle
        option1-text="Morning"
        option2-text="Evening"
        option1-icon="sun"
        option2-icon="moon"
        :option1-count="routine.morning.length"
        :option2-count="routine.evening.length"
        data-testid1="routine-toggle-morning"
        data-testid2="routine-toggle-evening"
        @toggle="onOptionSelected"
      />
      <div
        v-if="selectedOption === 0"
        class="w-full flex flex-col gap-4 justify-center items-center my-2"
      >
        <template
          v-for="(step, index) in routine.morning"
          :key="step.title"
        >
          <RoutineCardStep
            data-testid="morning-routine"
            :step="step"
            :index="index"
            :msg-index="msgIndex"
          />
        </template>
      </div>
      <div
        v-else-if="selectedOption === 1"
        class="w-full flex flex-col gap-4 justify-center items-center my-2"
      >
        <template
          v-for="(step, index) in routine.evening"
          :key="step.title"
        >
          <RoutineCardStep
            data-testid="evening-routine"
            :step="step"
            :index="index"
            :msg-index="msgIndex"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { GenAIRoutine } from '@/types/routine'

interface IDetailedRoutineCardProps {
  routine: GenAIRoutine
  msgIndex: number
}
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import SectionToggle from '@/components/molecules/SectionToggle.vue'
import RoutineCardStep from '@/components/molecules/RoutineCardStep.vue'

const props = defineProps<IDetailedRoutineCardProps>()

const selectedOption = ref(0)

const routineComplexity = computed(() =>
  props.routine.morning.length > 3 ? 'comprehensive' : 'simple'
)

const onOptionSelected = (index: number) => {
  selectedOption.value = index
}
</script>
