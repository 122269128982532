<template>
  <div
    v-if="anyReco"
    class="relative w-full h-[400px] flex flex-col flex-shrink-0 overflow-x-auto no-scrollbar"
  >
    <MouseDragContainer v-slot="{ moved, mouseDown }">
      <div
        class="flex flex-row gap-2 h-full w-full pt-4 pb-3 overflow-x-auto no-scrollbar scroll-smooth custom-min-w-max"
        :class="{ 'custom-cursor-pointer': !mouseDown || (mouseDown && moved) }"
      >
        <template
          v-for="(product, index) in recommendedProducts"
          :key="index"
        >
          <ChatProductCard
            :class="productCardStyle(index)"
            :product="product"
            :upc="product.idGenAI"
            :msg-index="msgIndex"
            :reco-id="index"
            :disable-click="moved"
            :mouseDown="mouseDown"
            @vto="(...args) => $emit('vto', ...args)"
          />
        </template>
      </div>
    </MouseDragContainer>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount, computed } from 'vue'
import _ from 'lodash'
import { useProductStore } from '@/store/productStore'
import type { ProductData } from '@/types/products'
import ChatProductCard from '@/components/chat/ChatProductCard.vue'
import MouseDragContainer from '@/components/chat/MouseDragContainer.vue'

const productStore = useProductStore()

/* Props & Emits */
const props = withDefaults(
  defineProps<{
    productUpcs: string[]
    msgIndex: number
    currId?: string
    triggeredByAdmin: boolean
  }>(),
  {
    productUpcs: () => [],
    triggeredByAdmin: true,
    currId: ''
  }
)

/* Computed Properties */
const recommendedProducts = ref<ProductData[]>([])

// returns true if the list of product recommendations is non-empty
const anyReco = computed(() => recommendedProducts.value.length || props.triggeredByAdmin)

const updateRecommendedProducts = async () => {
  const products = await productStore.getProducts(props.productUpcs)
  recommendedProducts.value = products.filter(
    (product: ProductData) => !_.isEmpty(product) && product.isFound
  )
}

const productCardStyle = (index: number) => {
  if (index === 0) {
    return ['custom-first-product-card']
  } else if (index === recommendedProducts.value.length - 1) {
    return ['custom-last-product-card']
  }
}

onBeforeMount(async () => await updateRecommendedProducts())

watch(() => props.productUpcs, updateRecommendedProducts)
</script>

<style scoped lang="scss">
.custom-first-product-card {
  margin-left: 1.5rem;
}

.custom-last-product-card {
  margin-right: 1.5rem;
}

.custom-min-w-max {
  min-width: max-content;
}

.custom-cursor-pointer {
  cursor: pointer;
}
</style>
