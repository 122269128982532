import type { VTOSliderMouseSlideConf, VTOSliderTouchSlideConf } from '@/types/vtoSlider'
import { clamp } from '@/utils/math.js'
import { MFE } from '@/utils/mfe/liveModule'

export function touchSlide(config: VTOSliderTouchSlideConf) {
  const vtoStyle = window.getComputedStyle(config.vtoContainer)
  const canvas = config.vtoContainer.querySelector('canvas') //vto canvas is wider then the modal
  if (!canvas) return
  const canvasStyle = window.getComputedStyle(canvas)

  // prevent default scroll behavior
  config.event.preventDefault()
  const makeupType = config.makeupType

  //set max and min (slider width) to prevent overflow
  const sliderWidth = parseFloat(config.compareSlider.style.width)
  const max = parseFloat(vtoStyle.width)
  const coverPadding = (parseFloat(canvasStyle.width) - parseFloat(vtoStyle.width)) / 2

  //get mouse position and starting position
  const touchX = config.event.touches[0].clientX
  const startX = parseFloat(window.getComputedStyle(config.compareSlider).left)

  //start slider
  MFE.setDualViewComparisonPercent(
    makeupType,
    (startX + coverPadding) / parseFloat(canvasStyle.width)
  )

  const ontouchmove = (e: TouchEvent) => {
    // prevent default scroll behavior
    e.preventDefault()
    e.stopPropagation()

    // prevent overflow by restricting the slider to be between min and max
    const leftDistance = clamp(
      startX + (e.touches[0].clientX - touchX),
      sliderWidth,
      max - sliderWidth
    )
    //find percentage minus width of the slider
    const percentageSlider = ((leftDistance - sliderWidth) / (max - sliderWidth)) * 100
    const percentageVTO = (leftDistance + coverPadding) / parseFloat(canvasStyle.width)
    // position the elements accordingly
    MFE.setDualViewComparisonPercent(makeupType, percentageVTO)
    config.compareSlider.style.left = `${percentageSlider}%`
  }

  config.vtoContainer.addEventListener('touchmove', ontouchmove, { passive: false })

  const ontouchend = () => {
    config.vtoContainer.removeEventListener('touchmove', ontouchmove)
    document.removeEventListener('touchend', ontouchend)
  }
  document.addEventListener('touchend', ontouchend)
}

export function mouseSlide(config: VTOSliderMouseSlideConf) {
  const vtoStyle = window.getComputedStyle(config.vtoContainer)
  const canvas = config.vtoContainer.querySelector('canvas') //vto canvas is wider then the modal
  if (!canvas) return
  const canvasStyle = window.getComputedStyle(canvas)

  //set max and min (slider width) to prevent overflow
  const sliderWidth = parseFloat(config.compareSlider.style.width)
  const max = parseFloat(vtoStyle.width)
  const coverPadding = (parseFloat(canvasStyle.width) - parseFloat(vtoStyle.width)) / 2

  const makeupType = config.makeupType
  //get mouse position and starting position
  const mouseX = config.event.clientX
  const startX = parseFloat(window.getComputedStyle(config.compareSlider).left)

  const onmousemove = (e: { clientX: number }) => {
    // prevent overflow by restricting the slider to be between min and max
    const leftDistance = clamp(startX + (e.clientX - mouseX), sliderWidth, max - sliderWidth)
    //find percentage
    const percentageSlider = ((leftDistance - sliderWidth) / (max - sliderWidth)) * 100
    const percentageVTO = (leftDistance + coverPadding) / parseFloat(canvasStyle.width)
    // position the elements accordingly
    MFE.setDualViewComparisonPercent(makeupType, percentageVTO)

    config.compareSlider.style.left = `${percentageSlider}%`
  }

  config.vtoContainer.addEventListener('mousemove', onmousemove)

  const onmouseup = () => {
    config.vtoContainer.removeEventListener('mousemove', onmousemove)
    config.compareSlider.removeEventListener('mouseup', onmouseup)
  }
  document.addEventListener('mouseup', onmouseup)
}
