<template>
  <button
    v-if="routine"
    data-testid="routine-to-detail"
    class="relative w-full px-6"
    @click="openDetailedRoutineCard"
  >
    <div class="w-[90%] h-[400px] relative">
      <img
        class="w-full h-full object-cover rounded-xl"
        src="/images/skin-routine-button-bg.png"
        alt="skin-routine-card"
      />
      <div
        class="w-full h-full absolute left-0 top-0 bg-gradient-to-b from-[#12121255] to-[#121212ee] rounded-xl"
      />
      <div class="w-full h-full absolute left-0 top-0">
        <div class="p-[16px] h-full w-full text-left text-[#FFF]">
          <div class="flex flex-col h-full w-full justify-between">
            <SvgIcon name="day-and-night" />
            <div class="flex flex-row w-full justify-between items-start">
              <div class="flex flex-col gap-1">
                <div class="text-[20px] leading-[28px] pr-6 font-[Campton] font-semibold">
                  An effective skincare routine for you
                </div>
                <div class="pr-6">
                  <div
                    class="bg-[#EEEEEE] rounded-[4px] leading-[28px] font-[Campton] font-semibold text-[#161616] px-2 inline-block"
                  >
                    {{ routineComplexity }}
                  </div>
                </div>
              </div>
              <div class="relative m-1">
                <SvgIcon
                  name="button-click-arrow-white"
                  class="absolute right-0 top-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import { GenAIRoutine, GenAIRoutineStep } from '@/types/routine'

interface IChatRoutineCardProps {
  routine: GenAIRoutine
  msgIndex: number
  productUpcs: string[]
}
</script>

<script setup lang="ts">
import { useModalControllerStore } from '@/store/modalStore'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { computed, onBeforeMount } from 'vue'
import type { ProductData } from '@/types/products'
import { isEmpty } from 'lodash'
import { useProductStore } from '@/store'
import { fuzzy } from 'fast-fuzzy'
import { getProductName } from '@/utils/product'

const modalControllerStore = useModalControllerStore()
const productStore = useProductStore()
const props = defineProps<IChatRoutineCardProps>()

onBeforeMount(async () => {
  const products = (await productStore.getProducts(props.productUpcs)).filter(
    (product: ProductData) => !isEmpty(product) && product.isFound
  )
  const steps = [...props.routine.morning, ...props.routine.evening]

  steps.forEach((step: GenAIRoutineStep) => {
    const matches = products.filter((product: ProductData) => {
      const res = fuzzy(getProductName(product), step.product, {
        ignoreCase: true,
        ignoreSymbols: true
      })
      return res > 0.5
    })
    if (!isEmpty(matches)) productStore.pushProductByName(step.product, matches[0])
  })
})

const routineComplexity = computed(() => {
  if (props.routine.morning.length > 3) {
    return 'Comprehensive'
  }
  return 'Simple'
})

const openDetailedRoutineCard = () =>
  modalControllerStore.setRoutine({ routine: props.routine, msgIndex: props.msgIndex })
</script>
