import { getUrlParam } from '@/utils/web'

/**
 * This script is used to load the Maze snippet on the client side.
 * It was acquired from the Maze dashboard.
 */
const MAZE_SCRIPT = `
(function (m, a, z, e) {
  var s, t;
  try {
    t = m.sessionStorage.getItem('maze-us');
  } catch (err) {}

  if (!t) {
    t = new Date().getTime();
    try {
      m.sessionStorage.setItem('maze-us', t);
    } catch (err) {}
  }

  s = a.createElement('script');
  s.src = z + '?apiKey=' + e;
  s.async = true;
  a.getElementsByTagName('head')[0].appendChild(s);
  m.mazeUniversalSnippetApiKey = e;
})(window, document, 'https://snippet.maze.co/maze-universal-loader.js', 'd144d96c-62c4-489d-9f2a-abf760abdbf2');
`

export const setupMazeServices = () => {
  if (!import.meta.env.VITE_MAZE_PASSWORD) return
  const param = getUrlParam('maze')
  if (param !== import.meta.env.VITE_MAZE_PASSWORD) return
  const script = document.createElement('script')
  script.innerHTML = MAZE_SCRIPT
  document.head.appendChild(script)
}
