export const parentCommand = (command: string, data: object) => {
  import.meta.env.VITE_DOMAIN_LIST?.split(' ').forEach((endpoint: string) => {
    window.parent.postMessage({ type: 'command', command, data }, endpoint)
  })
}
export const parentEvent = (event: string, data: object) => {
  import.meta.env.VITE_DOMAIN_LIST?.split(' ').forEach((endpoint: string) => {
    window.parent.postMessage({ type: 'event', event, data }, endpoint)
  })
}
