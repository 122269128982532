<template>
  <Animation
    v-if="showLoadingAnimation"
    name="ai_loading"
    class="h-[70px]"
  />
  <div
    class="custom-markdown-content [&_*]:rounded-2xl [&>:first-child]:rounded-tl-[8px] font-[Inter]"
    :class="contentStyle"
    v-html="html"
  />
</template>

<script lang="ts">
interface IMarkdownContentProps {
  content?: string
  complete?: boolean
  isCurrentMessage?: boolean
}
</script>

<script lang="ts" setup>
import { computed, onMounted, watch, ref, nextTick } from 'vue'
import DOMPurify from 'dompurify'
import { marked } from 'marked'
import { useChatStore, useUIStore } from '@/store'
import { getDelayBetweenTokens } from '@/utils/chat-store-utils'
import { runes } from 'runes2'
import Animation from '@/components/atoms/Animation.vue'

marked.setOptions({ headerIds: false, mangle: false })
const chatStore = useChatStore()
const uiStore = useUIStore()

const props = withDefaults(defineProps<IMarkdownContentProps>(), {
  content: '',
  complete: false,
  isCurrentMessage: false
})

const typeIndex = ref(0)
const showLoadingAnimation = ref(false)

const contentStyle = computed(() => {
  const style = []
  if (chatStore.typing) {
    style.push('typing')
  }
  if (!showLoadingAnimation.value) {
    style.push('markdown-content-background')
  }
  return style
})

const splitHtml = (htmlString: string) => {
  let splitTags = htmlString.split(/(<[^>]+>)/)
  let splitHtml = splitTags.flatMap((section: string) => {
    if (section.match(/(<[^>]+>)/)) return section
    else return runes(section)
  })
  return splitHtml
}

// Parse markdown and sanitize it
const characters = computed(() => {
  const sanitizedHtml = DOMPurify.sanitize(
    marked.parse(
      // Double line breaks so they are taken by the markdown parser
      (props.content ?? '')
        .replace(/^(\d+)\./gm, '<strong>$1\\.</strong>')
        .replace(/\n/g, '\n\n') || '<p></p>'
    )
  )
  return splitHtml(sanitizedHtml)
})

onMounted(() => {
  checkShowLoadingAnimation()
  nextTick(() => uiStore.setScrollToBottom(true))

  if (chatStore.typing) {
    setTimeout(() => {
      requestAnimationFrame(incrementTypeIndex)
    }, 500)
  }
})

const incrementTypeIndex = () => {
  if (characters.value) {
    const delta = characters.value.length - typeIndex.value
    if (delta > 0) {
      typeIndex.value += Math.ceil(delta / 500)
    }
    scrollToEnd()
    if (chatStore.typing) {
      setTimeout(() => {
        requestAnimationFrame(incrementTypeIndex)
      }, getDelayBetweenTokens(delta))
    }
  }
}

chatStore.typing = computed(
  () => props.isCurrentMessage && (!props.complete || characters.value.length - typeIndex.value > 0)
)

// Compute Typed Message
const html = computed(() => {
  const slice = characters.value.slice(0, Math.min(typeIndex.value, characters.value.length))
  for (let i = 1; i < 11; i++) {
    if (i > slice.length) break
    const slice_index = slice.length - i
    if (/\s/.test(slice[slice_index]) || slice[slice_index].match(/(<[^>]+>)/)) continue
    let style = 'opacity: ' + (i / 10).toString() + '; '
    style += 6 > i ? 'color: #E14300;' : ''
    slice[slice_index] = "<span style='" + style + "'>" + slice[slice_index] + '</span>'
  }
  return (
    props.isCurrentMessage && characters.value.length > typeIndex.value ? slice : characters.value
  ).join('')
})

// Scroll cards to end on each Type update
watch(typeIndex, () => scrollToEnd())

const scrollToEnd = () => {
  if (props.isCurrentMessage && props.content && uiStore.atBottom) uiStore.setScrollToBottom(true)
}

const checkShowLoadingAnimation = () => {
  showLoadingAnimation.value = props.isCurrentMessage && props.content === ''
}

watch(() => props.content, checkShowLoadingAnimation)
</script>

<style lang="scss">
@import '@/utils.scss';

%carded {
  padding: 0.75rem;
  background: var(--ai-message-bg);
  backdrop-filter: var(--blur-filter-lg);
  min-width: 200px;
}

.markdown-content-background {
  & > p {
    @extend %carded;
  }

  & > ul,
  & > ol {
    & > li {
      @extend %carded;
    }
  }
}

.custom-markdown-content {
  & > * {
    margin-top: 0.25rem;
  }

  & > ol > li > *:first-child,
  & > ul > li > *:first-child {
    display: inline !important;
  }

  & > ul,
  & > ol {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    gap: 0.25rem;
    width: 100%;
    overflow-y: visible;
    padding-left: 0;
  }

  & > ol > li {
    list-style: decimal;
    margin-left: 1.5rem;
    margin-bottom: 0.25rem;

    &::marker {
      color: var(--color-gold);
      font-size: 1rem;
    }
  }

  & > ul > li {
    list-style: disc;
    margin-left: 1.5rem;
    margin-bottom: 0.25rem;

    &::marker {
      color: var(--color-gold);
      font-size: 1.5rem;
    }
  }
}
</style>
