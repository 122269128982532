<template>
  <div class="w-full h-full relative bg-white overflow-y-auto">
    <div class="flex flex-col gap-[24px] pt-[72px] p-[24px]">
      <div class="font-[Campton] text-[#161616] text-[20px] font-semibold leading-[28px]">
        Understanding your skin analysis
      </div>
      <SectionToggle
        option1-text="Grading"
        option2-text="Science"
        :sticky="false"
        @toggle="onOptionSelected"
      />
      <div
        v-if="selectedOption === 0"
        class="flex flex-col gap-[24px]"
      >
        <div class="font-[Inter] text-[#484848] text-[14px] leading-[20px]">
          We analyze your skin for various concerns, and each concern is rated on a scale of 0 to
          10. Here's what each rating means:
        </div>
        <div class="flex flex-col gap-[24px]">
          <div class="flex flex-col gap-[12px]">
            <div class="flex flex-row justify-between items-center">
              <div class="font-[Inter] text-[#161616] text-[16px] font-semibold leading-[28px]">
                None
              </div>
              <div class="font-[Inter] text-[#484848] text-[12px] font-semibold leading-[18px]">
                (0-2)
              </div>
            </div>
            <ScoreBar :score="0.1" />
            <div class="font-[Inter] text-[#484848] text-[14px] leading-[20px]">
              Your skin appears youthful in this area.
            </div>
          </div>
          <div class="flex flex-col gap-[12px]">
            <div class="flex flex-row justify-between items-center">
              <div class="font-[Inter] text-[#161616] text-[16px] font-semibold leading-[28px]">
                Minimal
              </div>
              <div class="font-[Inter] text-[#484848] text-[12px] font-semibold leading-[18px]">
                (3-5)
              </div>
            </div>
            <ScoreBar :score="0.4" />
            <div class="font-[Inter] text-[#484848] text-[14px] leading-[20px]">
              There are some very subtle changes, but nothing to worry about.
            </div>
          </div>
          <div class="flex flex-col gap-[12px]">
            <div class="flex flex-row justify-between items-center">
              <div class="font-[Inter] text-[#161616] text-[16px] font-semibold leading-[28px]">
                Moderate
              </div>
              <div class="font-[Inter] text-[#484848] text-[12px] font-semibold leading-[18px]">
                (6-8)
              </div>
            </div>
            <ScoreBar :score="0.7" />
            <div class="font-[Inter] text-[#484848] text-[14px] leading-[20px]">
              Your skin is naturally maturing.
            </div>
          </div>
          <div class="flex flex-col gap-[12px]">
            <div class="flex flex-row justify-between items-center">
              <div class="font-[Inter] text-[#161616] text-[16px] font-semibold leading-[28px]">
                Significant
              </div>
              <div class="font-[Inter] text-[#484848] text-[12px] font-semibold leading-[18px]">
                (9-10)
              </div>
            </div>
            <ScoreBar :score="0.9" />
            <div class="font-[Inter] text-[#484848] text-[14px] leading-[20px]">
              Your skin shows more developed signs.
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-[24px]"
      >
        <div class="font-[Inter] text-[#484848] text-[14px] leading-[20px]">
          Our Skin Analysis tool is powered by Artificial Intelligence technology to analyze your
          skin's specific needs and is used to create more personalized skincare routines and
          recommendations.
        </div>
        <div class="flex flex-col gap-[24px]">
          <div class="flex flex-row gap-[16px] items-top">
            <div class="w-[24px] h-[24px] flex flex-row justify-center items-center">
              <SvgIcon name="cross" />
            </div>
            <div class="flex flex-col gap-[4px]">
              <div class="font-[Inter] text-[14px] font-semibold leading-[20px]">
                Expert Insights
              </div>
              <div class="font-[Inter] text-[14px] leading-[20px]">
                We use over 30 years of L'Oréal Paris research to understand skin of all types.
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-[16px] items-top">
            <div class="w-[24px] h-[24px] flex flex-row justify-center items-center">
              <SvgIcon name="research" />
            </div>
            <div class="flex flex-col gap-[4px]">
              <div class="font-[Inter] text-[14px] font-semibold leading-[20px]">
                Advanced Technology
              </div>
              <div class="font-[Inter] text-[14px] leading-[20px]">
                Our analysis compares your selfie to a massive database to accurately assess your
                skin.
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-[16px] items-top">
            <div class="w-[24px] h-[24px] flex flex-row justify-center items-center">
              <SvgIcon name="stethoscope" />
            </div>
            <div class="flex flex-col gap-[4px]">
              <div class="font-[Inter] text-[14px] font-semibold leading-[20px]">
                Dermatologist-Approved
              </div>
              <div class="font-[Inter] text-[14px] leading-[20px]">
                The AI behind Skin Genius is similar to those used by dermatologists. It detects 9
                signs of aging with near-expert precision.
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-[16px] items-top">
            <div class="w-[24px] h-[24px] flex flex-row justify-center items-center">
              <SvgIcon name="routine" />
            </div>
            <div class="flex flex-col gap-[4px]">
              <div class="font-[Inter] text-[14px] font-semibold leading-[20px]">
                Personalized Results
              </div>
              <div class="font-[Inter] text-[14px] leading-[20px]">
                In minutes, you'll receive a custom skincare routine based on your unique needs,
                validated by L'Oréal Paris dermatologists.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionToggle from '@/components/molecules/SectionToggle.vue'
import { ref } from 'vue'
import ScoreBar from '@/components/atoms/ScoreBar.vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

const selectedOption = ref(0)

const onOptionSelected = (index: number) => {
  selectedOption.value = index
}
</script>
