<template>
  <div class="flex flex-col">
    <div class="mb-1">
      <label
        for="skindiag"
        class="text-semibold text-right mr-4"
      >
        Trigger Skin Diag on AI message number :
      </label>
      <input
        id="skindiag"
        v-model="adminStore.skinDiagMessageNumber"
        class="w-10 border border-gray-200"
        type="number"
        min="0"
        hide-details
      />
    </div>
    <div class="mb-1">
      <label
        for="vto"
        class="text-semibold text-right mr-4"
      >
        Trigger VTO on AI message number :
      </label>
      <input
        id="vto"
        v-model="adminStore.vtoMessageNumber"
        class="w-10 border border-gray-200"
        type="number"
        min="0"
        hide-details
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAdminStore } from '@/store/adminStore'

const adminStore = useAdminStore()
</script>
