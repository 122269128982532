<template>
  <div
    ref="scrollingContainer"
    class="overflow-x-hidden overflow-y-auto scrollbar-hide"
  >
    <slot />
    <div
      class="anchor-last"
      ref="anchor"
    ></div>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { useUIStore } from '@/store'
import { onMounted, onUnmounted, onUpdated, ref, watch } from 'vue'
import { tracking } from '@/tracking/EventController'

const PIN_TO_BOTTOM_ACCEPTANCE_MARGIN = 40
const scrollingContainer = ref<HTMLDivElement | null>(null)
const emit = defineEmits(['scroll'])
const uiStore = useUIStore()
const anchor = ref<HTMLDivElement>()

const lastScrollTop = ref(0)
const scroll25 = ref(false)
const scroll50 = ref(false)
const scroll75 = ref(false)
const scroll100 = ref(false)

const onScroll = () => {
  if (!scrollingContainer.value) return

  const scrollTop = scrollingContainer.value.scrollTop
  if (scrollTop < lastScrollTop.value) {
    const scrollPercent = Math.round((scrollTop / scrollingContainer.value.scrollHeight) * 100)
    if (scrollTop === 0) {
      scroll100.value = true
      tracking.scrollDepthEvent('100')
    } else if (!scroll50.value && scrollPercent === 50) {
      scroll50.value = true
      tracking.scrollDepthEvent('50')
    } else if (!scroll75.value && scrollPercent === 25) {
      scroll75.value = true
      tracking.scrollDepthEvent('75')
    } else if (!scroll25.value && scrollPercent === 75) {
      scroll25.value = true
      tracking.scrollDepthEvent('25')
    }
  } else {
    scroll25.value = false
    scroll50.value = false
    scroll75.value = false
    scroll100.value = false
  }
  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop // For Mobile or negative scrolling
  const isPinned = pinned()
  uiStore.setAtBottom(isPinned)
  emit('scroll', scrollTop)
}

const scrollToBottom = () => {
  if (scrollingContainer.value) {
    debouncedScrollToBottom()
    uiStore.setAtBottom(pinned())
  }
}
const scrollToBottomNow = () => {
  anchor.value?.scrollIntoView({ behavior: 'smooth' })
  uiStore.setAtBottom(pinned())
}
const debouncedScrollToBottom = _.throttle(scrollToBottomNow, 750)

watch(
  () => uiStore.scrollToBottom,
  () => {
    scrollToBottom()
    uiStore.setScrollToBottom(false)
  }
)

const pinned = () => {
  const { scrollTop, scrollHeight, offsetHeight } = scrollingContainer.value
  return scrollTop + PIN_TO_BOTTOM_ACCEPTANCE_MARGIN > scrollHeight - offsetHeight
}

onUpdated(() => {
  if (pinned()) scrollToBottom()
})
onMounted(() => {
  if (!scrollingContainer.value) return
  scrollingContainer.value.addEventListener('scroll', onScroll)
})
onUnmounted(() => {
  if (!scrollingContainer.value) return
  scrollingContainer.value.removeEventListener('scroll', onScroll)
})
</script>
