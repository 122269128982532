<template>
  <div
    ref="scrollContainer"
    class="h-full w-full overflow-x-auto cursor-grab select-none"
    :class="{ 'custom-cursor-grabbing': isDown }"
    @mousedown="onMouseDown"
  >
    <slot
      :moved="moved"
      :mouseDown="isDown"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onUnmounted } from 'vue'

const scrollContainer = ref<HTMLElement | null>(null)
const isDown = ref(false)
const startX = ref(0)
const scrollLeft = ref(0)
const moving = ref(false)
const moved = ref(false)

const onMouseDown = (e: MouseEvent) => {
  if (e.button !== 0) return
  isDown.value = true
  moved.value = false
  moving.value = false
  startX.value = e.pageX - (scrollContainer.value?.offsetLeft || 0)
  scrollLeft.value = scrollContainer.value?.scrollLeft || 0
  window.addEventListener('mousemove', onMouseMove)
  window.addEventListener('mouseup', onMouseUp)
}

const onMouseMove = (e: MouseEvent) => {
  if (!isDown.value) return
  e.preventDefault()
  const x = e.pageX - (scrollContainer.value?.offsetLeft || 0)
  const walk = x - startX.value
  if (scrollContainer.value) {
    scrollContainer.value.scrollLeft = scrollLeft.value - walk
  }
  if (Math.abs(x - startX.value) > 5) {
    moving.value = true
  }
}

const onMouseUp = () => {
  if (moving.value) {
    moved.value = true
  }
  isDown.value = false
  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('mouseup', onMouseUp)
}

onUnmounted(() => {
  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('mouseup', onMouseUp)
})
</script>

<style scoped>
.custom-cursor-grabbing {
  cursor: grabbing;
}
</style>
