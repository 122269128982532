import type { Shade, VTOConfig, VTOModes } from '@/types/vto'
import { isMobile } from '@/utils/web'
import { HAIR_CONFIG, HAIR_MODE, MAKEUP_CONFIG, MAKEUP_MODE, SDK, hydrateConfig } from './constants'
import { logError } from '@/utils/errorUtils'

const liveModules: { [key: string]: any } = {
  [HAIR_MODE]: null,
  [MAKEUP_MODE]: null
}

const VTOStarted: { [key: string]: boolean } = {
  [HAIR_MODE]: false,
  [MAKEUP_MODE]: false
}

const liveConfigs: { [key: string]: VTOConfig } = {
  [HAIR_MODE]: HAIR_CONFIG,
  [MAKEUP_MODE]: MAKEUP_CONFIG
}

const VTODimension = {
  // window width minus border
  width: window.innerWidth - 2,
  // window height times bottom sheet's ratio minus border
  height: Math.ceil(window.innerHeight * 0.9) - 2
}

/**
 * Initialize makeup and hair SDKs.
 */
async function initSDK(): Promise<void> {
  // return if already initialized
  if (liveModules[HAIR_MODE] && liveModules[MAKEUP_MODE]) {
    return
  }

  await Promise.resolve(
    new Promise<void>((resolve, reject) => {
      // in case script loading failed
      // this should never happen unless network has failed
      const errMsg = 'SDK Library loading failed!'
      const scriptElement = document.createElement('script')
      const header = document.querySelector('head')
      if (header == null) {
        reject(new Error(errMsg))
        return
      }

      // append script
      header.appendChild(scriptElement)
      scriptElement.onload = () => {
        resolve()
      }
      scriptElement.onerror = () => {
        reject(new Error(errMsg))
      }
      scriptElement.src = SDK
    })
  ).catch((errMsg: string) => {
    throw new Error(errMsg)
  })

  // init hair module
  await initVTO(HAIR_MODE)
  // init makeup module
  await initVTO(MAKEUP_MODE)
}

/**
 * Initialize the live module according to the makeup type.
 *
 * @param makeupModule Type of the makeup module, either Makeup or Hair
 */
async function initVTO(makeupModule: string): Promise<void> {
  const k = liveConfigs[makeupModule].trackerLicenceKey
  if (!k) {
    console.warn(`license key for ${makeupModule} not populated correctly`)
  }
  const config = await hydrateConfig(liveConfigs[makeupModule])
  if (isMobile()) {
    config.libraryInfo.width = VTODimension.width
    config.libraryInfo.height = VTODimension.height
  }
  await window.MFE_VTO.init({ config }).then((module: object) => {
    liveModules[makeupModule] = module
  })
}

/**
 * We call startLiveMode to initiate the live mode using the video camera.
 */
async function startVTO(makeupType: string, shade: Shade): Promise<void | boolean> {
  try {
    // start live mode for the specified makeup type
    const makeupModule = makeupTypeToModule(makeupType)
    if (VTOStarted[makeupModule]) {
      return
    }
    await liveModules[makeupModule].startLiveMode()
    // set the corresponding look
    const canvas = await liveModules[makeupModule].setLiveLook({
      lookId: makeupModule,
      lookObject: getLook(shade)
    })

    // append canvas
    const container = document.getElementById(`${makeupModule.toLowerCase()}-canvas-container`)
    if (!container || container.childElementCount) return
    container.appendChild(canvas.renderedCanvas)
    canvas.renderedCanvas.style.height = '100%'
    canvas.renderedCanvas.setAttribute('data-testid', 'vto-canvas')

    //start live camera canvas for vto slider
    VTOStarted[makeupModule] = true
    return true
  } catch (e) {
    logError(e)
    return false
  }
}

function setMultiViewMode(type: string, mode: VTOModes) {
  const makeupModule = makeupTypeToModule(type)
  liveModules[makeupModule].setMultiViewMode({ mode: 'LIVE_MODE', multiViewMode: mode })
}

function setDualViewComparisonPercent(type: string, percentage: number) {
  const makeupModule = makeupTypeToModule(type)
  liveModules[makeupModule].setDualViewComparisonPercent({
    mode: 'LIVE_MODE',
    percentage: percentage
  })
}

async function stopVTO(makeupType?: string): Promise<void> {
  // live modules to be stopped
  const makeupModules = makeupType ? [makeupTypeToModule(makeupType)] : [MAKEUP_MODE, HAIR_MODE]

  // stop all the live modules specified
  for (const makeupModule of makeupModules) {
    const liveModule = liveModules[makeupModule]
    if (liveModule) {
      VTOStarted[makeupModule] = false
      await liveModule.stopLiveMode().catch(() => {
        // ignore module does not exist error
      })
    }
  }
}

/**
 * Apply makeup to the active live module.
 */
async function updateVTO(makeupType: string, shade: Shade): Promise<void> {
  try {
    const makeupModule = makeupTypeToModule(makeupType)
    await liveModules[makeupModule].lookChangeFromProduct({
      mode: 'LIVE_MODE',
      lookId: makeupModule,
      lookObject: getLook(shade)
    })
  } catch (e) {
    logError(e)
  }
}

/* Helpers */

/**
 * Determine whether the input makeup type is a hair product or not.
 *
 * @param makeupType - type of the makeup, e.g. hair, hair-color, makeup, etc.
 */
const isHair = (makeupType: string): boolean => makeupType.toLowerCase().includes('hair')

const isEyeliner = (shade: Shade) => shade.category === 'eyeliner'

function makeupTypeToModule(makeupType: string): string {
  return isHair(makeupType) ? HAIR_MODE : MAKEUP_MODE
}

function isSDKInitialized(): boolean {
  return [HAIR_MODE, MAKEUP_MODE].every((mode: string) => liveModules[mode])
}

function getLook(shade: Shade) {
  // Mutate the shade object to fix some missing mask issues
  if (
    isEyeliner(shade) &&
    shade.placement &&
    shade.placement.toLowerCase().includes('oap_halloween')
  ) {
    shade.placement = 'natural_top'
    shade.mask_image = null
  }
  return [
    {
      ...shade
    }
  ]
}

export const MFE = {
  initSDK,
  startVTO,
  stopVTO,
  updateVTO,
  isSDKInitialized,
  isHair,
  VTODimension,
  setDualViewComparisonPercent,
  setMultiViewMode
}
