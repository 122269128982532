import { clients } from './client'
import { ProductData, ProductReview } from '@/types/products'
import { useAuthStore } from '@/store'
import { userHasNotConsented } from '@/utils/user-utils'

export default {
  getProducts: async (upcs: string[]) => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return []
    if (!(await authStore.checkToken())) return []
    const resp = await clients.bff.get(`product/getProducts`, {
      params: { upcs }
    })
    return resp.data?.products as Record<string, ProductData>
  },
  reviews: async (upcs: string[]) => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return []
    if (!(await authStore.checkToken())) return []
    const resp = await clients.bff.get(`product/reviews`, {
      params: { upcs }
    })
    return resp.data.reviews as Record<string, ProductReview[]>
  },
  maskPrefix: async () => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore))
      return 'https://d3bu57wgd5zjfz.cloudfront.net/cmswebservice-linux/production/data/mask_images/cross/'
    if (!(await authStore.checkToken()))
      return 'https://d3bu57wgd5zjfz.cloudfront.net/cmswebservice-linux/production/data/mask_images/cross/'
    const resp = await clients.bff.get(`product/getMaskPrefix`)
    return resp.status === 200 && resp.data
      ? (resp.data as { mask_location: string; mask_location_json: string })
      : 'https://d3bu57wgd5zjfz.cloudfront.net/cmswebservice-linux/production/data/mask_images/cross/'
  }
}
