<template>
  <div
    class="top-16 w-full flex flex-row bg-white z-10 rounded-full"
    :class="[sticky ? 'sticky' : '']"
  >
    <div
      class="w-full flex flex-row gap-1 p-0.5 justify-center items-center rounded-full text-black font-semibold text-[14px] leading-[18px] font-[Inter]"
    >
      <button
        :class="leftOptionStyle"
        :data-testid="dataTestid1"
        @click="() => onOptionSelected(0)"
      >
        <SvgIcon
          v-if="option1Icon"
          class="custom-svg-icon h-[24px] w-[24px] mr-[8px]"
          :name="option1Icon"
          :color="selectedOption === 0 ? 'white' : 'black'"
        />
        {{ option1Text }}
        <span
          v-if="option1Count"
          class="px-1 font-normal"
        >
          ({{ option1Count }})
        </span>
      </button>
      <button
        :class="rightOptionStyle"
        :data-testid="dataTestid2"
        @click="() => onOptionSelected(1)"
      >
        <SvgIcon
          v-if="option2Icon"
          class="custom-svg-icon h-[24px] w-[24px] mr-[8px]"
          :name="option2Icon"
          :color="selectedOption === 1 ? 'white' : 'black'"
        />
        {{ option2Text }}
        <span
          v-if="option2Count"
          class="px-1 font-normal"
        >
          ({{ option2Count }})
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
interface ISectionToggleProps {
  option1Text: string
  option1Count?: number
  option1Icon?: string
  option2Text: string
  option2Count?: number
  option2Icon?: string
  dataTestid1?: string
  dataTestid2?: string
  sticky?: boolean
}
</script>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

withDefaults(defineProps<ISectionToggleProps>(), { sticky: true })

const selectedOption = ref(0)
const leftOptionStyle = ref<string[]>([])
const rightOptionStyle = ref<string[]>([])

const emit = defineEmits(['toggle'])

const onOptionSelected = (index: number) => {
  selectedOption.value = index
  leftOptionStyle.value = index === 0 ? ['custom-option-selected'] : ['custom-option-unselected']
  rightOptionStyle.value = index === 1 ? ['custom-option-selected'] : ['custom-option-unselected']
  emit('toggle', index)
}

onMounted(() => {
  onOptionSelected(selectedOption.value)
})
</script>

<style scoped lang="scss">
%custom-option {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 200px;
  transition: all 0.3s;
}

.custom-option-selected {
  @extend %custom-option;
  color: white;
  background-color: #2e2e2e;
}

.custom-option-unselected {
  @extend %custom-option;
  background-color: transparent;
}

.custom-svg-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
</style>
