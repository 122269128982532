import * as Sentry from '@sentry/vue'
import { DEFAULT_SENTRY_ENV } from '@/sentry'

export const logError = (error: unknown, message?: string) => {
  if (import.meta.env.VITE_SENTRY_ENV === DEFAULT_SENTRY_ENV) {
    console.error(error, message)
    return
  }
  // Sentry.captureException(error)
}

export const logMessage = (message: string, level: 'debug' | 'warning' | 'info') => {
  if (import.meta.env.VITE_SENTRY_ENV === DEFAULT_SENTRY_ENV) {
    const alerts = { debug: console.debug, warning: console.warn, info: console.info }
    alerts[level](message)
    return
  }
  // Sentry.captureMessage(message, level)
}
