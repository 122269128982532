import { createParser } from 'eventsource-parser'
import { baseUrl } from '@/utils/web'

import type { FeedbackData } from '@/types/feedback'
import type { Question } from '@/types/question'
import type { ChatHistoryResponse, ChatMessage, ChatMessageType } from '@/types/chats'
import { clients } from './client'
import { logError } from '@/utils/errorUtils'
import { useAuthStore } from '@/store'
import { BeautyState } from '@/types/diag'
import { userHasNotConsented } from '@/utils/user-utils'

export type PostMessageStreamingPayload = {
  type: ChatMessageType
  message: string | null
  sessionId: string | null
  auth: string
  imageId?: string
  onParse: (arg0: any) => void
  onEnd: (arg0: any) => void
}

export default {
  postMessage: async (
    type: ChatMessageType,
    message: string,
    sessionId: string | null,
    imageId?: string
  ): Promise<ChatMessage | null> => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return null
    if (!(await authStore.checkToken())) return null
    const resp = await clients.bff.post('/chat', {
      type,
      message,
      sessionId,
      imageId
    })
    return resp.data as ChatMessage
  },
  postMessageStreaming: async ({
    type,
    message,
    sessionId,
    imageId,
    auth,
    onParse,
    onEnd
  }: PostMessageStreamingPayload): Promise<void> => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return
    const { body } = await fetch(`${baseUrl}/api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
        Authorization: auth
      },
      body: JSON.stringify({ type, message, sessionId, imageId })
    })
    const reader: ReadableStreamDefaultReader<Uint8Array> = body!.getReader()
    const parser = createParser(onParse)

    // Stop Listening on Stream Close;
    reader.closed.then(onEnd).catch((e) => {
      logError(e)
      return onEnd(e)
    })

    let line = await reader.read()

    while (!line.done) {
      const chunk = new TextDecoder().decode(line.value)
      parser.feed(chunk)
      line = await reader.read()
    }
  },

  postFeedback: async (data: FeedbackData | null) => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return null
    if (!(await authStore.checkToken())) return null
    const resp = await clients.bff.post('/chat/feedback', data)
    return resp.data as { feedbackId: string }
  },

  getMissions: async () => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return null
    if (!(await authStore.checkToken())) return null
    const resp = await clients.bff.get('/chat/missions')
    return resp.data as Record<string, string>
  },

  getHistory: async (): Promise<ChatHistoryResponse | null> => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return null
    if (!(await authStore.checkToken())) return null
    const { data } = await clients.bff.get('/chat/history')
    return data as ChatHistoryResponse
  },

  getAllHistory: async (): Promise<ChatHistoryResponse[]> => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return []
    if (!(await authStore.checkToken())) return []
    const { data } = await clients.bff.get('/chat/all-history')
    return data as ChatHistoryResponse[]
  },

  getQuestions: async () => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return null
    if (!(await authStore.checkToken())) return null
    // In the future, we will need to get those from an api call to our database
    const resp = await clients.bff.get('/chat/questions')
    return resp.data as Question[]
  },

  deleteUser: async () => {
    const resp = await clients.bff.delete('/chat/delete-user')
    return resp.data as string
  },

  getBeautyState: async () => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return null
    if (!(await authStore.checkToken())) return null
    const resp = await clients.bff.get('/chat/beauty-state')
    return resp.data as BeautyState
  }
}
