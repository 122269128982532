<template>
  <div class="relative flex-grow h-[340px]">
    <div class="min-w-[255px] w-full h-full">
      <img
        class="h-full w-full object-cover rounded-lg"
        :src="`/${bgImage}.jpg`"
        :alt="bgImage"
      />
    </div>
    <div
      class="absolute w-full h-full left-0 top-0 bg-gradient-to-b from-[#000000dd] via-30% via-[#00000077] to-[#00000011] rounded-xl"
    />
    <div class="absolute w-full h-full left-0 top-0">
      <div class="w-full h-full flex flex-col justify-between gap-2 p-4">
        <div class="flex flex-col">
          <SvgIcon :name="icon" />
          <div class="pt-2 font-semibold text-white text-[20px] leading-[28px] welcome-card-title">
            {{ title }}
          </div>
          <div class="pt-2 font-normal text-[14px] text-white leading-[20px] font-[Inter]">
            {{ content }}
          </div>
        </div>
        <button
          class="backdrop-blur-2xl bg-[#00000055] rounded-[16px] p-3 max-w-[300px]"
          :class="{
            'custom-cursor-pointer': moved,
            'custom-cursor-grabbing': !moved
          }"
          @click="() => onQuestionClick(suggestedQuestion)"
        >
          <div class="flex flex-row justify-between items-end gap-2">
            <div
              class="w-full text-white font-normal text-[12px] font-[Inter] leading-[18px] text-left"
            >
              {{ suggestedQuestion }}
            </div>
            <div class="w-[20px]">
              <SvgIcon
                color="white"
                name="arrow-send"
              />
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
interface IWelcomeCardProps {
  bgImage: string
  icon: string
  title: string
  content: string
  suggestedQuestion: string
  moved?: boolean
}
</script>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'

withDefaults(defineProps<IWelcomeCardProps>(), {
  moved: false
})

const emit = defineEmits(['question-click'])

const onQuestionClick = (prompt: string) => {
  emit('question-click', prompt)
}
</script>

<style scoped>
.welcome-card-title {
  font-family: Campton, sans-serif;
}

.custom-cursor-pointer {
  cursor: pointer;
}

.custom-cursor-grabbing {
  cursor: grabbing;
}

button {
  cursor: default;
}
</style>
