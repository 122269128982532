<template>
  <SvgIcon
    v-if="!uiStore.atBottom"
    class="absolute h-[48px] w-[48px] bottom-[112px] right-[8px] cursor-pointer z-50"
    name="scroll-to-bottom"
    @click="uiStore.setScrollToBottom(true)"
  />
</template>
<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useUIStore } from '@/store'
const uiStore = useUIStore()
</script>
