import { ProductAPI } from '@/api'
import type { VTOConfig } from 'src/types/vto'

const SDK_LIB = 'duvjrttn4d5tj.cloudfront.net'
const SDK_VERSION = '11.0.0'
const SDK = `https://${SDK_LIB}/retailersdk-web/${SDK_VERSION}/sdk.js` as const
let dynamicMaskData: Record<string, string> | null = null

const HAIR_SDK = 'libmfehaircoloringwrapper-web'
const HAIR_SDK_VERSION = '2.18.1'
const HAIR_MODE = 'Hair'

const MAKEUP_SDK = 'libmfelivemakeup-web'
const MAKEUP_SDK_VERSION = 'lite-2.6.1'
const MAKEUP_MODE = 'Makeup'

const LIBRARY_CONFIGS = {
  domain: SDK_LIB,
  maskPrefix: '',
  shadeMatching: false,
  width: 300,
  height: 300
} as const
const VTO_CONFIGS = {
  debugMode: true,
  strictMode: true
} as const

/* Hair SDK */
const HAIR_LIB = {
  path: `/${HAIR_SDK}`,
  version: HAIR_SDK_VERSION,
  ...LIBRARY_CONFIGS
} as const

const HAIR_CONFIG: Readonly<VTOConfig> = {
  moduleMode: HAIR_MODE,
  libraryInfo: HAIR_LIB,
  ...VTO_CONFIGS,
  trackerLicenceKey: import.meta.env.VITE_HAIR_LICENSE_KEY
}

/* Makeup SDK */
const MAKEUP_LIB = {
  path: `/${MAKEUP_SDK}`,
  version: MAKEUP_SDK_VERSION,
  ...LIBRARY_CONFIGS
} as const

const MAKEUP_CONFIG: Readonly<VTOConfig> = {
  moduleMode: MAKEUP_MODE,
  libraryInfo: MAKEUP_LIB,
  ...VTO_CONFIGS,
  trackerLicenceKey: import.meta.env.VITE_MAKEUP_LICENSE_KEY
}

const hydrateConfig = async (config: VTOConfig): Promise<Readonly<VTOConfig>> => {
  dynamicMaskData = dynamicMaskData ? dynamicMaskData : await ProductAPI.maskPrefix()
  return {
    ...config,
    libraryInfo: { ...config.libraryInfo, maskPrefix: dynamicMaskData.mask_location }
  }
}

export { HAIR_CONFIG, HAIR_MODE, MAKEUP_CONFIG, MAKEUP_MODE, SDK, hydrateConfig }
