<template>
  <div class="w-[90%] grid grid-cols-2 gap-4 px-4 mt-4">
    <div
      v-for="image of processedImages"
      :key="image.url"
      class="w-full bg-white rounded-[8px]"
    >
      <img
        :src="image.url"
        :alt="image.caption"
        class="w-full h-48 object-cover rounded-t-[8px]"
      />
      <div class="flex flex-col justify-center px-2 py-0.5 h-[40px]">
        <p class="font-[Inter] font-[600] text-[12px] leading-[18px]">{{ image.caption }}</p>
        <p
          v-if="image.caption2"
          class="font-[Inter] font-[400] text-[10px] text-[#161616] leading-[16px]"
        >
          {{ image.caption2 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { TutorialImages, ProcessedImage } from '@/types/products'

interface IImageContainerProps {
  images: TutorialImages
}
</script>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import tutorialContents from '@/data/tutorialContents.json'

const props = defineProps<IImageContainerProps>()

const processedImages = ref<ProcessedImage[]>([])

const processImages = (images: TutorialImages) => {
  for (const image of images) {
    if (tutorialContents[image] !== undefined) {
      processedImages.value.push(...tutorialContents[image].images)
    }
  }
}

const clearImages = () => {
  processedImages.value.length = 0
}

onMounted(() => {
  processImages(props.images)
})

watch(
  () => props.images,
  (newImages: TutorialImages) => {
    clearImages()
    processImages(newImages)
  }
)

onUnmounted(() => clearImages)
</script>
