<template>
  <div
    ref="divRef"
    class="page-gradient absolute top-0 left-0 w-full h-full z-0"
    @click="onClick"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const divRef = ref<HTMLElement | null>(null)
const emit = defineEmits(['click', 'scroll'])

const onClick = () => {
  emit('click')
}

const onWheel = (event: WheelEvent) => {
  emit('scroll', event.deltaY)
}

onMounted(() => {
  if (divRef.value) {
    divRef.value.addEventListener('wheel', onWheel)
  }
})

onUnmounted(() => {
  if (divRef.value) {
    divRef.value.removeEventListener('wheel', onWheel)
  }
})
</script>
