<template>
  <div
    :class="[
      open
        ? drawer
          ? 'translate-x-0'
          : 'modal-shadow translate-x-0'
        : drawer
          ? 'translate-x-[100%] modal-shadow-hidden'
          : 'translate-y-[100%] modal-shadow-hidden',
      drawer ? 'rounded-none top-0 ' : 'rounded-t-2xl top-4 ',
      'w-full h-full max-w-mobile',
      'modal-transition',
      'absolute bottom-0',
      'overflow-hidden pb-4'
    ]"
    ref="modal"
  >
    <div
      v-if="visible"
      :class="[
        drawer ? 'bottom-0' : 'bottom-4',
        'absolute top-0 right-0 left-0 overflow-hidden w-full bg-black modal-transition'
      ]"
    >
      <BackButton
        v-if="backButton"
        data-testid="modal-back"
        :color="buttonColor"
        :background="buttonBackgroundColor"
        class="absolute top-5 left-5"
        @click="$emit('close')"
      />
      <CloseButton
        v-if="closeButton"
        data-testid="modal-close"
        :color="buttonColor"
        class="absolute top-5 right-5"
        @click="$emit('close')"
      />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
interface IModalProps {
  open: boolean
  closeButton?: boolean
  backButton?: boolean
  buttonColor?: 'light' | 'dark'
  buttonBackgroundColor?: string
  type?: 'drawer' | 'modal'
}
</script>

<script setup lang="ts">
import { computed, watch, onMounted, onUnmounted, ref, nextTick } from 'vue'
import { ANIMATION_END_EVENT } from '@/constants'
import CloseButton from '@/components/atoms/CloseButton.vue'
import BackButton from '@/components/atoms/BackButton.vue'

const transitioning = ref(false)
const modal = ref<HTMLDivElement | null>(null)

const closeModal = (event: Event) => {
  if (!modal.value?.contains(event.target as Node)) {
    emit('close')
  }
}

const openComplete = () => {
  modal.value?.scrollIntoView({ behavior: 'smooth' })
}

const emit = defineEmits(['close'])

const props = withDefaults(defineProps<IModalProps>(), {
  open: false,
  type: 'modal',
  backButton: false,
  buttonColor: 'light',
  closeButton: true
})

const visible = computed(() => props.open || transitioning.value)
const drawer = computed(() => props.type === 'drawer')

const onTransitionEnd = (event) => {
  transitioning.value = false
}
const onTransitionStart = (event) => {
  transitioning.value = true
}

onMounted(() => {
  window.addEventListener(ANIMATION_END_EVENT, openComplete)
  if (modal.value) {
    modal.value.addEventListener('transitionend', onTransitionEnd)
    modal.value.addEventListener('transitionstart', onTransitionStart)
  }
})

onUnmounted(() => {
  window.removeEventListener(ANIMATION_END_EVENT, openComplete)
  if (modal.value) {
    modal.value.removeEventListener('transitionend', onTransitionEnd)
    modal.value.removeEventListener('transitionstart', onTransitionStart)
  }
})

watch(
  () => open.value,
  (isOpen) => {
    if (isOpen) {
      nextTick(() => document.addEventListener('click', closeModal))
    } else {
      document.removeEventListener('click', closeModal)
    }
  }
)
</script>
<style lang="scss" scoped>
.modal-shadow-hidden {
  box-shadow: 0 0px 0px #00000000;
}

.modal-shadow {
  box-shadow: 0 0px 15px #000000a0;
}

.modal-transition {
  transition: all var(--slide-animation) var(--transition-duration-med-slow);
}
</style>
