class DSFAppComponent extends HTMLElement {
  constructor() {
    super()
    const shadow = this.attachShadow({ mode: 'open' })
    const div = document.createElement('div')
    div.setAttribute('id', 'vto-container')
    shadow.appendChild(div)
  }
}

export function setupBypassLicenceCheck() {
  if (import.meta.env.VITE_BYPASS_LICENCE_CHECK !== 'true') return
  if (customElements.get('dsf-app')) return
  customElements.define('dsf-app', DSFAppComponent)
}
