import { TrackingEvent } from '@/types/tracking'
import { clients } from '@/api/client'
import { useAuthStore } from '@/store'

const postTrackingData = async (trackingData: TrackingEvent[]) => {
  if (!(await useAuthStore().checkToken())) return []
  const resp = await clients.bff.post('/tracking', trackingData)
  return resp.data as string
}

export default { postTrackingData }
