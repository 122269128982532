<template>
  <ProductCard
    :loading="loading"
    :product="product"
    maximized
  />
</template>

<script lang="ts">
interface IProductCardModalProps {
  upc: string
  msgIndex: number
}
</script>

<script setup lang="ts">
import { type Ref, watch, ref, onMounted } from 'vue'
import type { ProductData } from '@/types/products'
import { useProductStore } from '@/store/productStore'
import ProductCard from '@/components/molecules/ProductCard.vue'
const productStore = useProductStore()

const product: Ref<ProductData> = ref({} as ProductData)
const loading = ref(false)

/* Props */
const props = withDefaults(defineProps<IProductCardModalProps>(), { upc: '', msgIndex: -1 })

/* lifecycle hooks */
onMounted(async () => {
  loading.value = true
  const products = await productStore.getProducts([props.upc])
  product.value = products[0]
  loading.value = false
})

watch(
  () => props.upc,
  async () => {
    loading.value = true
    const products = await productStore.getProducts([props.upc])
    product.value = products[0]
    loading.value = false
  }
)
</script>
