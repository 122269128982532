<template>
  <div
    data-testid="ai-message"
    class="w-full px-6 py-1"
  >
    <div class="flex flex-col items-start">
      <div
        ref="aiMessage"
        data-testid="ai-message-content"
        class="max-w-[90%] w-full text-[14px] leading-5"
      >
        <MarkdownContent
          :content="content"
          :complete="complete"
          :isCurrentMessage="isCurrentMessage"
        />
        <slot />
      </div>
      <div v-if="complete && messageId && !chatStore.typing">
        <MessageToolbar
          :selected="feedbackSelected?.overall"
          tooltip-placement="bottom-end"
          @copy="copyContent"
          @like="$emit('like')"
          @dislike="$emit('dislike')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { FeedbackOverall } from '@/types/feedback'

interface IAiMessageProps {
  content?: string
  complete?: boolean
  isCurrentMessage?: boolean
  messageId?: string
  feedbackSelected?: { overall: FeedbackOverall }
}
</script>

<script setup lang="ts">
import { copyText } from 'vue3-clipboard'
import { ref } from 'vue'
import { useChatStore } from '@/store/chatStore'
import MarkdownContent from '@/components/atoms/MarkdownContent.vue'
import MessageToolbar from '@/components/molecules/MessageToolbar.vue'

const chatStore = useChatStore()

const props = withDefaults(defineProps<IAiMessageProps>(), {
  content: '',
  complete: false,
  isCurrentMessage: false,
  messageId: '',
  feedbackSelected: undefined
})

defineEmits(['like', 'dislike'])

const aiMessage = ref<HTMLElement>()

const copyContent = () => {
  copyText(props.content ?? '', undefined, () => {})
}
</script>
