import { defineStore } from 'pinia'
import type { ProductCardData, RoutineData, VtoData } from '@/types/modal'
import { Modals } from '@/types/modal'
import { Pages } from '@/types/pages'
import { getElapsedMin, getTime } from '@/utils/time'
import { useChatStore } from './chatStore'
import _ from 'lodash'
import { tracking } from '@/tracking/EventController'
import { useAuthStore } from './authStore'
import { logError } from '@/utils/errorUtils'
import { informationPageContents } from '@/utils/onetrust'
import { userHasNotConsented } from '@/utils/user-utils'

export const useModalControllerStore = defineStore('modalController', {
  state: () => ({
    running: Modals.NONE,
    previousModal: Modals.NONE,
    vto: null as VtoData | null,
    productCard: null as ProductCardData | null,
    PDPMenu: {
      upc: String,
      msgIndex: String
    },
    informationPage: {
      id: '',
      content: ''
    },
    routine: null as RoutineData | null,
    returnTo: [Modals.NONE],
    productModalBar: false,
    termsCondsIndex: 0,
    page: Pages.CHAT,
    SelfieType: 'SKINCARE'
  }),
  getters: {
    isOpen: (state) => state.running !== Modals.NONE,
    currentModal: (state) => state.running,
    productModalBarEnabled: (state) => state.productModalBar
  },
  actions: {
    setVTO(data: VtoData) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/vto' },
        'Beauty Genius',
        '/vto?upcs=' + data['upcs']
      )
      this.returnTo.push(this.running)
      this.PDPMenu = {
        upc: data.selected,
        msgIndex: data.msgIndex
      }
      this.vto = data
      this.productModalBar = false
      this.running = Modals.VTO
      this.store()
    },
    setProduct(data: ProductCardData) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.returnTo.push(this.running)
      window.history.pushState(
        { back: window.history.state.current, current: '/PDP' },
        'Beauty Genius',
        '/PDP?upc=' + data['upc']
      )
      this.PDPMenu = {
        upc: data.upc,
        msgIndex: data.msgIndex
      }
      this.productCard = data
      this.productModalBar = true
      this.running = Modals.PRODUCT
      this.store()
    },
    setVtoFromProduct() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      if (!this.productCard) return
      const messageUPCS = useChatStore().getProductsFromMessage(this.productCard.msgIndex)
      const upcs = _.isEmpty(messageUPCS) ? [this.productCard.upc] : messageUPCS
      window.history.pushState(
        { back: window.history.state.current, current: '/vto' },
        'Beauty Genius',
        '/vto?upcs=' + upcs
      )
      this.setVTO({ upcs, msgIndex: this.productCard.msgIndex, selected: this.productCard.upc })
    },
    setProductModalbar(state: boolean) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.productModalBar = state
    },
    setPreSelfie(selfieType: string) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      if (!window.location.pathname.includes('skinResults')) this.returnTo.push(this.running)
      this.SelfieType = selfieType
      this.productModalBar = false
      this.running = Modals.PRE_SELFIE
    },
    setSkinInfo() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/skinInfo' },
        'Beauty Genius',
        '/skinInfo'
      )
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.SKIN_INFO
    },
    setSelfie() {
      tracking.faceScanEvent(useChatStore().currentSessionId)
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/scan' },
        'Beauty Genius',
        '/scan'
      )
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.SELFIE
    },
    setFeedback() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.productModalBar = false
      this.running = Modals.FEEDBACK
    },
    setSkinDiag() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/skinResults' },
        'Beauty Genius',
        '/skinResults'
      )
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.SKINDIAG
    },
    setRoutine(data: RoutineData) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.ROUTINE
      this.routine = data
      this.store()
    },
    setProfile() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/profile' },
        'Beauty Genius',
        '/profile'
      )
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.PROFILE
    },
    setLogin() {
      window.history.pushState(
        { back: window.history.state.current, current: '/login' },
        'Beauty Genius',
        '/login'
      )
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.LOGIN
    },
    setDeleteAccount() {
      window.history.pushState(
        { back: window.history.state.current, current: '/deleteAccount' },
        'Beauty Genius',
        '/deleteAccount'
      )
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.DELETE_ACCOUNT
    },
    setEditName() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.returnTo.push(Modals.PROFILE)
      this.productModalBar = false
      this.running = Modals.EDIT_NAME
    },
    setTermsConds() {
      window.history.pushState(
        { back: window.history.state.current, current: '/termsConds' },
        'Beauty Genius',
        '/termsConds'
      )
      if (this.running === Modals.TERMS_CONDS) return
      this.returnTo.push(this.running)
      this.productModalBar = false
      this.running = Modals.TERMS_CONDS
    },
    setInformationPage(page?: string) {
      this.productModalBar = false
      try {
        if (page) {
          const section = informationPageContents()[page]
          if (section) {
            window.history.pushState(
              { back: window.history.state.current, current: '/information' },
              'Beauty Genius',
              '/information?page=' + page
            )
            this.informationPage = section
            this.returnTo.push(this.running)
            this.running = Modals.INFORMATION_PAGE
          }
        } else {
          window.history.pushState(
            { back: window.history.state.current, current: '/information' },
            'Beauty Genius',
            '/information'
          )
          this.returnTo.push(this.running)
          this.running = Modals.INFORMATION_PAGES
        }
      } catch (error) {
        logError(error, 'Error setting information page')
      }
    },
    store() {
      window.localStorage.setItem(
        'modalHistory',
        JSON.stringify({
          modal: this.running,
          runOrder: this.returnTo,
          createdAt: getTime(),
          data: {
            [Modals.ROUTINE]: this.routine,
            [Modals.PRODUCT]: this.productCard,
            [Modals.VTO]: this.vto
          }
        })
      )
    },
    reload(reloadData: string | null) {
      if (!reloadData) return
      const data = JSON.parse(reloadData)
      if (!data || getElapsedMin(data.createdAt) > 2) return
      this.routine = data.data[Modals.ROUTINE] ?? null
      this.vto = data.data[Modals.VTO] ?? null
      this.productCard = data.data[Modals.PRODUCT] ?? null
      this.returnTo = data.runOrder ?? null
    },
    previous() {
      return this.previousModal
    },
    back() {
      const previous = this.returnTo.pop()
      this.previousModal = this.running
      if (previous === Modals.NONE || previous === null || previous === undefined)
        return this.close()
      this.productModalBar = false
      this.running = previous
      this.updateURLBasedOnModal()
    },
    updateURLBasedOnModal() {
      switch (this.running) {
        case Modals.VTO: {
          this.store()
          const upcs = this.vto?.upcs ?? []
          this.updateURL('/vto', '?upcs=' + upcs)
          break
        }
        case Modals.PRODUCT: {
          this.store()
          const upc = this.productCard?.upc ?? ''
          this.updateURL('/PDP', '?upc=' + upc)
          break
        }
        case Modals.ROUTINE: {
          this.store()
          this.updateURL('/chat')
          break
        }
        case Modals.SELFIE: {
          this.updateURL('/scan')
          break
        }
        case Modals.PROFILE: {
          this.updateURL('/profile')
          break
        }
        case Modals.LOGIN: {
          this.updateURL('/login')
          break
        }
        case Modals.TERMS_CONDS: {
          this.updateURL('/termsConds')
          break
        }
        case Modals.SKINDIAG: {
          this.updateURL('/skinResults')
          break
        }
        case Modals.INFORMATION_PAGES: {
          this.updateURL('/information')
          break
        }
        default: {
          break
        }
      }
    },
    updateURL(path: string, upcs?: string) {
      window.history.pushState(
        { back: window.history.state?.current ?? '/chat', current: path },
        'Beauty Genius',
        path + (upcs ?? '')
      )
    },
    close() {
      this.productModalBar = false
      this.returnTo = [Modals.NONE]
      this.running = Modals.NONE
      if (this.page === Pages.AUTH) {
        window.history.pushState({ back: '', current: '/auth' }, 'Beauty Genius', '/auth')
      } else {
        window.history.pushState({ back: '', current: '/chat' }, 'Beauty Genius', '/chat')
      }
      this.vto = null
      window.localStorage.removeItem('modalHistory')
    }
  }
})
