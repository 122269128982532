/**
 * 🇨🇦🇨🇦🇨🇦🇨🇦🇨🇦
 */
import { defineStore } from 'pinia'
import type { Flag } from '@/types/flag'
import { FlagAPI } from '@/api'

export const useFlagStore = defineStore('flag', {
  state: () => ({
    flags: [] as Flag[]
  }),
  getters: {
    getRawFlag:
      (state) =>
      (flag: string): Flag | undefined => {
        return state.flags?.find((f) => f.flag === flag)
      },
    getFlag:
      (state) =>
      (flag: string): boolean | undefined => {
        return state.flags?.find((f) => f.flag === flag)?.value
      }
  },
  actions: {
    async fetchUserFlags() {
      this.flags = await FlagAPI.getAllFlags()
    },
    setFlag(flag: string, value: boolean) {
      const currentFlag = this.getRawFlag(flag)
      if (!currentFlag) {
        this.flags.push({ flag, value })
      } else {
        currentFlag.value = value
      }
    }
  }
})
