<template>
  <div class="h-full w-full page-gradient flex flex-col items-center">
    <HomeBackground @click="onBackgroundClicked" />
    <div
      class="h-full w-full custom-main-content flex flex-col items-center"
      :class="overlayOpen"
    >
      <ChatPageHeader :chat-scroll="chatScroll" />
      <ChatPanel
        v-if="chatStore.ready"
        ref="chatPanel"
        :chat-input="chatInput"
        @scroll="onChatScrolled"
      />
    </div>
    <ChatInput
      v-if="chatStore.ready"
      ref="chatInput"
      :visible="chatInputVisible"
      @focus="onChatInputFocus"
      :active="panelActive"
      @blur="onChatInputBlur"
    />
    <LoadingOverlay :loading="!chatStore.ready" />
    <ModalController v-if="chatStore.ready" />
    <ProductModalBar @toggle="onProductModalBarToggled" />
    <Dialog>
      <ConfirmDelete />
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import HomeBackground from '@/components/molecules/HomeBackground.vue'
import ChatPageHeader from '@/pages/ChatPageHeader.vue'
import ChatPanel from '@/pages/ChatPanel.vue'
import ChatInput from '@/components/chat/ChatInput.vue'
import ModalController from '@/pages/ModalController.vue'
import LoadingOverlay from '@/components/molecules/LoadingOverlay.vue'
import { useChatStore, useModalControllerStore } from '@/store'
import Dialog from '@/components/molecules/Dialog.vue'
import ConfirmDelete from '@/components/molecules/ConfirmDelete.vue'
import { Modals } from '@/types/modal'
import ProductModalBar from '@/components/molecules/ProductModalBar.vue'

const chatStore = useChatStore()
const modalStore = useModalControllerStore()

const chatPanel = ref<HTMLElement | null>(null)
const chatInput = ref<typeof ChatInput | null>(null)
const chatScroll = ref<number>(0)
const chatInputVisible = ref(true)

const onProductModalBarToggled = (value: boolean) => (chatInputVisible.value = !value)
const onChatScrolled = (scrollTop: number) => {
  chatScroll.value = scrollTop
}

const onChatInputFocus = () => {}
const onChatInputBlur = () => {}
const onBackgroundClicked = () => {
  modalStore.close()
}

const DRAWERS = [Modals.PROFILE, Modals.PRODUCT, Modals.ROUTINE]

const overlayOpen = computed(() => {
  if (DRAWERS.includes(modalStore.running)) return ['profile-opened']
  if (modalStore.running !== Modals.NONE) return ['modal-opened']
  return []
})

// For now, we can't show the panel on top of product cards and routine cards because conversation context isn't supported
// when it is, we can uncomment this and handle it for real
// const ACTIVE_DRAWERS = [Modals.PRODUCT, Modals.ROUTINE];
// const panelActive = computed(() => ACTIVE_DRAWERS.includes(modalStore.running))
const panelActive = computed(() => false)
</script>

<style lang="scss" scoped>
.custom-main-content {
  transition: all var(--slide-animation) var(--transition-duration-med-slow);
  will-change: opacity, transform;
}

@media screen and (max-width: 768px) {
  .modal-opened {
    transform: translateY(-10%);
    overflow: hidden;
  }

  .profile-opened {
    transform: perspective(100px) translate3d(-50%, 0, 0);
    overflow: hidden;
  }
}
</style>
