<template>
  <div
    v-if="shouldShowSnackbar"
    class="w-full p-4 bg-white flex flex-row items-center z-10"
  >
    <div class="w-full px-2 flex flex-row justify-between">
      <div
        v-if="authStore.isAnonymous"
        class="flex flex-row text-sm"
      >
        <a
          class="font-bold underline"
          href="/login"
        >
          Sign up/Login
        </a>
        <span class="pl-1">for a more personalized experience.</span>
      </div>
      <div
        v-else
        class="flex flex-row text-sm"
      >
        <button @click="openFeedbackWindow">Help us improve Beauty Genius!</button>
      </div>
      <div class="flex flex-row justify-center items-center">
        <div @click="onCloseSnackbar">
          <SvgIcon name="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useChatStore, useModalControllerStore, useAuthStore } from '@/store'

const authStore = useAuthStore()
const snackbarVisible = ref(false)
const chatStore = useChatStore()
const modalControllerStore = useModalControllerStore()

const shouldShowSnackbar = computed(() => snackbarVisible.value && !authStore.loading)

const openFeedbackWindow = async () => {
  chatStore.setFeedback({ overall: 'general' })
  modalControllerStore.setFeedback()
}

const onCloseSnackbar = () => {
  snackbarVisible.value = false
}

onMounted(() => {
  snackbarVisible.value = true
})
</script>
