<template>
  <div class="w-full h-full relative bg-[#FAFAFA]">
    <div class="w-full h-full flex flex-col p-[16px]">
      <div
        class="flex flex-col items-center justify-center pt-16 text-[#161616] text-[28px] font-[Campton] font-[600] leading-[36px]"
      >
        Edit
      </div>
      <form
        @submit="onFormSubmit"
        class="w-full h-full flex flex-col justify-between pt-12"
      >
        <div class="flex flex-col gap-[12px]">
          <div class="h-[64px] flex flex-col border border-[#BDBDBD] rounded-[8px] p-2">
            <label
              for="firstName"
              class="text-[#808080] text-[10px] font-[Inter] font-[500] leading-[16px]"
            >
              First Name
            </label>
            <input
              required
              id="firstName"
              type="text"
              class="w-full h-[48px] text-[#161616] text-[14px] font-[Inter] font-[500] leading-[24px] rounded-[8px] border-none bg-[#FAFAFA] p-0"
              v-model="firstName"
            />
          </div>
          <div class="h-[64px] flex flex-col border border-[#BDBDBD] rounded-[8px] p-2">
            <label
              for="lastName"
              class="text-[#808080] text-[10px] font-[Inter] font-[500] leading-[16px]"
            >
              Last Name
            </label>
            <input
              required
              id="lastName"
              type="text"
              class="w-full h-[48px] text-[#161616] text-[14px] font-[Inter] font-[500] leading-[24px] rounded-[8px] border-none bg-[#FAFAFA] p-0"
              v-model="lastName"
            />
          </div>
        </div>
        <DesignedButton
          type="submit"
          :disabled="loading"
        >
          UPDATE NAME
        </DesignedButton>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore, useModalControllerStore } from '@/store'
import { onMounted, ref } from 'vue'
import DesignedButton from '@/components/designedComponents/DesignedButton.vue'
import FirebaseAuthController from '@/auth/firebase'

const authStore = useAuthStore()
const auth = new FirebaseAuthController(authStore)
const modalController = useModalControllerStore()

const loading = ref(false)
const firstName = ref('')
const lastName = ref('')

const updateDisplayName = async () => {
  const name = `${firstName.value} ${lastName.value}`
  await auth.updateDisplayName(name)
  modalController.setProfile()
  loading.value = false
}

const onFormSubmit = (event: Event) => {
  event.preventDefault()
  loading.value = true
  updateDisplayName()
}

onMounted(() => {
  if (authStore.user && authStore.user.displayName) {
    const [f, l] = authStore.user.displayName.split(' ')
    firstName.value = f
    lastName.value = l
  }
})
</script>
