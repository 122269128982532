<template>
  <div class="w-full h-full flex flex-col gap-2 text-center">
    <div class="flex flex-col gap-[32px]">
      <div class="flex flex-col gap-[16px]">
        <div class="text-left text-[#484848] font-[Inter] text-[14px] leading-[20px] font-semibold">
          How have you found your Beauty Genius experience so far?
        </div>
        <div class="flex flex-col gap-[8px]">
          <ul class="flex flex-col gap-[8px]">
            <li
              class="h-[56px] rounded-[4px] flex flex-row items-center justify-between bg-[white] border border-[#bebebe] text-[#808080] hover:bg-[#eeeeee] focus-within:border-sky-500 focus-within:ring-sky-500 focus-within:ring-1"
              v-for="(value, key) in feedback['general']"
              :key="key"
            >
              <label
                class="px-[16px] w-full h-full text-left font-[Inter] text-[14px] flex flex-row items-center justify-between leading-[20px] text-[#161616]"
                :for="key"
              >
                {{ value }}
                <DesignedRadioButton
                  class="mt-[6px] mb-2"
                  name="feedback"
                  :value="key"
                  v-model="selectedOption"
                />
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col gap-[16px]">
        <div class="text-left text-[#484848] font-[Inter] text-[14px] leading-[20px] font-semibold">
          How likely are you to use Beauty Genius again in the future?
        </div>
        <div class="flex flex-col gap-[8px] w-[311px]">
          <div class="flex flex-row gap-2">
            <button
              class="flex h-[56px] w-[56px] justify-center items-center text-[black] bg-[white] font-[Inter] text-[14px] leading-[20px] border border-[#bebebe] rounded-[4px] hover:bg-[#eeeeee]"
              v-for="index in 5"
              :key="index"
              :class="score === index ? ['custom-selected'] : []"
              @click="selectScore(index)"
            >
              {{ index }}
            </button>
          </div>
          <div
            class="flex flex-row justify-between font-[Inter] text-[14px] leading-[20px] font-[400] text-[#808080]"
          >
            <div>Not likely</div>
            <div>Very likely</div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-[16px]">
        <div class="text-left text-[#484848] font-[Inter] text-[14px] leading-[20px] font-semibold">
          Tell us more (optional)
        </div>
        <textarea
          v-model="additionalInfo"
          placeholder="Additional comments"
          class="font-[Inter] rounded-[4px] bg-[white] border border-[#bebebe] text-[14px] leading-[20px] text-[#161616]"
          rows="1"
        />
      </div>
    </div>
    <button
      class="mt-[20px] rounded-[4px] font-[Campton] text-[14px] leading-[18px] px-[16px] h-[48px] font-semibold"
      :class="submitEnabled ? 'custome-enable-submit' : 'custome-disable-submit'"
      :disabled="!submitEnabled"
      @click="emitComment"
    >
      SUBMIT
    </button>
  </div>
</template>

<script setup lang="ts">
import feedback from '@/components/feedback/feedback.json'
import { computed, ref } from 'vue'
import DesignedRadioButton from '@/components/designedComponents/DesignedRadioButton.vue'
const score = ref(0)
const selectedOption = ref('')
const additionalInfo = ref('')

const selectScore = (newScore: number) => {
  score.value = newScore
}
const submitEnabled = computed(() => {
  if (score.value === 0 || selectedOption.value === '') {
    return false
  }
  return true
})
const emitComment = () => {
  const comment = {
    comment: selectedOption.value,
    additional: additionalInfo.value,
    score: score.value
  }
  emit('send', comment)
}

const emit = defineEmits(['send'])
</script>

<style scoped>
.custom-selected {
  background-color: black;
  color: white;
}
.custome-enable-submit {
  background-color: black;
  color: white;
}
.custome-disable-submit {
  color: #484848;
  background-color: #bebebe;
}
</style>
