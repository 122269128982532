<template>
  <div class="max-w-[90%] px-6">
    <button
      v-for="(response, index) in suggestedResponses"
      :key="index"
      color="transparent"
      class="rounded-2xl mx-1 mt-1 p-3 border border-[#bebebe]"
      @click="selectResponse(response)"
    >
      <div class="flex flex-row justify-between items-end">
        <div class="w-full text-[#161616] text-[12px] leading-[18px] text-left">
          {{ response }}
        </div>

        <div class="ml-1 w-[20px]">
          <SvgIcon
            color="black"
            name="arrow-send"
          />
        </div>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
interface ISuggestedResponsesProps {
  suggestedResponses: string[]
}
</script>

<script setup lang="ts">
import { tracking } from '@/tracking/EventController'
import { useChatStore } from '@/store'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

const chatStore = useChatStore()

defineProps<ISuggestedResponsesProps>()

const emit = defineEmits(['select'])

const selectResponse = (response: string) => {
  tracking.selectFollowupMessageEvent(chatStore.currentSessionId)
  // Emit an event with the selected response
  emit('select', response)
}
</script>

<style scoped>
.suggestions-container {
  display: flex;
  overflow-x: auto;
  padding: 8px;
  gap: 8px;
}
</style>
