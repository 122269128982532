import { defineStore } from 'pinia'
import type { DiagResult, ScoreSignPare, FilteredSkinDiag } from '@/types/diag'
import { VisionAPI } from '@/api'
import { TrackProperties } from '@/types/tracking'
import _ from 'lodash'

interface VisionStoreState {
  diagResult: DiagResult | null
  overallDiag: string
  overallScore: number
  skinScores: ScoreSignPare[]
  skinDiagDetails: FilteredSkinDiag | null
}

export const useVisionStore = defineStore('diags', {
  state: () =>
    ({
      diagResult: null,
      overallDiag: '',
      overallScore: 0,
      skinScores: [],
      skinDiagDetails: null
    }) as VisionStoreState,
  getters: {
    diagComplete: (state) => state.diagResult?.faceShape && state.diagResult?.skinDiag
  },
  actions: {
    setDiag(result: DiagResult | null) {
      this.diagResult = result
      this.updateOverallScore()
    },
    async fetchDiagResults() {
      const diagResult = await VisionAPI.getDiagResults().catch(() => {
        console.error('No diag results available')
        return null
      })
      this.setDiag(diagResult)
    },
    updateOverallScore() {
      const skinData = this.diagResult?.skinDiag
      if (!skinData) return
      this.skinScores.length = 0

      this.skinDiagDetails = _.omit(skinData, [
        'id',
        'gender',
        'message',
        'imageConcerns',
        'globalWrinkles'
      ]) as FilteredSkinDiag
      let sum = 0

      for (const diag in this.skinDiagDetails) {
        const score = this.skinDiagDetails[diag].score ?? 0
        sum = score + sum
        this.skinScores.push({ sign: diag, score: score })
      }
      this.skinScores = _.orderBy(this.skinScores, ['score'], ['desc'])

      this.overallScore = sum / Object.keys(this.skinDiagDetails).length

      if (this.overallScore <= 0.3) this.overallDiag = 'Your skin is virtually flawless'
      else if (this.overallScore <= 0.7)
        this.overallDiag = 'Your skin would benefit from some targeted treatments'
      else this.overallDiag = 'Your skin has several areas that could benefit from specialized care'
    },
    enrichTracking() {
      const faceData = this.diagResult?.faceShape
      const skinData = this.diagResult?.skinDiag
      if (!faceData || !skinData) return

      return {
        faceMessage: faceData?.message,
        faceId: faceData?.id,
        darkCircles: faceData?.darkCircles,
        eyeBrowColor: faceData?.eyeBrowColor,
        eyeBrowShape: faceData?.eyeBrowShape,
        eyeBrowsThickness: faceData?.eyeBrowsThickness,
        eyeColor: faceData?.eyeColor,
        eyeCrease: faceData?.eyeCrease,
        eyeLashesLength: faceData?.eyeLashesLength,
        eyeShape: faceData?.eyeShape,
        cupidVisibility: faceData?.cupidVisibility,
        faceShape: faceData?.faceShape,
        foreheadSizes: faceData?.foreheadSizes,
        lipsDescription: faceData?.lipsDescription,
        mouthWidth: faceData?.mouthWidth,
        noseDescription: faceData?.noseDescription,
        hairColor: faceData?.hairColor,
        hairLength: faceData?.hairLength,
        hairState: faceData?.hairState,
        skinTone: faceData?.skinTone,
        skinUnderTone: faceData?.skinUnderTone,

        //skin diag
        skinMessage: skinData?.message,
        blotchinessRange: skinData?.blotchiness.range,
        blotchinessScore: skinData?.blotchiness.score,
        darkPigmentationRange: skinData?.darkPigmentation.range,
        darkPigmentationScore: skinData?.darkPigmentation.score,
        deepWrinklesRange: skinData?.deepWrinkles.range,
        deepWrinklesScore: skinData?.deepWrinkles.score,
        finelinesRange: skinData?.finelines.range,
        finelinesScore: skinData?.finelines.score,
        globalWrinklesRange: skinData?.globalWrinkles.range,
        globalWrinklesScore: skinData?.globalWrinkles.score,
        lackOfFirmnessRange: skinData?.lackOfFirmness.range,
        lackOfFirmnessScore: skinData?.lackOfFirmness.score,
        lackOfRadianceRange: skinData?.lackOfRadiance.range,
        lackOfRadianceScore: skinData?.lackOfRadiance.score,
        largePoresRange: skinData?.largePores.range,
        largePoresScore: skinData?.largePores.score,
        wrinklesRange: skinData?.wrinkles.range,
        wrinklesScore: skinData?.wrinkles.score,
        imageConcernsGoodLighting: skinData?.imageConcerns.goodLighting,
        imageConcernsFacingForward: skinData?.imageConcerns.facingForward,
        imageConcernsLightingQuality: skinData?.imageConcerns.lightingQuality,
        skinId: skinData?.id,
        gender: skinData?.gender
      } as TrackProperties
    }
  }
})
