<template>
  <div
    v-if="timerRunning || loading"
    class="flex flex-col absolute top-0 left-0 w-full h-full z-50 justify-center items-center"
  >
    <div class="absolute w-full h-full page-texture" />
    <Animation
      data-testid="loading-screen"
      class="relative"
      name="loading"
    />
  </div>
</template>

<script lang="ts">
import { onMounted, ref } from 'vue'
import Animation from '../atoms/Animation.vue'
interface ILoadingOverlayProps {
  loading: boolean
}
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<ILoadingOverlayProps>(), {
  loading: true
})
const timerRunning = ref(true)
onMounted(() => {
  setTimeout(() => {
    !props.loading
      ? (timerRunning.value = false)
      : setTimeout(() => (timerRunning.value = false), 4000)
  }, 6000)
})
</script>
