<template>
  <button
    :disabled="disabled"
    :class="[
      'flex flex-shrink-0 flex-nowrap p-2 pt-3 px-6 text-ellipsis overflow-hidden justify-center items-center',
      buttonClasses
    ]"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{ color?: 'transparent' | 'light' | 'dark' | 'white'; disabled?: boolean }>(),
  { color: 'light' }
)
const buttonClasses = computed(() => {
  if (props.color === 'transparent') {
    return 'bg-transparent hover:opacity-50 active:opacity-50 text-white'
  }
  if (props.color === 'light') {
    return 'bg-white text-black hover:bg-gray-100 active:bg-gray-300 disabled:bg-gray-100'
  }
  return 'bg-black hover:opacity-50 active:opacity-50 disabled:opacity-20 text-white'
})
</script>

<style>
.bg-gradient-dark {
  background: linear-gradient(119deg, #1b1b1b 0%, rgba(27, 27, 27, 0) 100%);
}
</style>
