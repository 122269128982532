<template>
  <div class="flex flex-col gap-5 items-center p-9 w-full">
    <div class="flex h-[96px] w-[96px] rounded-full bg-[#FFE9E6] justify-center items-center">
      <SvgIcon name="warningCircle" />
    </div>
    <p class="text-2xl text-black">Submission Error</p>
    <p class="text-black text-center">
      We're sorry. There was an error submitting your feedback. Our team is committed to providing
      an exceptional experience. To see what feedback others have shared click the link below.
    </p>
    <Button color="dark">
      <a
        target="_blank"
        :href="link"
      >
        Join Chat on Teams
      </a>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import DOMPurify from 'dompurify'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import Button from '@/components/atoms/Button.vue'

const teamUrl = ref<string>(import.meta.env.VITE_TEAMS_FEEDBACK_URL)
const link = computed(() => DOMPurify.sanitize(teamUrl.value))
</script>
