import { ChatAPI } from '@/api'
import { router } from '@/router/routes'
import { useAuthStore, useModalControllerStore } from '@/store'

export async function deleteUser() {
  await ChatAPI.deleteUser()
  await router.push({ name: 'chat' })
  router.go(0)
}

export const userHasNotConsented = (
  authStore: ReturnType<typeof useAuthStore>,
  pushModal = false
) => {
  if (authStore.isConsented || authStore.isAnonymous) return false
  if (pushModal) {
    const modalStore = useModalControllerStore()
    modalStore.setTermsConds()
  }
  return true
}
