<template>
  <div
    class="w-full max-w-mobile fixed absolute bottom-0"
    :class="[active ? 'z-40' : 'z-10']"
    ref="footer"
  >
    <div
      v-if="visible"
      class="flex flex-row pb-2 px-4 pt-3 justify-center items-end relative"
      :class="[active ? 'custom-bg-white' : 'custom-bg']"
      ref="inputFooter"
    >
      <div class="w-full h-full relative">
        <ChatInputMagic :focus="inputFocus" />
        <div class="w-full relative p-[2px] rounded-[30px] transition-all">
          <div
            class="custom-chat-input rounded-[30px] bg-custom-tw-chat-input-bg w-full flex flex-col gap-[8px] items-center px-[20px] cursor-text min-h-[52px] py-[12px]"
            @click="focusTextArea"
          >
            <textarea
              ref="input"
              data-testid="chat-input"
              :autofocus="autofocus"
              class="w-full h-full outline-none p-0 resize-none bg-transparent text-[16px] leading-[28px] font-medium placeholder-custom-tw-chat-input-placeholder text-black caret-[#BA0031] font-[Inter] border-none focus:ring-0"
              :placeholder="PLACEHOLDER_TEXT"
              rows="1"
              v-model="inputValue"
              :maxlength="maxLength"
              @blur="onInputBlur"
              @focus="onInputFocus"
              @keypress.enter="onEnterPress"
              @input="onInput"
            />
            <div
              v-if="isSendButtonVisible"
              class="text-xs w-full text-left"
            >
              {{ chatLength }}
            </div>
          </div>
        </div>
      </div>
      <SendButton
        data-testid="chat-input-send"
        :visible="isSendButtonVisible"
        @click="() => submit()"
      />
    </div>
    <SnackBar v-if="disableLogin !== 'true'" />
    <ScrollToBottomButton v-if="!active" />
  </div>
</template>

<script lang="ts">
interface IChatInputProps {
  active: boolean
  visible: boolean
  autofocus?: boolean
}
</script>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useChatStore } from '@/store/chatStore'
import { chatQuery } from '@/types/chats'
import { useAuthStore } from '@/store/authStore'
import { useRouter } from 'vue-router'
import SendButton from '@/components/chat/SendButton.vue'
import { useUIStore, useModalControllerStore } from '@/store'
import { Modals } from '@/types/modal'
import SnackBar from '@/components/molecules/SnackBar.vue'
import ScrollToBottomButton from '@/components/chat/ScrollToBottomButton.vue'
import ChatInputMagic from '@/components/molecules/ChatInputMagic.vue'

const authStore = useAuthStore()
const chatStore = useChatStore()
const modalStore = useModalControllerStore()
const router = useRouter()
const uiStore = useUIStore()
const disableLogin = import.meta.env.VITE_DISABLE_LOGIN ?? 'false'

const props = withDefaults(defineProps<IChatInputProps>(), {
  active: false,
  visible: true,
  autofocus: false
})

const PLACEHOLDER_TEXT = 'Ask a question or reply...'
const maxLength = import.meta.env.VITE_MAX_MESSAGE_LENGTH ?? 200
const input = ref<HTMLTextAreaElement>()
const inputFooter = ref<HTMLTextAreaElement>()
const inputFocus = ref(false)
const query = computed(() => router.currentRoute.value.query as chatQuery)

// bind the prop with input value
// reference: https://vuejs.org/guide/components/v-model.html
const inputValue = computed({
  get: () => chatStore.inputMessage,
  set: (prompt) => chatStore.setInputMessage(prompt)
})
// Define state of input box declaratively
const isSendButtonVisible = computed(() => inputValue.value.length > 0)

const chatLength = computed(() => `${inputValue.value.length}/${maxLength}`)

const emit = defineEmits(['focus', 'blur'])

/* Methods */

const focusTextArea = () => {}

const onEnterPress = (e: KeyboardEvent) => {
  e.preventDefault()
  submit()
}

// Function to resize the text area on activity
const onInput = () => {
  if (!input.value) return
  input.value.style.height = 'auto'
  if (input.value.textLength > 0 || props.active) {
    input.value.style.height = `${input.value.scrollHeight}px`
  }
}
const onInputFocus = (/* event: Event */) => {
  inputFocus.value = true
  emit('focus')
}

const onInputBlur = (/* event: Event */) => {
  inputFocus.value = false
  emit('blur')
}

/**
 * Populate the promt in chat input box and submit.
 *
 * @param prompt - User input to be sent.
 */
const sendPrompt = (prompt: string) => {
  if (prompt) inputValue.value = prompt
  submit()
}

const submit = () => {
  if (!authStore.user) {
    router.push({ name: 'chat' })
    return
  }
  if (modalStore.running !== Modals.NONE) {
    modalStore.close()
  }
  if (!chatStore.textEntryDisabled) {
    chatStore.setSuggestedResponsesFlag(false)
    chatStore.postMessage({})
    uiStore.setScrollToBottom(true)
  }

  // There to clear the input after sending the message
  nextTick(() => onInput())
}

/* Exposed Properties */
defineExpose({ focusTextArea, sendPrompt })

// Sets active if product modal is running
watch(
  () => props.active,
  (n) => {
    n && onInput()
  }
)

// Focuses if product modal bar is enabled
watch(
  () => modalStore.productModalBarEnabled,
  () => nextTick(() => focusTextArea())
)

// Sets active if text entry is enabled
// watch(
//   () => !chatStore.textEntryDisabled,
//   (n) => n && nextTick(() => focusTextArea())
// )

// Tracking event on data submit
watch(
  () => !chatStore.textEntryDisabled,
  (n) => {
    const { prompt, send } = query.value
    if (n && prompt && send === 'true') {
      submit()
    }
    query.value.prompt = undefined
  }
)

onMounted(() => {
  const { prompt } = query.value
  if (prompt) inputValue.value = prompt
  onInput()
  addEventListener('resize', onInput)
})

onUnmounted(() => {
  removeEventListener('resize', onInput)
})
</script>

<style scoped lang="scss">
.custom-bg-white {
  background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 15%);
  background: rgba(250, 250, 250);
}

.custom-bg {
  background: linear-gradient(to bottom, transparentize(#f4eadb, 1) 0%, #f4eadb 15%);
  background: #f4eadb;
}

.custom-chat-input {
  textarea {
    max-height: 80px;
  }
}
</style>
