<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition">
      <ErrorPage v-if="errorStore.showErrorPage" />
      <component
        v-else
        :is="Component"
      />
    </transition>
  </router-view>
</template>

<script setup lang="ts">
import ErrorPage from '@/components/molecules/ErrorPage.vue'
import { useErrorStore } from '@/store/errorStore'
const errorStore = useErrorStore()
</script>
