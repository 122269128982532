import { capitalize } from 'lodash'

/**
 * Add space to the provided string. The current method works only for strings in CamalCase or snake_case.
 *
 * @param s - Input string to add spacing to.
 */
export const spacing = (s: string): string =>
  s
    .replaceAll('_', ' ')
    .replaceAll('-', ' ')
    .split(/(?=[A-Z])/)
    .join(' ')

/**
 * Split the string according to brackets. Notice that this function won't work for nested brackets.
 *
 * Example: splitBrackets('string 0 [string 1] string 2 [string 3]') === ['string 0', '[string 1]', 'string 2', '[string 3]']
 */
export const splitBrackets = (s: string, left: string = '[', right: string = ']'): string[] =>
  s
    .split(new RegExp(`[\\${left}]`)) // look for left bracket
    .flatMap((sub) => sub.split(new RegExp(`[\\${right}]`))) // look for right bracket

export const splitInt = (input: string, split: string): Array<number> => {
  return input.split(split).map(function (element) {
    const intElement = parseInt(element, 10)
    if (isNaN(intElement)) {
      throw new Error(`splitInt: input contains a non number ${element}`)
    }
    return intElement
  })
}

export const listToStr = (list: string[]): string => capitalize(list.join(', '))

export const stringEmpty = (str: string) => /^\s*$/.test(str)
