import './index.scss'

import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { createApp, type Component } from 'vue'
import { register } from 'swiper/element/bundle'
import VueClipboard from 'vue3-clipboard'
import Vue3Marquee from 'vue3-marquee'
import App from './App.vue'
import { router } from './router/routes'
import { initStores, useAuthStore } from './store'
import { initSentry } from './sentry'
import FirebaseAuth from './auth/firebase'
import { setupBypassLicenceCheck } from './utils/mfe/bypassLicenceCheck'
import { createGtm } from '@gtm-support/vue-gtm'
import { setupMazeServices } from '@/utils/maze'

register()

function getApp(App?: Component) {
  const app = createApp(App ?? {})

  // initSentry(app)

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedState)
  app.use(pinia)
  app.use(router)
  app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true
  })
  if (import.meta.env.VITE_GA_ID) app.use(createGtm({ id: import.meta.env.VITE_GA_ID }))
  app.use(Vue3Marquee, { name: 'V3Marquee' })
  return app
}

const initialize = async () => {
  const authStore = useAuthStore()
  authStore.loading = true
  const auth = new FirebaseAuth(authStore)
  auth.watchAuthState(() => {
    // bypass vto license
    setupBypassLicenceCheck()
    setupMazeServices()
    // initialize Pinia stores
    initStores()
  })
}

const app = getApp(App)
app.mount(`${import.meta.env.VITE_SERVICE}-app`)
initialize()
