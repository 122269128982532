<template>
  <div class="campton flex flex-col pt-4 justify-center items-center text-center text-3xl px-1">
    <p class="font-semibold text-[16px] leading-[28px] text-[#484848]">
      {{ firstLine }}
      <span
        v-if="firstName"
        class="capitalize"
      >
        {{ firstName }}
      </span>
    </p>
    <p class="mt-2 text-[#161616] text-[32px] leading-[40px] font-semibold campton">
      How can I help you today?
    </p>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store'
import { computed } from 'vue'

const authStore = useAuthStore()

const firstLine = computed(() => {
  if (!authStore.user) return ''
  if (authStore.isNewUser) return 'Hello,'
  return 'Welcome back'
})

const firstName = computed(() => (authStore.user?.displayName ?? '').split(' ')[0])
</script>

<style scoped lang="scss">
.custom-welcome-header > p {
  font-family: Campton, Inter, sans-serif;
}
</style>
