// whether the current viewport belongs to a mobile device or not
export const isMobile = () => window.innerWidth < 1024

/* Base URL of the API */
export const baseUrl: string =
  // VITE_API_URL will only be set for local environment
  import.meta.env.VITE_API_URL ? import.meta.env.VITE_API_URL : ''

const urlParamCache = new Map<string, URLSearchParams>()
export function getUrlParam(): URLSearchParams
export function getUrlParam(param: string): string | null
export function getUrlParam(param?: string) {
  const search = window.location.search
  let urlParams = urlParamCache.get(search)
  if (!urlParams) {
    urlParams = new URLSearchParams(search)
    urlParamCache.set(search, urlParams)
  }
  if (param) {
    return urlParams.get(param)
  }
  return urlParams
}
