<template>
  <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
    <div class="sm:flex sm:items-start">
      <div
        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
      >
        <ExclamationTriangleIcon
          class="h-6 w-6 text-red-600"
          aria-hidden="true"
        />
      </div>
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <DialogTitle
          as="h3"
          class="text-base font-semibold leading-6 text-gray-900"
        >
          {{ dialog.content.title }}
        </DialogTitle>
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            {{ dialog.content.message }}
          </p>
        </div>
      </div>
    </div>
    <FirebaseUI
      class="mt-8"
      :callback="deleteUser"
    />
  </div>
  <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
    <button
      type="button"
      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      @click="close"
    >
      Cancel
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { DialogTitle } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { useUIStore } from '@/store'
import FirebaseUI from '@/components/molecules/FirebaseUI.vue'
import { useAuthStore } from '@/store/authStore'
import FirebaseAuth from '@/auth/firebase'

const uiStore = useUIStore()
const dialog = computed(() => uiStore.dialog)

const deleteUser = () => {
  const authStore = useAuthStore()
  const auth = new FirebaseAuth(authStore)
  auth.delete()
}
/* Methods */
const close = () => uiStore.resetDialog()
</script>
