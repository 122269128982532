<template>
  <div
    ref="rootRef"
    :id="id"
    class="w-full h-full overflow-y-auto pt-20 px-2 relative bg-[#FAFAFA]"
  />
</template>

<script lang="ts">
import { createOneTrustCDNScript, createOneTrustLoadScript } from '@/utils/onetrust'

interface IInformationPageProps {
  id: string
  content: string
}
</script>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

const rootRef = ref<HTMLDivElement | null>(null)

const props = defineProps<IInformationPageProps>()

onMounted(() => {
  if (rootRef.value) {
    const cdnScript = createOneTrustCDNScript()
    rootRef.value.appendChild(cdnScript)
    cdnScript.onload = () => {
      if (!rootRef.value) return
      const loadScript = createOneTrustLoadScript(props.content)
      rootRef.value.appendChild(loadScript)
    }
  }
})

onUnmounted(() => {
  if (rootRef.value) {
    rootRef.value.innerHTML = ''
  }
})
</script>
