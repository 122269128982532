<template>
  <div class="mb-1">
    <label
      for="chat-history"
      class="text-semibold text-right mr-4"
    >
      Enable chat history (needs refresh):
    </label>
    <input
      id="chat-history"
      v-model="adminStore.chatHistoryEnabled"
      type="checkbox"
      hide-details
    />
    <div class="my-4 xl:my-8" />
    <Button
      class="w-1/12"
      @click="deleteUser"
    >
      Delete user data
    </Button>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/atoms/Button.vue'
import { useAdminStore } from '@/store/adminStore'
import { deleteUser } from '@/utils/user-utils'

const adminStore = useAdminStore()
</script>
