<template>
  <div class="w-full h-full flex flex-col items-stretch bg-white overflow-y-auto">
    <div
      class="sticky top-0 bg-white flex items-center justify-start gap-4 p-4 xl:p-8 text-2xl shadow-md"
    >
      <SvgIcon
        name="arrow-up"
        class="cursor-pointer"
        style="transform: rotate(-90deg)"
        @click="router.push({ name: 'chat' })"
      />
      Admin
    </div>
    <div class="w-full h-full flex flex-col items-stretch p-4 xl:p-8">
      <div class="divider my-4 xl:my-8" />
      <p class="text-xl my-4">Chat history</p>
      <ChatHistory />
      <p class="text-xl my-4">Enable Skin Diag Results</p>
      <div class="mb-1">
        <label
          for="skin-diag"
          class="text-semibold"
        >
          Enable Skin Diag Results:
        </label>
        <input
          id="skin-diag"
          v-model="adminStore.skinDiagResults"
          type="checkbox"
          hide-details
        />
      </div>
      <p class="text-xl my-4">Enable Beauty State</p>
      <div class="mb-1">
        <label
          class="text-semibold"
          for="show-beauty-state"
        >
          Enable Beauty State Display:
        </label>
        <input
          id="show-beauty-state"
          v-model="adminStore.showBeautyState"
          type="checkbox"
          hide-details
        />
      </div>
      <div class="divider my-4 xl:my-8" />
      <p class="text-xl my-4">Export feedback</p>
      <ExportFeedback />
      <div class="divider my-4 xl:my-8" />
      <p class="text-xl my-4">Batch Upload (with conversational overrides if any)</p>
      <BatchUpload />
      <div class="divider my-4 xl:my-8" />
      <p class="text-xl my-4">Demo hardcoded settings</p>
      <DemoHardcoding />
      <div class="divider my-4 xl:my-8" />
      <p class="text-xl my-4">Download face analysis and skin diag results</p>
      <DiagResult />
      <div class="pb-4 xl:pb-8" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import ExportFeedback from '@/components/admin/ExportFeedback.vue'
import DemoHardcoding from '@/components/admin/DemoHardcoding.vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import BatchUpload from '@/components/admin/BatchUpload.vue'
import DiagResult from '@/components/admin/DiagResult.vue'
import ChatHistory from '@/components/admin/ChatHistory.vue'
import { useAdminStore } from '@/store/adminStore'

const adminStore = useAdminStore()
const router = useRouter()
</script>

<style lang="scss">
.divider {
  height: 1px;
  background-color: black;
}
</style>
