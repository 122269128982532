import { clients } from './client'
import type { DiagDeleteResult, DiagResult, FaceDiagResult, SkinDiagResult } from '@/types/diag'
import { useAuthStore } from '@/store'
import { userHasNotConsented } from '@/utils/user-utils'

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

const postFaceDiagBase64 = async (imageBase64: string) => {
  const authStore = useAuthStore()
  if (userHasNotConsented(authStore)) return ''
  if (!(await authStore.checkToken())) return
  const resp = await clients.bff.post('/vision/face-shape', { image: imageBase64 })
  return resp.data as FaceDiagResult
}

const postFaceDiag = async (image: File) => {
  const authStore = useAuthStore()
  if (userHasNotConsented(authStore)) return null
  if (!(await authStore.checkToken())) return null
  const imageBase64 = await toBase64(image)
  return await postFaceDiagBase64(imageBase64 as string)
}

const postSkinDiagBase64 = async (imageBase64: string) => {
  const authStore = useAuthStore()
  if (userHasNotConsented(authStore)) return ''
  if (!(await authStore.checkToken())) return ''
  const resp = await clients.bff.post('/vision/skin-diag', { image: imageBase64 })
  return resp.data as SkinDiagResult
}

const postDiagBase64 = async (imageBase64: string) => {
  const authStore = useAuthStore()
  if (userHasNotConsented(authStore)) return ''
  if (!(await authStore.checkToken())) return ''
  const resp = await clients.bff.post('/vision', { image: imageBase64 })
  return resp.data as DiagResult
}

const getDiagResults = async () => {
  const authStore = useAuthStore()
  if (userHasNotConsented(authStore)) return null
  if (!(await authStore.checkToken())) return null
  const resp = await clients.bff.get('/vision')
  return resp.data as DiagResult
}

/**
 * Deletes the diagnosis result from the database for the current userId
 */
const deleteDiagResults = async () => {
  const resp = await clients.bff.delete('/vision')
  return resp.data as DiagDeleteResult
}

export default {
  postFaceDiagBase64,
  postFaceDiag,
  postSkinDiagBase64,
  postDiagBase64,
  getDiagResults,
  deleteDiagResults
}
