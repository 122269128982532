import type {
  ChatMessage,
  ChatMessageAI,
  ChatMessageHistoryResponse,
  ChatMessageUserImage,
  ChatMessageUserText
} from '@/types/chats'
import _ from 'lodash'

export const isUserImageMessage = (msg: ChatMessage): msg is ChatMessageUserImage =>
  msg.sender === 'user' && msg.type === 'image'

export const isUserTextMessage = (msg: ChatMessage): msg is ChatMessageUserText =>
  msg.sender === 'user' && msg.type === 'text'

export const isChatMessageTextBase = (msg: ChatMessage): msg is ChatMessageUserText =>
  msg.type === 'text'

export const isAIMessage = (msg: Partial<ChatMessage>): msg is ChatMessageAI => msg.sender === 'ai'

export const isReady = (msg: ChatMessage) => isAIMessage(msg) && msg.complete && msg.content

export const getChatMessageFromHistoryMessage = (msg: ChatMessageHistoryResponse): ChatMessage => {
  if (msg.type === 'text' && msg.sender === 'ai') {
    return _.omit(msg, 'imageId', 'sessionId', 'createdAt') as ChatMessageAI
  } else if (msg.type === 'image' && msg.sender === 'user') {
    return _.omit(msg, 'content', 'sessionId', 'createdAt') as ChatMessageUserImage
  } else {
    return _.omit(msg, 'imageId', 'sessionId', 'createdAt') as ChatMessageUserText
  }
}
