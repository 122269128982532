<template>
  <div
    class="flex flex-row justify-between items-center p-4 transition-all w-full fixed max-w-mobile z-30"
    :class="parentStyle"
  >
    <CloseIframeButton :class="navbarStyle" />
    <NavBar :class="navbarStyle" />
  </div>
</template>

<script lang="ts">
interface IChatPageHeaderProps {
  chatScroll: number
}
</script>

<script setup lang="ts">
import NavBar from '@/components/molecules/NavBar.vue'
import { computed } from 'vue'
import CloseIframeButton from '@/components/molecules/CloseIframeButton.vue'

const props = withDefaults(defineProps<IChatPageHeaderProps>(), {
  chatScroll: 0
})

const isScrolled = computed(() => props.chatScroll > 5)

const parentStyle = computed(() => {
  if (!isScrolled.value) {
    return ['bg-transparent']
  }
  return ['bg-custom-tw-page-header-bg', 'drop-shadow-xl']
})

const navbarStyle = computed(() =>
  isScrolled.value
    ? ['bg-transparent']
    : ['rounded-full bg-custom-tw-page-header-bg drop-shadow-custom-tw-page-header-shadow']
)
</script>
