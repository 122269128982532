<template>
  <div class="flex flex-row justify-center items-center p-2">
    <button @click="openProfile">
      <SvgIcon
        name="profile"
        class="w-[20px] h-[20px]"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useModalControllerStore } from '@/store'

const modalStore = useModalControllerStore()
const openProfile = () => modalStore.setProfile()
</script>
