import {
  CommonFields,
  EventTypes,
  PageEvent,
  IdentifyEvent,
  IdentifyProperties,
  TrackProperties,
  PageProperties,
  IdentifyTypes,
  TrackTypes,
  TrackEvent,
  GTMEvent,
  GTMService,
  Impressions,
  TrackingEvent
} from '@/types/tracking'
import { useAuthStore } from '@/store/authStore'
import _ from 'lodash'
import { TrackingAPI } from '@/api'
import { logError } from '@/utils/errorUtils'
import { parentEvent } from '@/utils/parentEvent'

const pushEventData = async (event: TrackingEvent) => {
  const authStore = useAuthStore()
  if (!authStore.isValidUser) return
  const user = authStore.user
  if (user) {
    event.eventData = { ...event.eventData, userId: user.uid, isAnonymous: authStore.isAnonymous }
  }
  TrackingAPI.postTrackingData([event]).catch((error) => {
    logError(error)
  })
}

function getService(): GTMService {
  return {
    service_category: 'coach',
    service_name: 'beautygenius',
    service_version: VITE_APP_VERSION
  }
}

function serviceOpeningEvent() {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'service_opening',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'service opening',
    eventLabel: 'beautygenius opening',
    service_step: 'service_start',
    ...service
  })
}

function scrollDepthEvent(scrollThreshold: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'nievent',
    event_name: 'scroll_depth',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'scroll depth',
    eventLabel: scrollThreshold,
    scroll_percentage: scrollThreshold,
    ...service
  })
}

function privacyConsentEvent() {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'privacy_consent',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'select::continue',
    eventLabel: 'privacy::continue',
    service_step: 'privacy_consent',
    cta_name: 'get started',
    ...service
  })
}

function VTOEvent(sessionID: string, productName: string, productID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'started_vto',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'launch vto',
    eventLabel: 'virtual try on',
    event_detail: sessionID,
    feature_name: 'virtual try on',
    product_info: productName + '::' + productID,
    ...service
  })
}

function faceScanEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'use_face_scan',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'use feature',
    eventLabel: 'face scan',
    event_detail: sessionID,
    feature_name: 'face scan',
    ...service
  })
}

function faceScanResultEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'face_scan_result',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'result',
    eventLabel: 'face scan result',
    event_detail: sessionID,
    feature_name: 'face scan',
    ...service
  })
}

function selectShadeEvent(sessionID: string, productName: string, productID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'select_shade',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'select shade',
    eventLabel: productName + '::' + productID,
    event_detail: sessionID,
    feature_name: 'virtual try on',
    product_info: productName + '::' + productID,
    ...service
  })
}

function useBeforeAfterEvent(sessionID: string, productName: string, productID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'use_before_after',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'use before after',
    eventLabel: productName + '::' + productID,
    event_detail: sessionID,
    feature_name: 'virtual try on',
    product_info: productName + '::' + productID,
    ...service
  })
}

function selfieSentEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'selfie_sent',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'selfie',
    eventLabel: 'selfie sent',
    event_detail: sessionID,
    feature_name: 'face scan',
    ...service
  })
}

function sendMessageEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'sent_message',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'send message',
    eventLabel: 'message',
    event_detail: sessionID,
    ...service
  })
}

function selectFollowupMessageEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'suggested_follow_up_message',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'send message',
    eventLabel: 'message',
    event_detail: sessionID,
    ...service
  })
}

function selectOpeningMessageEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'suggested_opening_message',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'send message',
    eventLabel: 'message',
    event_detail: sessionID,
    ...service
  })
}

function replyReceivedEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'recieved_message',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'recieved reply',
    eventLabel: 'reply',
    event_detail: sessionID,
    ...service
  })
}

function productListEvent(products: Impressions[]) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'nievent',
    event_name: 'view_items_list',
    ecommerce: {
      currencyCode: 'USD',
      impressions: products,
      dimension91: 'beautygenius'
    },
    eventCategory: 'Ecommerce',
    eventAction: 'Product Impressions',
    eventLabel: 'recommended products list',
    ...service
  })
}

function productRecommendationEvent(sessionID: string) {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'received_product_recommendation',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'recommendation',
    eventLabel: 'product recommendation',
    event_detail: sessionID,
    ...service
  })
}

function resetPasswordEvent() {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'reset_password',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'select::reset password',
    eventLabel: 'reset password',
    ...service
  })
}

function loginEvent() {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'login',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'select',
    eventLabel: 'login',
    ...service
  })
}

function registrationEvent() {
  const service = getService()
  sendGoogleAnalytics({
    event: 'uaevent',
    event_name: 'account_registration',
    ecommerce: 'undefined',
    eventCategory: 'coach::beautygenius::multicategory',
    eventAction: 'select',
    eventLabel: 'account signup',
    ...service
  })
}

function sendGoogleAnalytics(GTM: GTMEvent, toParent: boolean = true) {
  if (toParent) parentEvent('BeautyGenius', GTM)
  pushEventData({ eventType: 'GTM', eventData: GTM })
  dataLayer.push(GTM)
}

function createBaseEvent(): CommonFields {
  const authStore = useAuthStore()
  const commonFields: CommonFields = {
    isAnonymous: authStore.isAnonymous,
    appVersion: VITE_APP_VERSION,
    pagePath: window.location.pathname,
    pageTitle: document.title,
    pageUrl: window.location.href,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    screenDensityF: window.devicePixelRatio ?? 1,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timestamp: Date.now(),
    userId: '',
    // Version of the event schema
    version: 1
  }
  const user = authStore.user
  if (user) {
    commonFields.userId = user.uid
  }
  return commonFields
}

function decorate<T, K>(target: T, props: K) {
  return _.merge(target, props)
}

function createPageEvent(props: PageProperties) {
  const baseEvent = createBaseEvent()
  const event = {
    ...baseEvent,
    event: EventTypes.PAGE,
    type: EventTypes.PAGE
  }
  return decorate(event, props) as PageEvent
}

function page(category?: string, name?: string) {
  const pageEvent = createPageEvent({
    category,
    name
  })
  pushEventData({ eventType: 'Other', eventData: pageEvent })
}

function createIdentifyEvent(props: IdentifyProperties) {
  const baseEvent = createBaseEvent()
  const identifyEvent = {
    ...baseEvent,
    event: EventTypes.IDENTIFY
  }
  return decorate(identifyEvent, props) as IdentifyEvent
}

function identify(type: IdentifyTypes) {
  const identifyEvent = createIdentifyEvent({
    type
  })
  pushEventData({ eventType: 'Other', eventData: identifyEvent })
}

function createTrackEvent(props: TrackProperties) {
  const baseEvent = createBaseEvent()
  const trackEvent = {
    ...baseEvent,
    event: EventTypes.TRACK
  }
  return decorate(trackEvent, props) as TrackEvent
}

function track(type: TrackTypes, props: Omit<TrackProperties, 'type'> = {}) {
  const trackEvent = createTrackEvent({
    type,
    ...props
  })
  pushEventData({ eventType: 'Other', eventData: trackEvent })
}

export const tracking = {
  page,
  identify,
  track,
  serviceOpeningEvent,
  scrollDepthEvent,
  privacyConsentEvent,
  VTOEvent,
  faceScanEvent,
  faceScanResultEvent,
  selectShadeEvent,
  useBeforeAfterEvent,
  selfieSentEvent,
  sendMessageEvent,
  selectFollowupMessageEvent,
  selectOpeningMessageEvent,
  replyReceivedEvent,
  productRecommendationEvent,
  productListEvent,
  resetPasswordEvent,
  loginEvent,
  registrationEvent
}
