<template>
  <div class="py-2 flex flex-col">
    <div
      class="background rounded-lg"
      v-html="html"
    />
  </div>
</template>

<script lang="ts">
interface IDisclaimerProps {
  content: string
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import DOMPurify from 'dompurify'
const props = defineProps<IDisclaimerProps>()
const html = computed(() => DOMPurify.sanitize(props.content))
</script>

<style scoped lang="scss">
.background {
  padding: 1rem;
  background: var(--ai-message-bg);
  backdrop-filter: var(--blur-filter-lg);
  min-width: 200px;
}
</style>
