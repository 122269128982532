<template>
  <div
    v-if="!focus"
    class="h-full w-full absolute rounded-[30px]"
  >
    <div class="h-full w-full">
      <div class="custom-cursor-container">
        <div
          ref="chatInputMagic"
          class="custom-border-effect"
        >
          <div class="w-full h-[52px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
interface IChatInputMagicProps {
  focus: boolean
}
</script>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

defineProps<IChatInputMagicProps>()

const chatInputMagic = ref<HTMLDivElement | null>(null)
const loopActive = ref(false)
const frameCount = ref(0)

const loop = () => {
  if (loopActive.value) {
    frameCount.value++
    if (chatInputMagic.value) {
      chatInputMagic.value.style.setProperty('--active', '1')
      chatInputMagic.value.style.setProperty('--border-radius', '9999px')
      chatInputMagic.value.style.setProperty('--start', `${frameCount.value}`)
    }
    requestAnimationFrame(loop)
  }
}

onMounted(() => {
  loopActive.value = true
  loop()
})

onUnmounted(() => {
  loopActive.value = false
})
</script>

<style scoped lang="scss">
@property --spread {
  syntax: '<number>';
  initial-value: 0;
  inherits: true;
}

@property --blur {
  syntax: '<number>';
  initial-value: 0;
  inherits: true;
}

@property --grap {
  syntax: '<number>';
  initial-value: 0;
  inherits: true;
}

.custom-cursor-container {
  --spread: 20;
  --blur: 60;
  --grap: 100;
}

@property --active {
  syntax: '<number>';
  initial-value: 0;
  inherits: true;
}

@property --border-radius {
  syntax: '<length>';
  initial-value: 0;
  inherits: true;
}

@property --start {
  syntax: '<number>';
  initial-value: 0;
  inherits: true;
}

.custom-border-effect {
  --active: 1;
  --start: 0;
  --border-radius: 353px;
}

.custom-border-effect::before {
  border: 3px solid transparent;
  background: #fcb45f;
  background-attachment: fixed;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
      hsla(0, 0%, 100%, 0.15) 0deg,
      #fff,
      hsla(0, 0%, 100%, 0.15) calc(var(--spread) * 2.5deg)
    );
  -webkit-mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
      hsla(0, 0%, 100%, 0.15) 0deg,
      #fff,
      hsla(0, 0%, 100%, 0.15) calc(var(--spread) * 2.5deg)
    );
  mask-clip: padding-box, border-box;
  -webkit-mask-clip: padding-box, border-box;
  mask-composite: intersect;
  -webkit-mask-composite: source-in, xor;
  opacity: var(--active);
}

.custom-border-effect::after {
  --bg-size: 100;
  background: conic-gradient(from 180deg at 50% 70%, #fcb45f 0deg, #fcb45f 1turn);
  background-attachment: fixed;
  opacity: var(--active, 0);
  --alpha: 0;
  border: 3px solid transparent;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg)
    );
  -webkit-mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg)
    );
  mask-clip: padding-box, border-box;
  -webkit-mask-clip: padding-box, border-box;
  mask-composite: intersect;
  -webkit-mask-composite: source-in, xor;
}

.custom-border-effect::before,
.custom-border-effect::after {
  position: absolute;
  inset: 0;
  content: '';
  pointer-events: none;
  border-radius: var(--border-radius);
  transition: opacity 1s;
}
</style>
