<template>
  <div class="absolute z-20 px-4 py-3">
    <CloseIframeButton
      class="rounded-full bg-custom-tw-page-header-bg drop-shadow-custom-tw-page-header-shadow"
    />
  </div>
  <LoginModal />
  <ModalController />
</template>

<script setup lang="ts">
import ModalController from '@/pages/ModalController.vue'
import LoginModal from '@/pages/LoginModal.vue'
import { onMounted, onUnmounted } from 'vue'
import { useModalControllerStore } from '@/store'
import { Pages } from '@/types/pages'
import CloseIframeButton from '@/components/molecules/CloseIframeButton.vue'

const modalStore = useModalControllerStore()

onMounted(() => {
  modalStore.page = Pages.AUTH
})

onUnmounted(() => {
  modalStore.page = Pages.CHAT
})
</script>
