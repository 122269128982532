import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'
import { type Dialog, defaultDialog } from '@/types/dialog'

/**
 * All UI components that have a pattern of *Singleton* should be included in this store,
 * so that the management of those components are centralized
 * (instead of being instantiated separately in different vue components).
 */
export const useUIStore = defineStore('ui', {
  state: () => ({
    dialog: {
      open: false,
      content: defaultDialog as Dialog,
      continue: async () => {} // callback after user confirmation
    },
    scrollToBottom: false,
    atBottom: true
  }),
  actions: {
    // args is a object containing arguments that are passed into the confirm callback the object structure depends on the callback
    confirmDialog(close: boolean, args?: object) {
      if (close) this.dialog.open = false
      this.dialog.continue?.(args)
    },
    displayDialog(confirmCallback: () => Promise<void>, dialog?: Dialog) {
      this.dialog.continue = confirmCallback
      this.dialog.open = true
      if (dialog) this.dialog.content = dialog
      else this.dialog.content = cloneDeep(defaultDialog)
    },
    resetDialog() {
      this.dialog.open = false
      this.dialog.continue = async () => {}
    },
    setScrollToBottom(newVal: boolean) {
      this.scrollToBottom = newVal
    },
    setAtBottom(newVal: boolean) {
      this.atBottom = newVal
    }
  }
})
