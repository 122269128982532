<template>
  <button
    v-if="inIframe"
    class="h-full flex flex-row items-center px-4 py-3"
    @click="returnToLorealParis"
  >
    <SvgIcon
      name="close"
      color="black"
    />
    <span class="pl-3 text-xs font-bold">Return to L'Oreal Paris</span>
  </button>
  <div v-else></div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { parentCommand } from '@/utils/parentEvent'

const inIframe = ref(true)

const returnToLorealParis = () => parentCommand('returnToLorealParis', {})

onMounted(() => {
  try {
    inIframe.value = window.self !== window.top
  } catch {
    inIframe.value = true
  }
})
</script>
