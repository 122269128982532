<template>
  <div
    class="w-72 mt-0 relative flex-shrink-0 rounded-lg border border-transparent shadow-lg mb-2 transition-transform lg:hover:-translate-y-1"
  >
    <div
      class="flex flex-col rounded-lg flex-grow h-full bg-white"
      @click="onClick"
    >
      <div
        v-if="product.packshotImageUrl"
        class="flex-[1] flex-col rounded-lg flex items-center w-full justify-center bg-white h-[200px]"
      >
        <img
          class="w-[150px] h-[200px] p-6 object-contain"
          :src="product.packshotImageUrl"
          :alt="'Product packshot for ' + product.name"
          draggable="false"
        />
      </div>
      <div class="h-[20px] text-xs text-center font-semibold text-[#484848]">
        {{ brandName }}
      </div>
      <div
        class="flex-[3] flex-col w-[200px] flex-shrink-0 p-1 text-sm self-center text-center capitalize font-semibold text-[#161616]"
      >
        {{ productName }}
      </div>

      <Ratings
        v-if="!_.isNil(product.rating)"
        class="flex-[4] flex-row self-center pb-3 pt-2"
        :rating="product.rating"
        :ratingCount="product.rating_Count"
      />
      <div
        v-if="productStore.hasVTO(product.idGenAI)"
        class="flex-[5] flex relative items-center justify-center w-full pb-5"
      >
        <div
          class="absolute w-[128px] h-[40px] text-center leading-[18px] text-[14px] text-[#161616] font-semibold border-[#BEBEBE] border-[1px] rounded-[200px] flex items-center justify-center"
          :class="{
            'custom-cursor-pointer': !mouseDown || (mouseDown && disableClick)
          }"
          data-testid="chat-card-vto-button"
          @click="(e) => startVTO(e, product)"
        >
          Try It On
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { ProductData } from '@/types/products'

interface IChatProductCardProps {
  product: ProductData
  msgIndex: number
  recoId: number
  disableClick?: boolean
  mouseDown?: boolean
}
</script>

<script setup lang="ts">
import _ from 'lodash'
import { computed, onMounted } from 'vue'
import Ratings from '@/components/molecules/Ratings.vue'
import { useModalControllerStore, useProductStore, useUIStore } from '@/store'
import { getProductName } from '@/utils/product'

const modalStore = useModalControllerStore()
const uiStore = useUIStore()

const props = withDefaults(defineProps<IChatProductCardProps>(), {
  product: {
    name: 'chat',
    upc: '',
    category: '',
    brandLabel: ''
  } as ProductData,
  msgIndex: 0,
  recoId: 0,
  disableClick: false,
  mouseDown: false
})

const emit = defineEmits(['vto'])

const productStore = useProductStore()

/* Computed Properties */
const productName = computed(() => {
  const product = props.product
  if (!_.isEmpty(product.name)) return product.name.replace(/-/g, ' ')
  return getProductName(product)
})

const brandName = computed(() => props.product.franchise || props.product.brandLabel || '')
/* Methods */

// listener for click event
const onClick = (e: Event) => {
  e.preventDefault()

  if (props.disableClick) {
    return
  }

  modalStore.setProduct({
    upc: props.product.idGenAI ?? '',
    msgIndex: props.msgIndex
  })
}

const startVTO = (e: Event, product: ProductData) => {
  e.preventDefault()
  e.stopPropagation()

  if (props.disableClick) {
    return
  }

  modalStore.close()
  emit('vto', product.idGenAI)
}

onMounted(() => uiStore.setScrollToBottom(true))
</script>

<style lang="scss" scoped>
@import '@/utils.scss';

ul.product-card-ingredient-list {
  list-style-position: inside;
}

.small-cta-button {
  padding: 10px 15px 8px 15px;
}

.custom-cursor-pointer {
  cursor: pointer;
}
</style>
