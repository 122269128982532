import { AuthAction } from '@/auth/firebase'
import { Auth } from 'firebase/auth'

export enum Errors {
  Invalid = 'Invalid user or password',
  Permission = 'Permission denied',
  Unknown = 'Unknown error'
}

export interface AuthController<User, UserCredential> {
  // whether the current user is authenticated successfully or not
  authenticated(): Promise<boolean>

  // a listener for changes in auth state
  onAuth(user: User | null): void

  // auth error handler
  onErr(err: { code?: string; message?: string }): void

  // sign in the user with credentials provided
  signIn(userInfo?: UserCredential): Promise<void>

  // sign out the user
  signOut(): Promise<void>

  // delete the user
  delete(): Promise<AuthAction>

  // create the user
  create(email: string, password: string): Promise<AuthAction>

  // sign in as anonymous user
  anonymousSignIn(): Promise<void>

  // get the current Auth
  getAuth(): Auth
}
