<template>
  <div
    data-testid="feedback-modal"
    class="w-full h-full flex flex-col pt-8 bg-custom-tw-background-sign-in"
  >
    <FeedbackConfirmation v-if="confirmation" />
    <FeedbackError v-else-if="error" />
    <FeedbackForm
      v-else
      :message="chatStore.feedback?.message || ''"
      :loading="loading"
      :overall="chatStore.feedback?.overall"
      :missions="missionStore.missions"
      @skip="skipFeedback"
      @send="postFeedback"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useChatStore } from '@/store/chatStore'
import { useMissionStore } from '@/store/missionStore'
import { ChatAPI } from '@/api'
import FeedbackForm from '@/components/feedback/FeedbackForm.vue'
import FeedbackConfirmation from '@/components/feedback/FeedbackConfirmation.vue'
import FeedbackError from '@/components/feedback/FeedbackError.vue'
import type { FeedbackData } from '@/types/feedback'
import { asyncTimeout } from '@/utils/asyncTimeout'
import { logError } from '@/utils/errorUtils'

const loading = ref(false)
const confirmation = ref(false)
const error = ref(false)

const chatStore = useChatStore()
const missionStore = useMissionStore()

const emit = defineEmits(['close'])

const skipFeedback = async () => {
  if (chatStore.feedback) {
    await postFeedback({
      overall: chatStore.feedback.overall,
      messageId: chatStore.feedback.messageId,
      comment: '',
      mission: null,
      suggestedAnswer: '',
      isWrong: false,
      isHarmful: false
    })
  }
}

const postFeedback = async (data: FeedbackData) => {
  if (loading.value || !chatStore.feedback) return
  loading.value = true
  try {
    await ChatAPI.postFeedback({
      ...data,
      overall: chatStore.feedback.overall,
      messageId: chatStore.feedback.messageId
    })
    await showConfirmation()
  } catch (e) {
    logError(e)
    await showError()
  }
}

const showConfirmation = async () => {
  confirmation.value = true
  await asyncTimeout(3000)
  closeFeedback()
}
const showError = async () => {
  error.value = true
  await asyncTimeout(3000)
  closeFeedback()
}

const closeFeedback = () => {
  confirmation.value = false
  loading.value = false
  emit('close')
}
onMounted(() => {
  if (!chatStore.feedback) {
    emit('close')
  }
})
onUnmounted(() => {
  chatStore.setFeedback(null)
})
</script>
