import { type FirebaseApp, getApp, initializeApp } from 'firebase/app'
import {
  Auth,
  AuthErrorCodes as Codes,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  reauthenticateWithPopup,
  signInAnonymously,
  updateProfile,
  type User
} from 'firebase/auth'
import { useAuthStore } from '@/store'
import firebaseJson from '../firebase.json'
import { AuthController, Errors } from './auth'
import { deleteUser as deleteUserData } from '@/utils/user-utils'
import { logError } from '@/utils/errorUtils'
import { isFirebaseError } from '@/types/auth'
import { initClient } from '@/api/client'

export type UserCredential = {
  email: string
  password: string
}

export enum AuthAction {
  NO_OP,
  SIGN_OUT,
  TO_CHAT
}

export default class FirebaseAuthController implements AuthController<User, UserCredential> {
  private model: ReturnType<typeof useAuthStore>
  private auth: Auth
  private firebase?: FirebaseApp

  private errMapping: Record<string, string> = {
    [Codes.INVALID_EMAIL]: Errors.Invalid,
    [Codes.USER_DELETED]: Errors.Invalid,
    [Codes.INVALID_PASSWORD]: Errors.Invalid,
    [Codes.ADMIN_ONLY_OPERATION]: Errors.Permission
  }

  /**
   * Initialize firebase controller.
   *
   * @param authStore - Pinia storage for retrieving authenticated user object.
   */
  public constructor(model: ReturnType<typeof useAuthStore>) {
    this.firebase = initializeApp(firebaseJson, '[DEFAULT]')
    this.model = model
    this.refreshAuth()
  }

  /**
   * Attach a listener to user's auth state.
   * @param onAuthenticated - Callback to setup project using the token from Firebase.
   */
  public watchAuthState(onAuthenticated: () => any) {
    onAuthStateChanged(getAuth(this.firebase), (user: User | null) => {
      this.onAuth(user)
      if (!this.model.isValidUser) return
      const userInfo = user?.toJSON()
      initClient(userInfo?.stsTokenManager.accessToken)
      onAuthenticated()
      this.refreshAuth()
    })
  }

  public async authenticated(): Promise<boolean> {
    return await this.model.isUserAuthenticated()
  }

  public onAuth(user: User | null): void {
    this.model.updateUser(user)
  }

  public getAuth(): Auth {
    return this.auth
  }

  public onErr(err: { code?: string; message?: string }): void {
    // message is prioritized over code
    this.model.setCustomError(err.message ?? (err.code && this.errMapping[err.code]))
  }

  private async signInWithEmail(auth: Auth, email: string, password: string): Promise<void> {
    this.model.clearError()
    this.model.loading = true
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error: any) {
      this.onErr({ code: error.code })
    }
  }

  private createGoogleProvider() {
    const provider = new GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    provider.setCustomParameters({
      prompt: 'select_account'
    })
    return provider
  }

  private async signInWithGoogle(auth: Auth): Promise<void> {
    this.model.clearError()
    this.model.loading = true
    const provider = this.createGoogleProvider()
    try {
      await signInWithPopup(auth, provider)
    } catch (error: any) {
      this.onErr({ code: error.code })
    }
  }

  public async signIn(userInfo?: UserCredential): Promise<void> {
    if (this.model.user?.isAnonymous ?? false) this.model.oldUser = this.model.user
    await this.signOut()
    if (userInfo) await this.signInWithEmail(this.auth, userInfo.email, userInfo.password)
    else await this.signInWithGoogle(this.auth)
  }

  public async anonymousSignIn(): Promise<void> {
    try {
      await signInAnonymously(this.auth)
    } catch (error: any) {
      this.onErr({ code: error.code })
    }
  }

  public async signOut(): Promise<void> {
    try {
      await signOut(this.auth)
    } catch (error: any) {
      this.onErr({ code: error.code })
    }
  }

  public async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this.auth, email, {
        url: window.location.origin + window.location.pathname
      })
    } catch (error: any) {
      /* silently catch the error to not let bad intended people know if the email is used or not  */
    }
    this.model.setCustomError(
      'If this email is linked to an account, you will receive an email with a link to reset your password.'
    )
  }

  public async delete(): Promise<AuthAction> {
    if (this.model.isAnonymous) return AuthAction.NO_OP
    const user = this.auth.currentUser
    if (!user) return AuthAction.NO_OP
    if (!(await useAuthStore().checkToken())) return AuthAction.NO_OP
    try {
      await deleteUser(user)
    } catch (error) {
      return this.handleDeleteUserError(user, error)
    }
    await deleteUserData()
    return AuthAction.NO_OP
  }

  public async create(email: string, password: string): Promise<AuthAction> {
    if (this.model.user?.isAnonymous ?? false) this.model.oldUser = this.model.user
    try {
      if (import.meta.env.VITE_ALLOW_SIGNUP === 'false') return AuthAction.NO_OP
      await createUserWithEmailAndPassword(this.auth, email, password)
    } catch (error) {
      let errorMessage = 'Failed to create your user. Please try again later'
      let logMessage = 'Failed to create a new user.'

      if (isFirebaseError(error)) {
        if (error.code === Codes.WEAK_PASSWORD) {
          errorMessage = 'The specified password is too weak use a stronger password'
          logMessage = 'weak password'
        } else if (error.code === Codes.EMAIL_EXISTS) {
          errorMessage = 'A user with this email already exists. Please choose another email'
          logMessage = 'email exists'
        } else if (error.code === Codes.INVALID_EMAIL) {
          errorMessage = 'Either your email or password is invalid please try again'
          logMessage = 'email invalid'
        } else if (error.code === Codes.INVALID_PASSWORD) {
          errorMessage = 'Either your email or password is invalid please try again'
          logMessage = 'password invalid'
        } else if (error.code === Codes.QUOTA_EXCEEDED) {
          logMessage = 'quota exceeded'
        }
      }
      logError(error, logMessage)
      this.model.setCustomError(errorMessage)
      return AuthAction.NO_OP
    }
    return AuthAction.TO_CHAT
  }

  public async updateDisplayName(displayName: string) {
    if (this.auth.currentUser) {
      await updateProfile(this.auth.currentUser, { displayName })
    }
  }

  /** Re-initialize auth instance. */
  private refreshAuth() {
    this.auth = getAuth(getApp())
  }

  private async handleDeleteUserError(user: User, error: any) {
    logError(error, 'There was an unexpected error while deleting the user.')
    return AuthAction.SIGN_OUT
  }

  private async reauthenticateWithGoogle(user: User) {
    const provider = this.createGoogleProvider()
    try {
      await reauthenticateWithPopup(user, provider)
    } catch (error) {
      logError(error, 'There was an unexpected error while reauthenticating the user.')
      return false
    }
    return true
  }
}
