/**
 * Util functions for CMS/Beauty Hub/P360 products.
 */
import { isEmpty } from 'lodash'
import { ProductData } from '@/types/products'

/**
 * Fetch product name for the specified product according to the fallback priority.
 *
 * @param product - ProductData object.
 */
export const getProductName = (product: ProductData) =>
  [
    // fallbacks
    product.product_name,
    product.p360Name,
    product.name?.replace(/-/g, ' '),
    (product.category?.toLowerCase().toUpperCase() || 'Makeup') + ' Product'
  ].find((name) => !isEmpty(name))
