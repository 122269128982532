<template>
  <div class="w-full h-full relative bg-[#FAFAFA]">
    <div class="mx-[16px]">
      <div class="h-[190px] flex flex-col justify-center items-center">
        <div
          class="text-[#161616] text-[28px] font-[Campton] font-[600] leading-[36px] text-center"
        >
          Terms
          <br />
          & Conditions
        </div>
      </div>
      <div
        class="mt-[48px] flex flex-col gap-2 items-center justify-center bg-white rounded-md p-2"
      >
        <button
          class="w-full flex flex-row justify-between items-center p-2"
          @click="termsOfUse"
        >
          <div>Terms of Use</div>
          <SvgIcon
            name="button-click-arrow"
            color="#000000"
          />
        </button>
        <button
          class="w-full flex flex-row justify-between items-center p-2"
          @click="privacyNotice"
        >
          <div>Privacy Notice</div>
          <SvgIcon
            name="button-click-arrow"
            color="#000000"
          />
        </button>
        <button
          class="w-full flex flex-row justify-between items-center p-2"
          @click="informationNotice"
        >
          <div>Information Notice</div>
          <SvgIcon
            name="button-click-arrow"
            color="#000000"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useModalControllerStore } from '@/store'

const modalStore = useModalControllerStore()

const termsOfUse = () => {
  modalStore.setInformationPage('termsOfUse')
}

const privacyNotice = () => {
  modalStore.setInformationPage('privacyNotice')
}

const informationNotice = () => {
  modalStore.setInformationPage('informationNotice')
}
</script>
