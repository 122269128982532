<template>
  <div class="w-full h-full flex flex-col gap-2 text-center">
    <div class="flex flex-col gap-[32px]">
      <div class="flex flex-col gap-[16px]">
        <div class="text-left text-[#484848] font-[Inter] text-[14px] leading-[20px] font-semibold">
          Why did you choose this rating?
        </div>
        <div class="text-left text-[#808080] font-[Inter] text-[14px] leading-[20px] font-[400]">
          Select all that apply
        </div>
        <div class="flex flex-col gap-[8px]">
          <ul class="flex flex-col gap-[8px]">
            <li
              class="h-[56px] rounded-[4px] flex flex-row items-center justify-between bg-[white] border border-[#bebebe] text-[#808080] hover:bg-[#eeeeee] focus-within:border-sky-500 focus-within:ring-sky-500 focus-within:ring-1"
              v-for="(value, key) in feedback[type]"
              :key="key"
            >
              <label
                class="px-[16px] w-full h-full text-left font-[Inter] text-[14px] flex flex-row items-center justify-between leading-[20px] text-[#161616]"
                :for="key"
              >
                {{ value }}
                <DesignedCheckbox
                  class="mt-[6px] mb-2"
                  v-model="options[key]"
                  :id="key"
                />
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="showOther"
        class="flex flex-col gap-[16px]"
      >
        <div class="text-left text-[#484848] font-[Inter] text-[14px] leading-[20px] font-semibold">
          Tell us more (optional)
        </div>
        <textarea
          v-model="additionalInfo"
          placeholder="Additional comments"
          class="font-[Inter] rounded-[4px] bg-[white] border border-[#bebebe] text-[14px] leading-[20px] text-[#161616]"
          rows="1"
        />
      </div>
    </div>
    <button
      class="mt-[20px] rounded-[4px] font-[Campton] text-[14px] leading-[18px] px-[16px] h-[48px] font-semibold"
      :class="submitEnabled ? 'custome-enable-submit' : 'custome-disable-submit'"
      :disabled="!submitEnabled"
      @click="emitComment"
    >
      SUBMIT
    </button>
  </div>
</template>

<script lang="ts">
interface IFeedbackProps {
  type: string
}
</script>

<script setup lang="ts">
import feedback from '@/components/feedback/feedback.json'
import { computed, onMounted, ref } from 'vue'
import DesignedCheckbox from '@/components/designedComponents/DesignedCheckbox.vue'

const props = defineProps<IFeedbackProps>()

const options = ref<Record<(typeof feedback)[string], boolean>>({})
const additionalInfo = ref('')

const showOther = computed(() => options.value['Other'])
const submitEnabled = computed(() => Object.values(options.value).some((item) => item === true))

const emitComment = () => {
  let returnFeedback = {}
  Object.keys(feedback[props.type]).forEach((key) => {
    if (options.value[key]) {
      returnFeedback[key] = options.value[key]
    }
    if (key === 'Other' && options.value[key]) {
      returnFeedback['additional'] = additionalInfo.value
    }
  })
  emit('send', returnFeedback)
}

const emit = defineEmits(['send'])

onMounted(() => {
  Object.keys(feedback[props.type]).forEach((key) => {
    options.value[key] = false
  })
})
</script>

<style scoped>
.custome-enable-submit {
  background-color: black;
  color: white;
}
.custome-disable-submit {
  color: #484848;
  background-color: #bebebe;
}
</style>
