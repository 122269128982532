<template>
  <div class="relative w-full h-full flex flex-col overflow-y-auto overflow-x-hidden page-offwhite">
    <div
      v-if="!props.product.upc && !loading"
      class="absolute top-[50%] left-[5%] right-0 bottom-0 flex justify-center w-[90%] h-full [&_canvas]:object-cover [&_canvas]:w-full [&_canvas]:h-full text-black"
    >
      This product was not found
    </div>
    <div
      v-else-if="!props.product.upc && loading"
      class="page-offwhite flex flex-col flex-grow lg:min-h-[200px] lg:pb-6 w-full"
    >
      <Loading />
    </div>
    <div
      v-else-if="props.product.upc && !loading"
      class="page-offwhite flex flex-col pb-[25px] flex-grow lg:min-h-[200px] lg:pb-6 w-full"
    >
      <div
        v-if="product.packshotImageUrl"
        class="w-full h-[400px] flex-[1] flex-shrink-0 flex-col flex items-center justify-center bg-white pt-6 pb-8"
      >
        <img
          v-if="product.packshotImageUrl"
          class="w-[300px] h-[400px] object-contain p-2 pr-0"
          :src="product.packshotImageUrl"
          :alt="'Product packshot for ' + product.name"
        />
      </div>

      <div class="flex-[2] p-3 pb-[60px] flex flex-col justify-start gap-2 text-black pt-6">
        <h2
          v-if="product.franchise"
          class="h-[30px] flex-shrink-0 text-3xs font-semibold text-[#484848] leading-[28px]"
        >
          {{ spacing(product.franchise) }}
        </h2>

        <div>
          <p
            class="line-clamp-4 w-[90%] text-2xl text-custom-tw-PDP-heading-color py-1 capitalize font-semibold font-[Campton]"
          >
            {{ productName }}
          </p>
        </div>

        <small
          v-if="product.price"
          class="text-base font-semibold pb-2"
        >
          ${{ product.price }}
        </small>

        <Ratings
          class="pb-4"
          v-if="!_.isNil(product.rating)"
          :rating="product.rating"
          :ratingCount="product.rating_Count"
        />
        <div
          v-if="shortDescription"
          class="text-sm text-custom-tw-PDP-body-color pb-6"
          v-html="shortDescription"
        />

        <div
          class="flex flex-row flex-wrap content-center justify-center flex-shrink-0 h-[40px] text-black text-sm font-bold underline gap-2"
          @click="viewOnLorealWebsite"
        >
          <p>View on L'Oreal Paris Website</p>
          <SvgIcon
            class="h-[20px]"
            name="open-url-arrow"
          />
        </div>
        <!-- VTO IMAGE -->
        <div
          v-if="productStore.hasVTO(props.product.idGenAI)"
          class="relative h-[460px] flex-shrink-0 mb-[20px] rounded-lg"
        >
          <img
            class="absolute h-full object-cover rounded-lg"
            :src="Math.floor(Math.random() * 10) % 2 === 0 ? vtoImage1 : vtoImage2"
            alt="vto"
          />
          <div class="absolute w-full flex flex-col gap-4 h-full p-4 justify-between">
            <div>
              <SvgIcon
                class="flex-shrink-0 self-center"
                name="camera-frame"
              />
            </div>
            <div>
              <div class="flex flex-col gap-1 pb-4">
                <div class="text-white text-2xl font-bold font-[Campton]">Try it on</div>
                <div class="text-white text-lg font-[Campton]">Try this product live</div>
              </div>
              <div
                class="flex flex-wrap content-center justify-center rounded flex-shrink-0 h-[60px] bg-white text-black text-md uppercase cursor-pointer"
                @click="modalStore.setVtoFromProduct()"
              >
                <p class="font-semibold font-[Campton] text-[14px] leading-[18px]">Try It On</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col h-full gap-2 justify-between">
          <div class="flex-grow items-start flex flex-col gap-4 text-black [&_h3]:mb-2">
            <!-- INSTRUCTIONS OF USE -->
            <hr class="w-full h-[3px]" />
            <CollapsableSection v-if="instructionOfUse">
              <template #header>
                <div
                  class="w-[90%] text-[20px] leading-[28px] font-semibold text-custom-tw-PDP-heading-color font-[Campton]"
                >
                  How To Use
                </div>
              </template>
              <template #content>
                <div
                  v-html="instructionOfUse"
                  class="text-sm text-custom-tw-PDP-body-color line-clamp-6"
                ></div>
              </template>
            </CollapsableSection>
            <!-- INGREDIENTS -->
            <hr class="w-full h-[3px]" />
            <CollapsableSection v-if="product.fullIngredientsList && slicedIngredients.length > 0">
              <template #header>
                <div
                  class="w-[90%] text-[20px] leading-[28px] font-semibold text-custom-tw-PDP-heading-color font-[Campton]"
                >
                  Ingredients
                </div>
              </template>
              <template #content>
                <ul
                  class="text-sm text-custom-tw-PDP-body-color list-disc product-card-ingredient-list capitalize pt-3"
                >
                  <li
                    v-for="(ingredient, index) in slicedIngredients"
                    :key="index"
                  >
                    {{ ingredient.toLowerCase() }}
                  </li>
                </ul>
                <button
                  v-if="!ingredientsExpanded && ingredients.length > 10"
                  class="underline"
                  @click="ingredientsExpanded = true"
                >
                  Show more
                </button>
              </template>
            </CollapsableSection>
            <!-- REVIEWS -->
            <hr class="w-full h-[3px]" />
            <CollapsableSection v-if="product.reviews ?? reviews">
              <template #header>
                <div
                  class="w-[90%] text-[20px] leading-[28px] font-semibold text-custom-tw-PDP-heading-color font-[Campton]"
                >
                  Reviews
                </div>
              </template>
              <template #content>
                <Reviews
                  :reviews="product.reviews ?? reviews"
                  :rating="product.rating"
                  :rating-count="product.rating_Count"
                />
              </template>
            </CollapsableSection>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { computed, ref, watch } from 'vue'
import type { ProductData } from '@/types/products'
import Loading from '@/components/atoms/Loading.vue'
import Ratings from '@/components/molecules/Ratings.vue'
import Reviews from '@/components/molecules/Reviews.vue'
import { spacing, splitBrackets } from '@/utils/string'
import CollapsableSection from '@/components/layout/CollapsableSection.vue'
import vtoImage1 from '@/assets/images/vto-1.png?url'
import vtoImage2 from '@/assets/images/vto-2.png?url'
import SvgIcon from '../atoms/SvgIcon.vue'
import { useModalControllerStore, useProductStore } from '@/store'
import DOMPurify from 'dompurify'

const productStore = useProductStore()
const modalStore = useModalControllerStore()

/* Props and emits */
const props = withDefaults(
  defineProps<{
    loading: boolean
    product: ProductData
  }>(),
  {
    loading: false,
    product: {
      name: 'chat',
      upc: '',
      category: '',
      brandLabel: '',
      reviews: null
    } as ProductData
  }
)
const reviews = ref(props.product.reviews)

watch(
  () => props.product.upc,
  async () => {
    if (!props.product.upc || props.product.reviews || reviews.value) return
    await productStore.getProducts([props.product.upc])
    await productStore.fetchReviews([props.product.upc])
    reviews.value = productStore.getProduct(props.product.upc).reviews
  }
)

const ingredientsExpanded = ref(false)

/* Computed Properties */
const productName = computed(() => {
  const product = props.product
  if (product.name) return product.name?.replace(/-/g, ' ')
  const priorities = [
    product.product_name,
    // fallbacks
    product.p360Name,
    product.name?.replace(/-/g, ' '),
    (product.category?.toLowerCase().toUpperCase() || 'Makeup') + ' Product'
  ]
  return priorities.find((name) => !_.isEmpty(name))
})

const ingredients = computed(() =>
  splitBrackets(props.product.fullIngredientsList ?? '')
    .flatMap((list: string) => (list.includes('+/-') ? list : list.split(',')))
    .filter((ingredient) => !_.isEmpty(ingredient))
)

const slicedIngredients = computed(() =>
  ingredients.value.slice(0, ingredientsExpanded.value ? undefined : 10)
)

const lorealDefaultURL = ref(import.meta.env.VITE_DEFAULT_LOREAL_URL)
const viewOnLorealWebsite = () => {
  const url = new URL(props.product.pdpUrl ?? lorealDefaultURL.value)
  url.searchParams.set('prescripted', 'beautygenius')
  window.open(url.href, '_blank', 'noopener noreferrer')
}

const shortDescription = computed(() => DOMPurify.sanitize(props.product.shortDescription))

const instructionOfUse = computed(() => {
  let content = props.product.instructionOfUse
  if (!content) return null
  return DOMPurify.sanitize(content)
})
</script>

<style lang="scss" scoped>
@import '@/utils.scss';

ul.product-card-ingredient-list {
  list-style-position: inside;
}

.small-cta-button {
  padding: 10px 15px 8px 15px;
}
</style>
