<template>
  <template
    v-for="(rating, index) in ratingDists"
    :key="index"
  >
    <div class="flex flex-row w-full">
      <div class="text-xs w-[20%] pl-3 font-semibold">
        {{ 5 - index + (5 - index === 1 ? ' Star' : ' Stars') }}
      </div>
      <div class="flex flex-row w-[60%] relative self-center">
        <rect
          class="overflow-hidden rounded-[2px] absolute h-[10px] bg-black"
          :style="`width: ${(rating / totalReviews) * 100}%;`"
        />
        <rect class="h-[10px] w-full rounded-[2px] bg-custom-tw-rating-bar-fill" />
      </div>
      <div class="text-xs w-[20%] text-right pr-6">{{ rating }}</div>
    </div>
  </template>
</template>

<script lang="ts">
import _ from 'lodash'
import { RatingDistribution } from '@/types/products'
import { computed } from 'vue'
interface IReviewBarProps {
  totalReviews: number
  ratingDistributions: RatingDistribution[] | null
}
</script>

<script setup lang="ts">
const props = defineProps<IReviewBarProps>()
const ratingDists = computed(() =>
  _.map(
    [5, 4, 3, 2, 1],
    (star) =>
      props.ratingDistributions?.find((starRating) => starRating.RatingValue === star)?.Count ?? 0
  )
)
</script>
