<template>
  <input
    type="radio"
    v-model="picked"
    class="mr-3 w-6 h-6 border-[#BEBEBE] bg-custom-tw-background-sign-in focus-visible:border-[#055CCB] focus:bg-[#FAFAFA] focus:ring-0 hover:bg-[#EEE] active:bg-[#2E2E2E] checked:bg-black checked:hover:bg-[#636363] checked:active:bg-[#2e2e2e] checked:focus:bg-[#010101] checked:focus-visible:border-[#055CCB] checked:focus:ring-0"
    :name="name"
    :value="value"
    :id="value"
  />
</template>

<script lang="ts">
interface IDesignedRadioButtonProps {
  name: string
  value: string
}
</script>

<script setup lang="ts">
import { ref } from 'vue'
defineProps<IDesignedRadioButtonProps>()

const picked = ref(false)
</script>
