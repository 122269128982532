<template>
  <div class="custom-bar-gradient h-[16px] w-full flex flex-row rounded-3xl justify-end">
    <div
      class="h-full bg-[#e6e6e6] relative"
      :class="[
        score > 0 ? 'custom-progress-bar-indicator rounded-e-3xl left-[0.5px]' : 'rounded-3xl'
      ]"
      :style="{ width: barWidth }"
    />
  </div>
</template>

<script lang="ts">
interface IScoreBarProps {
  score: number
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<IScoreBarProps>(), {
  score: 0
})

const barWidth = computed(() => {
  const percScore = 100 - props.score * 100
  if (percScore < 0) return '0%'
  else if (percScore > 100) return '100%'
  return percScore + '%'
})
</script>

<style scoped>
.custom-progress-bar-indicator {
  /* this will do the magic */
  -webkit-mask-image: radial-gradient(circle at left, transparent 2.5px, #e6e6e6 2.5px);
  mask-image: radial-gradient(circle at left, transparent 2px, #e6e6e6 2px);
}

.custom-bar-gradient {
  background: linear-gradient(to right, #f9e3c2 0%, #f2cd95 100%);
}
</style>
