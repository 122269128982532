<template>
  <form @submit.prevent="submitBatch">
    <input
      ref="file"
      type="file"
      label="XLSX/TXT questions file"
      accept="text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @change="filePicked"
    />
    <div class="flex flex-col items-start my-4 gap-4">
      <Button
        type="submit"
        :disabled="!submitEnabled"
      >
        Submit batch
      </Button>
      <span class="text-red">
        {{ uploadFeedback }}
      </span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { BatchAPI } from '@/api'
import Button from '@/components/atoms/Button.vue'
import { logError } from '@/utils/errorUtils'

const uploadFeedback = ref('')

const file = ref<HTMLInputElement | null>(null)
const submitEnabled = ref(false)

const filePicked = () => {
  submitEnabled.value = !!file.value?.files && file.value.files.length > 0
}
const submitBatch = async (e: Event) => {
  e.preventDefault()
  submitEnabled.value = false
  if (file.value?.files && file.value.files.length > 0) {
    try {
      const batchResp = await BatchAPI.postBatch(file.value.files[0])
      uploadFeedback.value = batchResp.message
    } catch (e) {
      logError(e)
      uploadFeedback.value = 'Batch upload failed'
    }
  }
  submitEnabled.value = true
}
</script>
