<template>
  <div class="w-full mt-6 mb-5">
    <MouseDragContainer v-slot="{ moved }">
      <div class="w-full flex flex-row gap-1">
        <div class="w-[20px] flex-shrink-0" />
        <WelcomeCard
          v-for="content in welcomeCardContents"
          :key="content.title"
          :bg-image="content.bgImage"
          :icon="content.icon"
          :title="content.title"
          :content="content.content"
          :suggested-question="content.suggestedQuestion"
          :moved="moved"
          @question-click="(prompt: string) => onQuestionClick(prompt, moved)"
        />
        <div class="w-[20px] flex-shrink-0" />
      </div>
    </MouseDragContainer>
  </div>
</template>

<script setup lang="ts">
import MouseDragContainer from '@/components/chat/MouseDragContainer.vue'
import WelcomeCard from '@/components/molecules/WelcomeCard.vue'
import welcomeCardContents from '@/data/welcomeCardContents.json'

const emit = defineEmits(['question-click'])

const onQuestionClick = (prompt: string, moved: boolean) => {
  if (moved) {
    return
  }
  emit('question-click', prompt)
}
</script>
