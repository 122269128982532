<template>
  <div class="mt-1 pt-1 px-1 gap-1 flex flex-row items-center justify-center">
    <IconButton
      data-testid="message-positive-feedback"
      :size="16"
      :selected="selected === 'positive'"
      class="w-9 h-9 border-0"
      icon="thumb-up"
      color="light"
      @click="$emit('like')"
    />
    <IconButton
      data-testid="message-negative-feedback"
      :size="16"
      :selected="selected === 'negative'"
      class="w-9 h-9 border-0"
      icon="thumb-down"
      color="light"
      @click="$emit('dislike')"
    />
    <IconButton
      data-testid="message-copy"
      :size="16"
      class="w-9 h-9 border-0"
      icon="copy"
      color="light"
      @click="$emit('copy')"
    />
  </div>
</template>

<script lang="ts">
import type { FeedbackOverall } from '@/types/feedback'
import { onMounted } from 'vue'
import { useUIStore } from '@/store'
interface IMessageToolbarProps {
  selected?: FeedbackOverall
}
</script>

<script setup lang="ts">
import IconButton from '@/components/atoms/IconButton.vue'
withDefaults(defineProps<IMessageToolbarProps>(), {
  selected: undefined
})
defineEmits(['like', 'dislike', 'copy'])
const uiStore = useUIStore()
onMounted(() => {
  if (uiStore.atBottom) uiStore.setScrollToBottom(true)
})
</script>
