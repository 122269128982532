<template>
  <div class="w-full h-full">
    <dsf-app v-if="bypassEnabled" />
    <div
      id="vto-container"
      ref="vtoContainer"
      data-testid="vto-container"
      class="w-full relative overflow-hidden h-full lg:pt-[100%]"
    >
      <div
        v-if="isHair"
        id="hair-canvas-container"
        class="absolute top-0 left-0 right-0 bottom-0 flex justify-center w-full h-full [&_canvas]:h-full"
      />
      <div
        v-else
        id="makeup-canvas-container"
        class="absolute top-0 left-0 right-0 bottom-0 flex justify-center w-full h-full [&_canvas]:h-full"
      />
      <div
        v-if="!isShadeValid() && !loading"
        class="absolute top-[50%] left-[10%] right-0 bottom-0 flex justify-center w-[90%] h-full [&_canvas]:object-cover [&_canvas]:w-full [&_canvas]:h-full text-white"
      >
        Sorry, we have a problem retrieving the rendering data, please try again later.
      </div>
      <div v-else>
        <Loading v-if="loading" />
        <div
          ref="compareSlider"
          data-testid="vto-slider"
          class="custom-vertical-line"
          :class="{ hidden: disableSlider }"
          style="left: 50%; width: 2px"
          @touchstart="vtoTouchSlide"
          @mousedown="vtoMouseSlide"
        >
          <SvgIcon
            name="sliderIcon"
            class="fixed top-[45%] w-[30px]"
            style="transform: translateX(calc(-50% + 1px))"
          ></SvgIcon>
        </div>
        <SvgIcon
          name="toggleSlider"
          data-testid="vto-slider-toggle"
          class="absolute bottom-[14%] right-4 cursor-pointer w-[40px] h-[40px] z-60"
          style="user-select: none"
          color="white"
          @click="toggleSlider"
        />
        <vtoSwatches
          class="absolute bottom-[7%] left-[0] h-[50px] w-[100%]"
          :class="vtoSwatchClass"
          :shades="productRecos"
          :upc="selectedUPC"
          :makeup-type="selectedMakeupType"
          @productSelected="(upc: string) => changeShade(upc)"
        />
        <div
          class="absolute right-2 top-6 w-full max-w-[300px] h-[80px] p-2 flex flex-row gap-2 text-sm text-slate-200 bg-black bg-opacity-50 rounded-lg"
        >
          <div class="h-full basis-9 flex-none overflow-hidden">
            <img
              v-if="packshot"
              class="h-full w-auto object-cover"
              :src="packshot"
              :alt="'Placeholder packshot for ' + productName"
            />
            <img
              v-else
              class="h-full w-auto object-cover"
              src="@/assets/images/product-packshot-placeholder.png"
              :alt="'Placeholder packshot for ' + productName"
            />
          </div>
          <div class="h-full flex flex-col gap-2 justify-center">
            <div>Trying on</div>
            <div>{{ productName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useProductStore } from '@/store/productStore'
import { MFE } from '@/utils/mfe/liveModule'
import { isMobile } from '@/utils/web'
import Loading from '@/components/atoms/Loading.vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { mouseSlide, touchSlide } from '@/utils/sliderListeners.js'
import type { ProductData } from '@/types/products'
import vtoSwatches from '@/components/vto/vtoSwatches.vue'
import { useModalControllerStore, useChatStore } from '@/store'
import { logError } from '@/utils/errorUtils'
import _ from 'lodash'
import { tracking } from '@/tracking/EventController'

/* refs */
const vtoContainer = ref<HTMLDivElement | null>(null)
const compareSlider = ref<HTMLDivElement | null>(null)
const chatStore = useChatStore()

/* vars */
const productStore = useProductStore()
const loading = ref(false)
const disableSlider = ref(true)
const productRecos = ref<ProductData[]>([])

const modalControllerStore = useModalControllerStore()

/* computed properties */

// check the current makeup type
const isHair = computed(() => MFE.isHair(selectedMakeupType.value))

const product = computed((): ProductData => productStore.getProduct(selectedUPC.value))
const shade = computed(() => product.value?.shadeArray[0] || {})

// product card info
const productName = computed(() => product?.value?.productName || "L'Oreal Paris")
const packshot = computed(() => product?.value?.packshotImageUrl || '')

const vtoSwatchClass = computed(() => {
  //disable if loading
  let swatchClass = loading.value ? 'opacity-50 pointer-events-none ' : ''
  swatchClass += !isMobile() ? 'bottom-[7%]' : 'bottom-[17%]'
  return swatchClass
})

/* props */
const props = withDefaults(defineProps<{ upcs: string[]; msgIndex: number; selected: string }>(), {
  upcs: () => [],
  selected: '',
  msgIndex: -1
})
let selectedUPC = ref('')
let selectedMakeupType = ref('')

const setCanvasSize = () => {
  const canvas = document.getElementById('norendering-canvas-container')
    ?.firstElementChild as HTMLDivElement
  if (canvas && vtoContainer.value) {
    canvas.style.width = `${vtoContainer.value.clientWidth}px`
  }
}

/* lifecycle hooks */
onMounted(async () => {
  loading.value = true // loading...
  // return to home page if some params are missing
  if (_.isEmpty(props.upcs)) {
    loading.value = false
    return
  }
  productRecos.value = await getProductRecos()
  const validUPCS = productStore.checkProducts(props.upcs)
  if (validUPCS.includes(props.selected)) {
    selectedUPC.value = props.selected
  } else {
    selectedUPC.value = validUPCS[0]
    changeShade(validUPCS[0])
  }
  selectedMakeupType.value = 'makeup'

  // start the try-on
  try {
    if (!isShadeValid()) {
      loading.value = false
      return
    }

    if (MFE.isHair(product.value?.category) || MFE.isHair(shade.value.category))
      selectedMakeupType.value = 'hair'
    await MFE.initSDK()
    await MFE.startVTO(selectedMakeupType.value, shade.value)
    loading.value = false // loaded
  } catch (e) {
    logError(e)
    loading.value = false
  }

  setCanvasSize()
  window.addEventListener('resize', setCanvasSize)
  loading.value = false
  tracking.VTOEvent(chatStore.currentSessionId, productName.value, selectedUPC.value)
})

onBeforeUnmount(async () => {
  // only pause the VTO so that we don't have to
  // re-initialize the module next time
  await MFE.stopVTO(selectedMakeupType.value)
  window.removeEventListener('resize', setCanvasSize)
})

/* watchers */

// makeup type
watch(
  () => selectedMakeupType,
  async (newMakeupType, oldMakeupType) => {
    await MFE.stopVTO(oldMakeupType)
    loading.value = true // loading...
    // start vto with the updated makeup category
    if (!isShadeValid()) return
    await MFE.startVTO(newMakeupType, shade.value)
    loading.value = false // loaded
  }
)

watch(
  () => props.upcs,
  async () => {
    await MFE.stopVTO(selectedMakeupType.value)
    loading.value = true
    if (_.isEmpty(props.upcs)) {
      loading.value = false
      return
    }
    productRecos.value = await getProductRecos()
    const validUPCS = productStore.checkProducts(props.upcs)
    if (validUPCS.includes(props.selected)) {
      selectedUPC.value = props.selected
    } else {
      selectedUPC.value = validUPCS[0]
      changeShade(validUPCS[0])
    }
    selectedMakeupType.value = 'makeup'
    if (!isShadeValid()) {
      loading.value = false
      return
    }
    if (MFE.isHair(product.value?.category) || MFE.isHair(shade.value.category))
      selectedMakeupType.value = 'hair'
    await MFE.startVTO(selectedMakeupType.value, shade.value)
    loading.value = false
  }
)

watch(
  () => props.selected,
  async () => {
    selectedUPC.value = props.selected
    selectedMakeupType.value = 'makeup'
    if (!isShadeValid()) return
    if (MFE.isHair(product.value?.category) || MFE.isHair(shade.value.category))
      selectedMakeupType.value = 'hair'
    await MFE.startVTO(selectedMakeupType.value, shade.value)
    await MFE.updateVTO(selectedMakeupType.value, shade.value)
    tracking.selectShadeEvent(chatStore.currentSessionId, productName.value, props.selected)
  }
)

/* methods */

const vtoTouchSlide = (event: TouchEvent) => {
  try {
    if (!vtoContainer.value || !compareSlider.value) {
      throw new Error('missing elements')
    }
    touchSlide({
      event,
      vtoContainer: vtoContainer.value,
      compareSlider: compareSlider.value,
      makeupType: selectedMakeupType.value
    })
  } catch (e) {
    logError(e)
  }
}
const vtoMouseSlide = (event: MouseEvent) => {
  try {
    if (!vtoContainer.value || !compareSlider.value) {
      throw new Error('missing elements')
    }
    mouseSlide({
      event,
      vtoContainer: vtoContainer.value,
      compareSlider: compareSlider.value,
      makeupType: selectedMakeupType.value
    })
  } catch (e) {
    logError(e)
  }
}
const toggleSlider = () => {
  try {
    disableSlider.value = !disableSlider.value
    if (!compareSlider.value) throw new Error()
    if (disableSlider.value) {
      MFE.setMultiViewMode(selectedMakeupType.value, 'single')
    } else {
      tracking.useBeforeAfterEvent(chatStore.currentSessionId, productName.value, selectedUPC.value)
      compareSlider.value.style.left = '50%'
      MFE.setMultiViewMode(selectedMakeupType.value, 'dual')
      MFE.setDualViewComparisonPercent(selectedMakeupType.value, 0.5)
    }
  } catch (e) {
    logError(e)
  }
}
const changeShade = async (upc: string) => {
  modalControllerStore.setVTO({
    upcs: props.upcs,
    selected: upc,
    msgIndex: props.msgIndex
  })
}

const getProductRecos = async () => {
  return await productStore.getProducts(props.upcs)
}

// whether the rendering data is valid or not
const isShadeValid = () => !!shade.value && !_.isEmpty(shade.value)

const bypassEnabled = computed(() => {
  return import.meta.env.VITE_BYPASS_LICENCE_CHECK === 'true'
})
</script>

<style lang="scss" scoped>
.custom-vertical-line {
  width: 2px;
  background-color: black;
  top: 0;
  // height: 1000px;
  position: absolute;
  bottom: 0;
  left: 30px;
  user-select: none;
  cursor: col-resize;
}
</style>
