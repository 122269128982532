import _ from 'lodash'
import type {
  Chat,
  ChatMessage,
  ChatMessageAI,
  ChatMessageUserImage,
  ChatMessageUserText
} from '@/types/chats'
import { isAIMessage, isChatMessageTextBase } from './messages-utils'

const MAX_SPEED_THRESHOLD = 100
const MIN_SPEED_THRESHOLD = 5
const MIN_DELAY_BETWEEN_TOKENS = 5
const MAX_DELAY_BETWEEN_TOKENS = 20

export const getDelayBetweenTokens = (backlogSize: number) => {
  const deltaInThresholds = Math.max(
    MIN_SPEED_THRESHOLD,
    Math.min(MAX_SPEED_THRESHOLD, backlogSize)
  )
  const normalizedSpeedCoeff =
    (deltaInThresholds - MIN_SPEED_THRESHOLD) / (MAX_SPEED_THRESHOLD - MIN_SPEED_THRESHOLD)
  return (
    (1 - normalizedSpeedCoeff) * (MAX_DELAY_BETWEEN_TOKENS - MIN_DELAY_BETWEEN_TOKENS) +
    MIN_DELAY_BETWEEN_TOKENS
  )
}

const findMessage = ({
  messages,
  messageId
}: {
  messages: ChatMessage[]
  messageId: string
}): number => _.findIndex(messages, ['id', messageId])

//Appends a User Text Message to a chat
export const appendUserTextMessage = ({
  chat,
  message
}: {
  chat: Chat
  message: Partial<ChatMessageUserText>
}): void => {
  const { messages } = chat
  messages.push({
    id: '',
    type: 'text',
    sender: 'user',
    content: '',
    complete: true,
    ...message
  })
}

//Appends a User Image Message to a chat
export const appendUserImageMessage = ({
  chat,
  message
}: {
  chat: Chat
  message: Partial<ChatMessageUserImage>
}): void => {
  const { messages } = chat
  messages.push({
    id: '',
    type: 'image',
    imageId: '',
    sender: 'user',
    complete: true,
    ...message
  })
}

// Appends an AI message to a chat
export const appendAIMessage = ({
  chat,
  message
}: {
  chat: Chat
  message: Partial<ChatMessageAI>
}): void => {
  const { messages } = chat
  messages.push({
    id: '',
    type: 'text',
    sender: 'ai',
    content: '',
    complete: false,
    productVideos: [],
    skinDiag: null,
    ...message
  })
}

// Appends Content to a message
export const appendMessageContent = ({
  chat: { messages },
  messageId,
  content
}: {
  chat: Chat
  messageId: string
  content: string
}): void => {
  const messageIndex = findMessage({ messages, messageId })
  if (!messageIndex) return
  const message = messages[messageIndex]
  if (isChatMessageTextBase(message)) {
    message.content += content
  }
}

// Displays an Error message in Chaat
export const showError = ({
  chat,
  data,
  error = 'Something happened :(\nWe were unable to process your request. Try again soon.',
  messageId
}: {
  chat: Chat
  data: any
  error: string
  messageId: string
}) => {
  console.error(`Error during SSE call to GenAI backend: ${data}`)
  updateMessage({
    chat,
    messageId,
    message: {
      sender: 'ai',
      content: error,
      complete: true
    }
  })
  throw new Error(data)
}

// Returns a new Empty Chat object
export const newChat = (): Chat => ({ createdAt: new Date(), messages: [] })

// Patches a message
export const updateMessage = ({
  chat: { messages },
  messageId,
  message
}: {
  chat: Chat
  messageId: string
  message: Partial<ChatMessage>
}) => {
  const messageIndex = findMessage({ messages, messageId })
  if (!messageIndex) {
    return
  }
  if (isAIMessage(message) && !message.skinDiag) {
    message.skinDiag = null
  }
  messages[messageIndex] = {
    ...messages[messageIndex],
    ...message
  } as ChatMessage
}

export const updateMessageId = ({
  chat,
  previousId,
  newId
}: {
  chat: Chat
  newId: string
  previousId: string
}): void => updateMessage({ chat, messageId: previousId, message: { id: newId } })

export const getFormattedTimestamp = (instance?: string): any => {
  const date = instance ? new Date(instance) : new Date()
  return date.toLocaleString('en-GB', {
    weekday: 'short',
    day: 'numeric',
    month: 'short'
  })
}
