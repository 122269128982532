<template>
  <button
    class="h-full relative flex items-center justify-center flex-shrink-0 ml-2 transition-all send-button z-50"
    :class="sendButtonImgStyle"
  >
    <div class="custom-send-button-backdrop z-20">
      <div class="magic-gradient h-full w-full rounded-full mix-blend-overlay"></div>
    </div>
    <div class="custom-send-button-image z-40 rounded-full mix-blend-overlay"></div>
    <Animation
      name="ai_loading"
      class="absolute flex-shrink-0 min-w-[110px] min-h-[110px] z-50"
    />
    <img
      class="max-w-[52px] ml-2 position-relative z-50"
      src="@/assets/images/send_button.png"
      alt="send-button"
    />
  </button>
</template>

<script lang="ts">
interface ISendButtonProps {
  visible?: boolean
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import Animation from '../atoms/Animation.vue'

const props = withDefaults(defineProps<ISendButtonProps>(), { visible: false })

const sendButtonImgStyle = computed(() => {
  if (!props.visible) {
    return ['custom-send-button-inactive']
  }
  return ['custom-send-button-active']
})
</script>

<style scoped lang="scss">
.custom-send-button-active {
  transform: perspective(100px) translate3d(0, 0, 0);
  opacity: 1;
  width: 60px;
}

.custom-send-button-inactive {
  transform: perspective(100px) translate3d(0, 0, -200px);
  opacity: 0;
  width: 0;
  margin: 0;
}

.custom-send-button-image,
.custom-send-button-backdrop {
  position: absolute;
  pointer-events: none;
  left: -10px;
  top: -15px;
  bottom: -15px;
  right: -15px;
}

.custom-send-button-backdrop {
  filter: blur(10px);
}

.custom-send-button-image {
  background-image: url('/images/send-button-bg.png');
  background-size: cover;
}
</style>
